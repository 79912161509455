import * as types from './mutation-types'

export const fetchSubcategories = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/subcategories`, { params })
      .then((response) => {
        commit(types.SET_SUBCATEGORIES, response.data.subcategories.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchSubcategory = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/subcategories/${id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addSubcategory = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/v1/subcategories', data)
      .then((response) => {
        commit(types.ADD_SUBCATEGORY, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateSubcategory = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/v1/subcategories/${data.id}`, data)
      .then((response) => {
        commit(types.UPDATE_SUBCATEGORY, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteSubcategory = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .delete(`/api/v1/subcategories/${id}`)
      .then((response) => {
        if (response.data.success) {
          commit(types.DELETE_SUBCATEGORY, id)
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
