var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      staticClass:
        "box-border bg-white border border-gray-200 border-solid rounded-b",
    },
    [
      _c(
        "td",
        { staticClass: "p-0 text-left align-top", attrs: { colspan: "12" } },
        [
          _c("table", { staticClass: "w-full" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "px-2 py-4 text-left align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex justify-start" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-12 h-5 mt-2 text-gray-400 cursor-move handle",
                        },
                        [_c("drag-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-select",
                        {
                          ref: "baseSelect",
                          attrs: {
                            options: _vm.categories,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "invoices.item.select_a_category"
                            ),
                            label: "formattedCategory",
                            "track-by": "id",
                          },
                          on: {
                            select: function (val) {
                              return _vm.onSelectCategory(val)
                            },
                            deselect: _vm.onDeselectCategory,
                          },
                          model: {
                            value: _vm.selectedCategory,
                            callback: function ($$v) {
                              _vm.selectedCategory = $$v
                            },
                            expression: "selectedCategory",
                          },
                        },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex items-center justify-center w-full px-2 py-3 bg-gray-200 border-none outline-none",
                              attrs: {
                                slot: "afterList",
                                type: "button",
                                variant: "gray-light",
                              },
                              on: { click: _vm.openCategoryModal },
                              slot: "afterList",
                            },
                            [
                              _c("plus-icon", {
                                staticClass: "h-5 text-center text-primary-400",
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "ml-2 text-xs leading-none text-primary-400",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "settings.expense_category.add_new_category"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-left align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex justify-start" },
                    [
                      _c(
                        "sw-select",
                        {
                          ref: "baseSelect",
                          attrs: {
                            options: _vm.subcats,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "invoices.item.select_a_subcategory"
                            ),
                            label: "formattedSubcategory",
                            "track-by": "id",
                          },
                          on: {
                            select: function (val) {
                              return _vm.onSelectSubcategory(val)
                            },
                          },
                          model: {
                            value: _vm.selectedSubcategory,
                            callback: function ($$v) {
                              _vm.selectedSubcategory = $$v
                            },
                            expression: "selectedSubcategory",
                          },
                        },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex items-center justify-center w-full px-2 py-3 bg-gray-200 border-none outline-none",
                              attrs: {
                                slot: "afterList",
                                type: "button",
                                variant: "gray-light",
                              },
                              on: { click: _vm.openSubcategoryModal },
                              slot: "afterList",
                            },
                            [
                              _c("plus-icon", {
                                staticClass: "h-5 text-center text-primary-400",
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "ml-2 text-xs leading-none text-primary-400",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "settings.expense_subcategory.add_new_subcategory"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "px-2 py-4 text-right align-top" },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.item.name.$error,
                        type: "text",
                        small: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.item.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.item.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "name", $$v)
                        },
                        expression: "item.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-left align-top" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full" },
                          [
                            _c("sw-money", {
                              attrs: {
                                currency: _vm.customerCurrency,
                                invalid: _vm.$v.item.price.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.item.price.$touch()
                                },
                              },
                              model: {
                                value: _vm.price,
                                callback: function ($$v) {
                                  _vm.price = $$v
                                },
                                expression: "price",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$v.item.price.$error
                          ? _c("div", [
                              !_vm.$v.item.price.maxLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("validation.price_maxlength")
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "px-2 py-4 text-right align-top" },
                  _vm._l(_vm.item.taxes, function (tax, index) {
                    return _c("tax", {
                      key: tax.id,
                      attrs: {
                        index: index,
                        "tax-data": tax,
                        taxes: _vm.item.taxes,
                        motivation: _vm.item.tax_motivation,
                        "discounted-total": _vm.total,
                        "total-tax": _vm.totalSimpleTax,
                        total: _vm.total,
                        currency: _vm.currency,
                      },
                      on: { update: _vm.updateTax, remove: _vm.removeTax },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center h-10 cursor-pointer text-sm",
                    },
                    [
                      _c("span", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(_vm.totalTTC, _vm.currency)
                            ),
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center w-6 h-10 mx-2 text-xs cursor-pointer",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-25px" },
                            model: {
                              value: _vm.calcolaEnpav,
                              callback: function ($$v) {
                                _vm.calcolaEnpav = $$v
                              },
                              expression: "calcolaEnpav",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center w-6 h-10 mx-2 text-xs cursor-pointer",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-25px" },
                            on: { change: _vm.updateItem },
                            model: {
                              value: _vm.item.fctax,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "fctax", $$v)
                              },
                              expression: "item.fctax",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-end text-xs" },
                    [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-25px" },
                            on: { change: _vm.updateItem },
                            model: {
                              value: _vm.item.indtax,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "indtax", $$v)
                              },
                              expression: "item.indtax",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                        },
                        [
                          _vm.showRemoveItemIcon
                            ? _c("trash-icon", {
                                staticClass: "h-5 text-gray-700",
                                on: { click: _vm.removeItem },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "16%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "14%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "9%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "16%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "9%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "7%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "7%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "7%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }