export default {
    classes: {
      avatarContainer: 'relative flex items-center justify-center w-32 h-32 p-5 mt-2 bg-transparent border-2 border-dashed rounded-md cursor-pointer avatar-upload border-gray-300',
      overlay: 'absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full opacity-0 overlay',
      overlayIcon: 'mb-2 text-3xl leading-normal text-white',
      image: 'absolute opacity-100 h-20 w-20',
      previewAvatarClass: 'flex flex-col items-center',
      defaultIcon: 'mb-2 text-xl leading-6 text-gray-400',
      defaultLabel: 'text-xs leading-4 text-center text-gray-400'
    },
    variants: {
      grayLight: {
        button:
          'inline-flex items-center justify-center text-gray-400 transition duration-150 ease-in-out border border-transparent focus:outline-none bg-gray-100 border border-gray-200 hover:bg-gray-200 hover:border-gray-400 hover:text-gray-600',
      },
      gray: {
        button:
          'inline-flex items-center justify-center text-gray-400 transition duration-150 ease-in-out border border-transparent focus:outline-none bg-gray-300 border border-gray-200',
      },
      white: {
        button:
          'inline-flex items-center justify-center text-black transition px-2 duration-150 ease-in-out border border-gray-300 border-solid focus:outline-none bg-white',
      },
    },
    sizes: {
      discount: {
        button: 'py-2 px-2 text-sm leading-5 rounded',
        loadingIcon: 'w-4 h-4 -ml-2',
      },
    },
  }
  