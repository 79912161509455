<template>
  <sw-card variant="setting-card">
    <div slot="header" class="flex flex-wrap justify-between lg:flex-nowrap">
      <div>
        <h6 class="sw-section-title">
          {{ $t('settings.expense_subcategory.title') }}
        </h6>
        <p
          class="mt-2 text-sm leading-snug text-gray-500"
          style="max-width: 680px"
        >
          {{ $t('settings.expense_subcategory.description') }}
        </p>
      </div>
      <div class="mt-4 lg:mt-0 lg:ml-2">
        <sw-button
          variant="primary-outline"
          size="lg"
          @click="addExpenseSubcategory"
        >
          <plus-icon class="w-6 h-6 mr-1 -ml-2" />
          {{ $t('settings.expense_subcategory.add_new_subcategory') }}
        </sw-button>
      </div>
    </div>
    
    <div class="relative grid grid-flow-col grid-rows">
      <sw-input-group :label="$tc('invoices.item.category', 1)" class="mt-2">
        <sw-select
          v-model="category"
          :options="categories"
          :group-select="false"
          :searchable="true"
          :show-labels="false"
          :placeholder="$t('invoices.item.select_a_category')"
          :allow-empty="false"
          track-by="id"
          label="formattedCategory"
          @select="(val) => onSelectCategory(val)"
          @remove="clearCategorySearch()"
        />
      </sw-input-group>

      <label
        class="absolute text-sm leading-snug text-black cursor-pointer"
        @click="clearFilter"
        style="top: 10px; right: 15px"
        >{{ $t('general.clear_all') }}</label
      >
    </div>

    <sw-table-component
      ref="table"
      :show-filter="false"
      :data="fetchData"
      variant="gray"
    >
     <sw-table-column
        :label="$t('settings.expense_subcategory.subcategory_master')"
        show="category_id"
      >
        <template slot-scope="row">
          <span>{{ $t('settings.expense_subcategory.subcategory_master') }}}</span>
          <span class="mt-6">{{ row.category }}</span>
        </template>
      </sw-table-column>

     <sw-table-column
        :label="$t('settings.expense_subcategory.subcategory_code')"
        show="code"
      >
        <template slot-scope="row">
          <span>{{ $t('settings.expense_subcategory.subcategory_code') }}}</span>
          <span class="mt-6">{{ row.code }}</span>
        </template>
      </sw-table-column>

      <sw-table-column
        :label="$t('settings.expense_subcategory.subcategory_name')"
        show="name"
      >
        <template slot-scope="row">
          <span>{{ $t('settings.expense_subcategory.subcategory_name') }}}</span>
          <span class="mt-6">{{ row.name }}</span>
        </template>
      </sw-table-column>

      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="$t('settings.expense_subcategory.subcategory_description')"
      >
        <template slot-scope="row">
          <span>{{
            $t('settings.expense_subcategory.subcategory_description')
          }}</span>
          <div class="w-48 overflow-hidden notes">
            <div
              class="overflow-hidden whitespace-nowrap"
              style="text-overflow: ellipsis"
            >
              {{ row.description }}
            </div>
          </div>
        </template>
      </sw-table-column>

      <sw-table-column
        :sortable="false"
        :filterable="false"
        cell-class="action-dropdown"
      >
        <template slot-scope="row">
          <span>{{ $t('settings.expense_subcategory.action') }}</span>
          <sw-dropdown>
            <dot-icon slot="activator" class="h-5" />

            <sw-dropdown-item @click="editExpenseSubcategory(row.id)">
              <pencil-icon class="h-5 mr-3 text-gray-600" />
              {{ $t('general.edit') }}
            </sw-dropdown-item>

            <sw-dropdown-item @click="removeExpenseSubcategory(row.id)">
              <trash-icon class="h-5 mr-3 text-gray-600" />
              {{ $t('general.delete') }}
            </sw-dropdown-item>
          </sw-dropdown>
        </template>
      </sw-table-column>
    </sw-table-component>
  </sw-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TrashIcon, PencilIcon, PlusIcon } from '@vue-hero-icons/solid'

export default {
  components: {
    TrashIcon,
    PencilIcon,
    PlusIcon,
  },

  data() {
    return {
      category: null,
      filters: {
        category: null
      }
    }
  },

  computed: {
    ...mapGetters('subcategory', ['subcategories', 'getSubcategoryById']),
    ...mapGetters('category', ['categories']),
  },

  mounted() {
    this.fetchCategories({ limit: 'all' })
  },

  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  methods: {
    ...mapActions('modal', ['openModal']),

    ...mapActions('subcategory', [
      'fetchSubcategories',
      'fetchSubcategory',
      'deleteSubcategory',
    ]),

    ...mapActions('category', ['fetchCategories']),

    setFilters() {
      this.refreshTable()
    },

    clearFilter() {
      this.filters = {
        category: null,
      }
      this.category = null
    },

    refreshTable() {
      this.$refs.table.refresh()
    },

    onSelectCategory(category) {
      this.filters.category = category.id
    },

    async clearCategorySearch(removedOption, id) {
      this.filters.category = null
      this.refreshTable()
    },

    async fetchData({ page, filter, sort }) {
      let data = {
        category_id: this.filters.category,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchSubcategories(data)
      this.isRequestOngoing = false

      return {
        data: response.data.subcategories.data,
        pagination: {
          totalPages: response.data.subcategories.last_page,
          currentPage: page,
          count: response.data.subcategories.count,
        },
      }
    },

    async removeExpenseSubcategory(id, index) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('settings.expense_subcategory.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let response = await this.deleteSubcategory(id)
          if (response.data.success) {
            window.toastr['success'](
              this.$tc('settings.expense_subcategory.deleted_message')
            )
            this.id = null
            this.$refs.table.refresh()
            return true
          }
          window.toastr['error'](
            this.$t('settings.expense_subcategory.already_in_use')
          )
        }
      })
    },

    addExpenseSubcategory() {
      this.openModal({
        title: this.$t('settings.expense_subcategory.add_subcategory'),
        componentName: 'SubcategoryModal',
        refreshData: this.$refs.table.refresh,
      })
    },

    async editExpenseSubcategory(id) {
      let response = await this.fetchSubcategory(id)
      this.openModal({
        title: this.$t('settings.expense_subcategory.edit_subcategory'),
        componentName: 'SubcategoryModal',
        id: id,
        data: response.data.subcategory,
        refreshData: this.$refs.table.refresh,
      })
    },
  },
}
</script>
