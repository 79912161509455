import * as types from './mutation-types'

export default {
  [types.SET_DEADLINES](state, deadlines) {
    state.deadlines = deadlines
  },

  [types.SET_TOTAL_DEADLINES](state, totalDeadlines) {
    state.totalDeadlines = totalDeadlines
  },

  [types.ADD_DEADLINE](state, data) {
    state.deadlines.push(data.deadline)
  },

  [types.UPDATE_DEADLINE](state, data) {
    let pos = state.deadlines.findIndex(
      (deadline) => deadline.id === data.deadline.id
    )
    Object.assign(state.deadlines[pos], { ...data.deadline })
  },

  [types.DELETE_DEADLINE](state, id) {
    let pos = state.deadlines.findIndex((deadline) => deadline.id === id)
    state.deadlines.splice(pos, 1)
  },
}
