<template>
  <form action="" @submit.prevent="submitPaymentData">
    <div class="p-8 sm:p-6">
      <sw-input-group 
        :label="$t('payments.payment_mode')"
        class="mt-3"
        :error="ModeError"
        required
      >
        <sw-select
          v-model="formData.payment_method"
          :options="paymentModesVrb"
          :searchable="true"
          :show-labels="false"
          :placeholder="$t('payments.select_payment_mode')"
          :max-height="150"
          :invalid="$v.formData.payment_method.$error"
          label="name"
          @select="(val) => onSelectMethod(val)"
        />
      </sw-input-group>

      <sw-input-group
        :label="$t('payments.date')"
        :error="DateError"
        class="mt-3"
        required
      >
        <base-date-picker
          v-model="formData.payment_date"
          :invalid="$v.formData.payment_date.$error"
          :calendar-button="true"
          calendar-button-icon="calendar"
          @input="$v.formData.payment_date.$touch()"
        />
      </sw-input-group>

      <sw-input-group
        :label="$t('invoices.amount')"
        class="mt-3"
      >
        <span>
          <div v-html="$utils.formatMoney(formData.amount, currency)" />
        </span>
      </sw-input-group>
      

    </div>
    <div
      class="z-0 flex justify-end p-4 border-t border-gray-200 border-solid border-modal-bg"
    >
      <sw-button
        type="button"
        variant="primary-outline"
        class="mr-3 text-sm"
        @click="closePaymentModal"
      >
        {{ $t('general.cancel') }}
      </sw-button>
      <sw-button variant="primary" type="submit" :loading="isLoading">
        <save-icon v-if="!isLoading" class="mr-2" />
        {{ $t('general.save') }}
      </sw-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

const { required } = require('vuelidate/lib/validators')
export default {
  // props: {
  //   // deadlineData: {
  //   //   type: Object,
  //   //   default: null,
  //   // },
  //   // index: {
  //   //   type: Number,
  //   //   default: null,
  //   // },
  //   // currency: {
  //   //   type: [Object, String],
  //   //   required: true,
  //   // },
  //   // invoiceDeadlines: {
  //   //   type: Array,
  //   //   default: null,
  //   // },
  //   // invoiceTotal: {
  //   //   type: Number,
  //   //   default: null,
  //   // },
  //   // isEdit: {
  //   //   type: Boolean,
  //   //   default: null,
  //   // },
  //   documentType: {
  //     type: String,
  //     default: null,
  //   },
  // },
  data() {
    return {
      isLoading: false,
      isEdit: false,
      currency: null,
      documentType: null,
      formData: {
        deadline_id: null,
        user_id: null,
        payment_number: null,
        payment_date: null,
        amount: null,
        payment_method: null,
        invoice_id: null,
        notes: null,
        payment_method_id: null,
        ritenuta: 0,
      },
      paymentModesVrb: null,
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalDataID',
      'modalData',
      'modalActive',
      'refreshData',
    ]),
    ...mapGetters('payment', ['paymentModes']),

    DateError() {
      if (!this.$v.formData.payment_date.$error) {
        return ''
      }
      if (!this.$v.formData.payment_date.required) {
        return this.$t('validation.required')
      }
    },
    ModeError() {
      if (!this.$v.formData.payment_method.$error) {
        return ''
      }
      if (!this.$v.formData.payment_method.required) {
        return this.$t('validation.required')
      }
    },
  },
  validations: {
    formData: {
      payment_date: {
        required,
      },
      payment_method: {
        required,
      },
    },
  },
  watch: {
    modalDataID(val) {
      if (val) {
        this.isEdit = true
        this.setData()
      } else {
        this.isEdit = false
      }
    },
    modalActive(val) {
      if (!this.modalActive) {
        this.resetFormData()
      }
    },
    documentType: {
      handler: function(value){
        this.setMethodTD(value)
      },
      deep: true,
    }
  },

  created(){
    this.paymentModesVrb = this.paymentModes
    console.log(documentType)
  },

  mounted() {
    if (this.modalDataID) {
      this.isEdit = true
    }
    this.setData()
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('payment', [
      'addPayment',
      'updatePayment',
      'fetchPaymentMode',
      'fetchPaymentModes',
    ]),

    onSelectMethod(val) {
      this.formData.payment_method_id = val.id
    },

    resetFormData() {
      this.formData = {
        deadline_id: null,
        user_id: null,
        payment_date: moment().format('YYYY-MM-DD'),
        amount: null,
        payment_method: null,
        invoice_id: null,
        payment_method_id: null,
        ritenuta: 0,
      }
      this.$v.formData.$reset()
    },

    async submitPaymentData() {
      this.$v.formData.$touch()

      if (this.$v.$invalid) {
        return true
      }
      this.isLoading = true
      
      let response = {};
      if (!this.isEdit) {
        response = await this.addPayment(this.formData)  
        this.$router.go(0)
      } else {
        let data = {
          editData: {
            ...this.formData,
            payment_method_id: this.formData.payment_method
              ? this.formData.payment_method.id
              : null,
            payment_date: moment(this.formData.payment_date).format('YYYY-MM-DD'),
          },
          id: this.modalDataID,
        }
        
        response = await this.updatePayment(data)
      }

      if (response.data) {
        if (!this.isEdit) {
          window.toastr['success'](
            this.$t('payments.created_message')
          )
        } else {
          window.toastr['success'](
            this.$t('payments.updated_message')
          )
        }
        this.refreshData ? this.refreshData() : ''
        this.closePaymentModal()
        this.isLoading = false
        return true
      }
      window.toastr['error'](response.data.error)
    },

    async setData() {
      await this.fetchPaymentModes({ 
        limit: 'all',
        //creditnote: this.modalData.document_type == 'TD04' ? 1 : 0
      })
      
      let method = null
      let deadline = null
      this.paymentModes.push({id: 1, name: 'Stornata'})
      if(this.isEdit) {
        if (this.modalData.payment_method_id > 0) {
          method = this.paymentModes.find(
            (_method) => _method.id == this.modalData.payment_method_id
          )
        }
        deadline = this.modalData.deadline_id
      } else {      
        if (this.modalData.deadline_method_id > 0) {
          method = this.paymentModes.find(
            (_method) => _method.id == this.modalData.deadline_method_id
          )
        }else{
          this.paymentModesVrb = this.paymentModes
        }
        deadline = this.modalData.id
      }
      this.formData = {
        deadline_id: deadline,
        user_id: this.modalData.user_id,
        payment_date: moment(this.modalData.payment_date).format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD'),
        amount: this.modalData.amount,
        payment_method: method,
        invoice_id: this.modalData.invoice_id,
        payment_method_id: method ? method.id : null,
        ritenuta: this.modalData.ritenuta,
      }      

      this.currency = this.modalData.currency
      this.documentType = this.modalData.documentType
      console.log(this.documentType, this.document_type, this.currency)
      this.setMethodTD(this.documentType)
    },

    closePaymentModal() {
      this.resetFormData()
      this.closeModal()
    },

    setMethodTD(value){
      if(value == 'TD04'){
          if(this.paymentModesVrb[this.paymentModesVrb.length-1].id != 1)
            this.paymentModesVrb.push({id: 1, name: 'Stornata'})
        }else{
          if(this.paymentModesVrb[this.paymentModesVrb.length-1].id == 1)
            this.paymentModesVrb.splice(this.paymentModesVrb.length-1, 1)
        }
    }
  },
}
</script>
