var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "grid h-full grid-cols-12 overflow-y-hidden",
      staticStyle: { "background-color": "#006db3 !important" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex items-center justify-center w-full max-w-sm col-span-12 p-4 mx-auto text-gray-900 md:p-8 flex-2 md:pb-48 md:pt-40",
        },
        [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("router-view"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pt-12 mt-0 text-sm not-italic font-medium leading-relaxed text-center text-white",
                },
                [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(
                      "Copyright @ Alcyon Italia - " +
                        _vm._s(new Date().getFullYear())
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/admin" } }, [
      _c("img", {
        staticClass:
          "block w-full h-auto max-w-full mb-4 text-primary-400 text-center",
        attrs: {
          src: "/assets/img/logo_alcyon_italia.png",
          alt: "Alcyon Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "mb-4 not-italic text-3xl leading-relaxed text-center text-white",
      },
      [_c("p", { staticClass: "mb-3" }, [_vm._v("REPORTING")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }