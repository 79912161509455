var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "item-create" },
        [
          _c(
            "sw-page-header",
            { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/dashboard",
                      title: _vm.$t("general.home"),
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "/admin/users",
                      title: _vm.$tc("users.user", 2),
                    },
                  }),
                  _vm._v(" "),
                  _vm.$route.name === "users.edit"
                    ? _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$t("users.edit_user"),
                          active: "",
                        },
                      })
                    : _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "#",
                          title: _vm.$t("users.new_user"),
                          active: "",
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c("template", { slot: "actions" }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-12" }, [
            _c("div", { staticClass: "col-span-12 md:col-span-8" }, [
              _c(
                "form",
                {
                  attrs: { action: "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitUser.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "sw-card",
                    [
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: {
                            label: _vm.$t("users.name"),
                            error: _vm.nameError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            label: _vm.$t("users.email"),
                            error: _vm.emailError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            attrs: {
                              invalid: _vm.$v.formData.email.$error,
                              type: "text",
                              name: "email",
                              "tab-index": "3",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.email.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            label: _vm.$tc("users.password"),
                            error: _vm.passwordError,
                            required: "",
                          },
                        },
                        [
                          _c("sw-input", {
                            staticClass: "mt-2",
                            attrs: {
                              invalid: _vm.$v.formData.password.$error,
                              type: "password",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.password.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "password", $$v)
                              },
                              expression: "formData.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "sw-input-group",
                        {
                          staticClass: "mt-4 mb-6",
                          attrs: { label: _vm.$t("users.phone") },
                        },
                        [
                          _c("sw-input", {
                            attrs: {
                              type: "text",
                              name: "phone",
                              "tab-index": "4",
                            },
                            model: {
                              value: _vm.formData.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isEdit,
                              expression: "!isEdit",
                            },
                          ],
                          staticClass:
                            "grid gap-6 sm:grid-col-1 md:grid-cols-2",
                        },
                        [
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.company_info.fiscalcode"
                                ),
                                error: _vm.fiscalCodeError,
                              },
                            },
                            [
                              _c("sw-input", {
                                attrs: {
                                  invalid: _vm.$v.formData.fiscalcode.$error,
                                  type: "text",
                                  name: "fiscalcode",
                                  placeholder: _vm.$t(
                                    "settings.company_info.fiscalcode"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.fiscalcode.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.address.fiscalcode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.address,
                                      "fiscalcode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.address.fiscalcode",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                label: _vm.$t(
                                  "settings.company_info.vat_number"
                                ),
                                error: _vm.vatNumberError,
                              },
                            },
                            [
                              _c("sw-input", {
                                attrs: {
                                  invalid: _vm.$v.formData.vatnumber.$error,
                                  type: "text",
                                  name: "vatnumber",
                                  placeholder: _vm.$t(
                                    "settings.company_info.vat_number"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.vatnumber.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.address.vatnumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.address,
                                      "vatnumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.address.vatnumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              attrs: {
                                label: _vm.$tc("settings.company_info.country"),
                                error: _vm.countryError,
                                required: "",
                              },
                            },
                            [
                              _c("sw-select", {
                                staticClass: "mt-2",
                                class: {
                                  error:
                                    _vm.$v.formData.address.country_id.$error,
                                },
                                attrs: {
                                  options: _vm.countries,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": false,
                                  placeholder: _vm.$t("general.select_country"),
                                  label: "name",
                                  "track-by": "id",
                                },
                                model: {
                                  value: _vm.country,
                                  callback: function ($$v) {
                                    _vm.country = $$v
                                  },
                                  expression: "country",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              attrs: {
                                label: _vm.$tc(
                                  "settings.company_info.province"
                                ),
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  placeholder: _vm.$tc(
                                    "settings.company_info.province"
                                  ),
                                  name: "state",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.formData.address.state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData.address, "state", $$v)
                                  },
                                  expression: "formData.address.state",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              attrs: {
                                label: _vm.$tc("settings.company_info.city"),
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  placeholder: _vm.$tc(
                                    "settings.company_info.city"
                                  ),
                                  name: "city",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.formData.address.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData.address, "city", $$v)
                                  },
                                  expression: "formData.address.city",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-input-group",
                            {
                              attrs: {
                                label: _vm.$tc("settings.company_info.zip"),
                              },
                            },
                            [
                              _c("sw-input", {
                                staticClass: "mt-2",
                                attrs: {
                                  placeholder: _vm.$tc(
                                    "settings.company_info.zip"
                                  ),
                                },
                                model: {
                                  value: _vm.formData.address.zip,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData.address, "zip", $$v)
                                  },
                                  expression: "formData.address.zip",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "sw-input-group",
                                {
                                  attrs: {
                                    label: _vm.$tc(
                                      "settings.company_info.address"
                                    ),
                                    error: _vm.address1Error,
                                  },
                                },
                                [
                                  _c("sw-textarea", {
                                    class: {
                                      invalid:
                                        _vm.$v.formData.address.address_street_1
                                          .$error,
                                    },
                                    attrs: {
                                      placeholder: _vm.$tc("general.street_1"),
                                      rows: "2",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.formData.address.address_street_1.$touch()
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.formData.address.address_street_1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.address,
                                          "address_street_1",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.address.address_street_1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-6 mb-4" },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex justify-center w-full md:w-auto",
                              attrs: {
                                loading: _vm.isLoading,
                                variant: "primary",
                                type: "submit",
                                size: "lg",
                              },
                            },
                            [
                              !_vm.isLoading
                                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.isEdit
                                      ? _vm.$t("users.update_user")
                                      : _vm.$t("users.save_user")
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }