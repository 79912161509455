import * as types from './mutation-types'

export const fetchDeadlines = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/deadlines`, { params })
      .then((response) => {
        commit(types.SET_DEADLINES, response.data.deadlines.data)
        commit(types.SET_TOTAL_DEADLINES, response.data.deadlineTotalCount)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchDeadline = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/deadlines/${id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addDeadline = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/v1/deadlines', data)
      .then((response) => {
        commit(types.ADD_DEADLINE, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateDeadline = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/v1/deadlines/${data.id}`, data)
      .then((response) => {
        commit(types.UPDATE_DEADLINE, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteDeadline = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .delete(`/api/v1/deadlines/${id}`)
      .then((response) => {
        if (response.data.success) {
          commit(types.DELETE_DEADLINE, id)
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
