<template>
  <tr class="box-border bg-white border border-gray-200 border-solid rounded-b">
    <td colspan="12" class="p-0 text-left align-top">
      <table class="w-full">
        <colgroup>
          <col style="width: 16%" />
          <col style="width: 14%" />
          <col style="width: 15%" />
          <col style="width: 9%" />
          <!--<col v-if="discountPerItem === 'YES'" style="width: 15%" />-->
          <col style="width: 16%" />
          <col style="width: 9%" />
          <col style="width: 7%" />
          <col style="width: 7%" />
          <col style="width: 7%" />
        </colgroup>
        <tbody>
          <tr>
            <!-- Conto -->
            <td class="px-2 py-4 text-left align-top">
              <div class="flex justify-start">
                <div
                  class="flex items-center justify-center w-12 h-5 mt-2 text-gray-400 cursor-move handle"
                >
                  <drag-icon />
                </div>

                <sw-select
                  ref="baseSelect"
                  v-model="selectedCategory"
                  :options="categories"
                  :searchable="true"
                  :show-labels="false"
                  :placeholder="$t('invoices.item.select_a_category')"
                  label="formattedCategory"
                  track-by="id"
                  @select="(val) => onSelectCategory(val)"
                  @deselect="onDeselectCategory"
                >
                  <sw-button
                    slot="afterList"
                    type="button"
                    variant="gray-light"
                    class="flex items-center justify-center w-full px-2 py-3 bg-gray-200 border-none outline-none"
                    @click="openCategoryModal"
                  >
                    <plus-icon class="h-5 text-center text-primary-400" />
                    <label class="ml-2 text-xs leading-none text-primary-400">{{
                      $t('settings.expense_category.add_new_category')
                    }}</label>
                  </sw-button>
                </sw-select>
              </div>
            </td>
            
            <!-- Sottoconto -->
            <td class="px-2 py-4 text-left align-top">
              <div class="flex justify-start">
                <sw-select
                  ref="baseSelect"
                  v-model="selectedSubcategory"
                  :options="subcats"
                  :searchable="true"
                  :show-labels="false"
                  :placeholder="$t('invoices.item.select_a_subcategory')"
                  label="formattedSubcategory"
                  track-by="id"
                  @select="(val) => onSelectSubcategory(val)"
                >
                  <sw-button
                    slot="afterList"
                    type="button"
                    variant="gray-light"
                    class="flex items-center justify-center w-full px-2 py-3 bg-gray-200 border-none outline-none"
                    @click="openSubcategoryModal"
                  >
                    <plus-icon class="h-5 text-center text-primary-400" />
                    <label class="ml-2 text-xs leading-none text-primary-400">{{
                      $t('settings.expense_subcategory.add_new_subcategory')
                    }}</label>
                  </sw-button>
                </sw-select>
              </div>
            </td>

            <!-- Descrizione -->
            <td class="px-2 py-4 text-right align-top">
              <sw-input
                v-model="item.name"
                :invalid="$v.item.name.$error"
                type="text"
                small
                @input="$v.item.name.$touch()"
              />
            </td>

            
            <!-- Item -->
            <!--
            <td class="px-2 py-4 text-right align-top">
              <item-select
                ref="itemSelect"
                :invalid="$v.item.name.$error"
                :invalid-description="$v.item.description.$error"
                :item="item"
                :tax-per-item="taxPerItem"
                :taxes="item.taxes"
                @search="searchVal"
                @select="onSelectItem"
                @deselect="deselectItem"
                @onDesriptionInput="$v.item.description.$touch()"
                @onSelectItem="isSelected = true"
              />
            </td>
            -->
            <!-- Quantità -->
            <!--
            <td class="px-2 py-4 text-right align-top">
              <sw-input
                v-model="item.quantity"
                :invalid="$v.item.quantity.$error"
                type="text"
                small
                @keyup="updateItem"
                @input="$v.item.quantity.$touch()"
              />
              <div v-if="$v.item.quantity.$error">
                <span v-if="!$v.item.quantity.maxLength" class="text-danger">
                  {{ $t('validation.quantity_maxlength') }}
                </span>
              </div>
            </td>
            -->

            <!-- Prezzo -->
            <td class="px-2 py-4 text-left align-top">
              <div class="flex flex-col">
                <div class="flex-auto flex-fill bd-highlight">
                  <div class="relative w-full">
                    <sw-money
                      v-model="price"
                      :currency="customerCurrency"
                      :invalid="$v.item.price.$error"
                      @input="$v.item.price.$touch();"
                    />
                  </div>
                  <div v-if="$v.item.price.$error">
                    <span v-if="!$v.item.price.maxLength" class="text-danger">
                      {{ $t('validation.price_maxlength') }}
                    </span>
                  </div>
                </div>
              </div>
            </td>

            <!-- IVA -->
            <td class="px-2 py-4 text-right align-top">
              <tax
                v-for="(tax, index) in item.taxes"
                :key="tax.id"
                :index="index"
                :tax-data="tax"
                :taxes="item.taxes"
                :motivation="item.tax_motivation"
                :discounted-total="total"
                :total-tax="totalSimpleTax"
                :total="total"
                :currency="currency"
                @update="updateTax"
                @remove="removeTax"
              />
            </td>

            <!-- Totale -->
            <td class="px-2 py-4 text-right align-top">
              <div class="flex items-center justify-center h-10 cursor-pointer text-sm">
                <span>
                  <div v-html="$utils.formatMoney(totalTTC, currency)" />
                </span>
              </div>
            </td>

            <!-- Enpav -->
            <td class="px-2 py-4 text-right align-top">
              <div class="flex items-center justify-center w-6 h-10 mx-2 text-xs cursor-pointer">
                <div class="relative w-12">
                  <sw-switch
                    v-model="calcolaEnpav"
                    class="absolute"
                    style="top: -25px"
                  />
                </div>
              </div>
            </td>

            <!-- Fuori campo -->
            <td class="px-2 py-4 text-right align-top">
              <div class="flex items-center justify-center w-6 h-10 mx-2 text-xs cursor-pointer">
                <div class="relative w-12">
                  <sw-switch
                    v-model="item.fctax"
                    class="absolute"
                    style="top: -25px"
                    @change="updateItem"
                  />
                </div>
              </div>
            </td>

            <!-- Indetraibile -->
            <td class="px-2 py-4 text-right align-top">
              <div class="flex items-center justify-end text-xs">
                <div class="relative w-12">
                  <sw-switch
                    v-model="item.indtax"
                    class="absolute"
                    style="top: -25px"
                    @change="updateItem"
                  />
                </div>

                <div class="flex items-center justify-center w-6 h-10 mx-2 cursor-pointer">
                  <trash-icon
                    v-if="showRemoveItemIcon"
                    class="h-5 text-gray-700"
                    @click="removeItem"
                  />
                </div>
              </div>
            </td>

          </tr>
          <!--
          <tr v-if="taxPerItem === 'YES'" class="tax-tr">
            <td class="px-5 py-4 text-left align-top" />
            <td colspan="4" class="px-5 py-4 text-left align-top">
              <tax
                v-for="(tax, index) in item.taxes"
                :key="tax.id"
                :index="index"
                :tax-data="tax"
                :taxes="item.taxes"
                :discounted-total="total"
                :total-tax="totalSimpleTax"
                :total="total"
                :currency="currency"
                @update="updateTax"
                @remove="removeTax"
              />
            </td>
          </tr>
          -->
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
import Guid from 'guid'
import { mapActions, mapGetters } from 'vuex'
import TaxStub from '../../stub/tax'
import InvoiceStub from '../../stub/invoice'
import ItemSelect from './ItemSelect'
import Tax from './Tax'
import { TrashIcon, ViewGridIcon, ChevronDownIcon, ShoppingCartIcon, PlusIcon } from '@vue-hero-icons/solid'
import DragIcon from '@/components/icon/DragIcon'

const {
  required,
  minValue,
  between,
  maxLength,
} = require('vuelidate/lib/validators')

export default {
  components: {
    Tax,
    ItemSelect,
    TrashIcon,
    ViewGridIcon,
    ChevronDownIcon,
    DragIcon,
    ShoppingCartIcon,
    PlusIcon,
  },
  props: {
    itemData: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    currency: {
      type: [Object, String],
      required: true,
    },
    taxPerItem: {
      type: String,
      default: '',
    },
    discountPerItem: {
      type: String,
      default: '',
    },
    invoiceItems: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isClosePopup: false,
      itemSelect: null,
      item: { ...this.itemData },
      maxDiscount: 0,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
      },
      isSelected: false,
      selectedCategory: null,
      selectedSubcategory: null,
      subcats: [],
      calcolaEnpav: false,
    }
  },
  computed: {
    ...mapGetters('item', ['items']),
    ...mapGetters('modal', ['modalActive']),
    ...mapGetters('company', ['defaultCurrencyForInput']),
    ...mapGetters('category', ['categories']),
    ...mapGetters('subcategory', ['subcategories']),

    customerCurrency() {
      if (this.currency) {
        return {
          decimal: this.currency.decimal_separator,
          thousands: this.currency.thousand_separator,
          prefix: this.currency.symbol + ' ',
          precision: this.currency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
    showRemoveItemIcon() {
      if (this.invoiceItems.length == 1) {
        return false
      }
      return true
    },
    subtotal() {
      return this.item.price * this.item.quantity
    },
    discount: {
      get: function () {
        return this.item.discount
      },
      set: function (newValue) {
        if (this.item.discount_type === 'percentage') {
          this.item.discount_val = (this.subtotal * newValue) / 100
        } else {
          this.item.discount_val = Math.round(newValue * 100)
        }

        this.item.discount = newValue
      },
    },
    total() {
      return this.subtotal + this.item.enpav - this.item.discount_val
    },
    totalTTC() {
      return this.subtotal - this.item.discount_val + this.totalTax
    },
    totalSimpleTax() {
      return Math.round(
        window._.sumBy(this.item.taxes, function (tax) {
          if (!tax.compound_tax) {
            return tax.amount
          }

          return 0
        })
      )
    },
    totalCompoundTax() {
      return Math.round(
        window._.sumBy(this.item.taxes, function (tax) {
          if (tax.compound_tax) {
            return tax.amount
          }

          return 0
        })
      )
    },
    totalTax() {
      return this.totalSimpleTax + this.totalCompoundTax
    },
    price: {
      get: function () {
        if (parseFloat(this.item.price) > 0) {
          return this.item.price / 100
        }

        return this.item.price / 100
      },
      set: function (newValue) {
        if (parseFloat(newValue) > 0) {
          this.item.price = Math.round(newValue * 100)
          this.maxDiscount = this.item.price
        } else {
          this.item.price = newValue * 100
        }
      },
    },
  },
  watch: {
    item: {
      handler: 'updateItem',
      deep: true,
    },
    selectCategory(newValue) {
      this.item.category_id = newValue.id
    },
    selectSubcategory(newValue) {
      this.item.subcategory_id = newValue.id
    },
    subtotal(newValue) {
      if (this.item.discount_type === 'percentage') {
        this.item.discount_val = (this.item.discount * newValue) / 100
      }
    },
    modalActive(val) {
      if (!val) {
        this.isSelected = false
      }
    },
    calcolaEnpav: function (newValue) {
      if (newValue) {
        // Calcola l'enpav, subtotal è gia diviso 100 quindi non devo fare di nuovo la divisione qui
        this.item.enpav = Math.round(this.subtotal * 0.02);

      } else {
        // Se viene disattivato imposto l'enpav a null
        this.item.enpav = null;
      }
      this.updateItem();
    }
  },
  validations() {
    return {
      item: {
        name: {
          required,
        },
        /*quantity: {
          required,
          minValue: minValue(0),
          maxLength: maxLength(20),
        },*/
        price: {
          required,
          // minValue: minValue(0),
          maxLength: maxLength(20),
        },
        discount_val: {
          between: between(0, this.maxDiscount),
        },
        description: {
          maxLength: maxLength(65000),
        },
      },
    }
  },
  mounted() {
    this.$v.item.$reset()
  },
  created() {
    window.hub.$on('checkItems', this.validateItem)
    window.hub.$on('newItem', (val) => {
      if (this.taxPerItem === 'YES') {
        this.item.taxes = val.taxes
      }
      if (!this.item.item_id && this.modalActive && this.isSelected) {
        this.onSelectItem(val)
      }
    })

    window.hub.$on('newCategory', (val) => {
      this.selectedCategory = val
      this.selectedSubcategory = null
      this.item.category_id = val.id
      this.updateSubcategories({ limit: 'all', 'category_id': val.id })
    })

    window.hub.$on('newSubcategory', (val) => {
      this.selectedSubcategory = val
      this.item.subcategory_id = val.id
    })

    if (this.item.category_id > 0) {
      this.selectedCategory = this.categories.find(
        (_cat) => _cat.id === this.item.category_id
      )
    }

    if (this.item.subcategory_id > 0) {
      this.updateSubcategories({ limit: 'all', 'category_id': this.item.category_id })
      
      // Get Selected Subcategory Data
      new Promise((resolve, reject) => {
        window.axios
          .get(`/api/v1/subcategories/${this.item.subcategory_id}`)
          .then((response) => {
            this.selectedSubcategory = response.data.subcategory
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }

    // quando creo il componente controllo se la riga fattura ha l'enpav, se c'è 
    // imposto il toggle a true
    this.calcolaEnpav = this.item.enpav ? true : false;  
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('subcategory_id', ['fetchSubcategories']),

    openCategoryModal() {
      this.openModal({
        title: this.$t('settings.expense_category.add_category'),
        componentName: 'CategoryModal',
      })
    },

    openSubcategoryModal() {
      this.openModal({
        title: this.$t('settings.expense_subcategory.add_subcategory'),
        componentName: 'SubcategoryModal',
      })
    },

    onSelectCategory(val) {
      this.item.category_id = val.id
      this.selectedSubcategory = null
      this.updateSubcategories({ limit: 'all', 'category_id': val.id })
    },
    onDeselectCategory(val) {
      this.selectedSubcategory = null
      this.updateSubcategories({ limit: 'all', 'category_id': -1 })
    },
    updateSubcategories (params) {
      new Promise((resolve, reject) => {
        window.axios
          .get(`/api/v1/subcategories`, { params })
          .then((response) => {
            this.subcats = response.data.subcategories.data
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })

    },

    onSelectSubcategory(val) {
      this.item.subcategory_id = val.id
    },

    updateTax(data) {
      this.$set(this.item.taxes, data.index, data.item)

      let lastTax = this.item.taxes[this.item.taxes.length - 1]
      this.item.tax_motivation = data.tax_motivation
/*
      if (lastTax.tax_type_id !== 0) {
        this.item.taxes.push({ ...TaxStub, id: Guid.raw() })
      }
*/
      this.updateItem()
    },
    removeTax(index) {
      this.item.taxes.splice(index, 1)
      this.item.taxes.push({ ...TaxStub, id: Guid.raw() })

      this.updateItem()
    },
    taxWithPercentage({ name, percent }) {
      return `${name} (${percent}%)`
    },
    searchVal(val) {
      this.item.name = val
    },
    deselectItem() {
      this.item = {
        ...InvoiceStub,
        id: this.item.id,
        taxes: [{ ...TaxStub, id: Guid.raw() }],
      }
      this.$nextTick(() => {
        this.$refs.itemSelect.$refs.baseSelect.$refs.search.focus()
      })
    },
    onSelectItem(item) {
      this.item.name = item.name
      this.item.price = item.price
      this.item.enpav = item.enpav
      this.item.item_id = item.id
      this.item.description = item.description
      this.item.unit_name = item.unit_name

      if (this.taxPerItem === 'YES' && item.taxes) {
        let index = 0
        item.taxes.forEach((tax) => {
          this.updateTax({ index, item: { ...tax } })
          index++
        })
      }
    },
    selectFixed() {
      if (this.item.discount_type === 'fixed') {
        return
      }

      this.item.discount_val = Math.round(this.item.discount * 100)
      this.item.discount_type = 'fixed'
    },
    selectPercentage() {
      if (this.item.discount_type === 'percentage') {
        return
      }

      this.item.discount_val = (this.subtotal * this.item.discount) / 100

      this.item.discount_type = 'percentage'
    },
    updateItem() {
      // In caso di aggiornamento dell'item ricalcolo l'enpav
      if(this.calcolaEnpav){
        this.item.enpav = Math.round(this.subtotal * 0.02);  
      } else {
        this.item.enpav = null;
      }

      this.$emit('update', {
        index: this.index,
        item: {
          ...this.item,
          total: this.total + this.totalTax,
          totalSimpleTax: this.totalSimpleTax,
          totalCompoundTax: this.totalCompoundTax,
          totalTax: this.totalTax,
          tax: this.totalTax,
          taxes: [...this.item.taxes],
        },
      })
    },
    removeItem() {
      this.$emit('remove', this.index)
    },
    validateItem() {
      this.$v.item.$touch()

      if (this.item !== null) {
        this.$emit('itemValidate', this.index, !this.$v.$invalid)
      } else {
        this.$emit('itemValidate', this.index, false)
      }
    },
  },
}
</script>
