var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAppLoaded
    ? _c(
        "div",
        { staticClass: "h-full" },
        [
          _c("base-modal"),
          _vm._v(" "),
          _c("site-header"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex h-screen pt-16 pb-10 overflow-hidden" },
            [_c("site-sidebar"), _vm._v(" "), _c("router-view")],
            1
          ),
          _vm._v(" "),
          _c("site-footer"),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "h-full" },
        [_c("refresh-icon", { staticClass: "h-6 animate-spin" })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }