import * as types from './mutation-types'

export const addPaymentMode = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/payment-methods`, data)
      .then((response) => {
        commit(types.ADD_PAYMENT_MODE, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updatePaymentMode = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/v1/payment-methods/${data.id}`, data)
      .then((response) => {
        commit(types.UPDATE_PAYMENT_MODE, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchPaymentModes = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/payment-methods`, { params })
      .then((response) => {
        commit(types.SET_PAYMENT_MODES, response.data.paymentMethods.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchPaymentMode = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/payment-methods/${data.id}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deletePaymentMode = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .delete(`/api/v1/payment-methods/${id}`)
      .then((response) => {
        if (!response.data.error) {
          commit(types.DELETE_PAYMENT_MODE, id)
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
