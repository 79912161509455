var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitCustomerData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "flex-1 p-5 sm:p-6" },
          [
            _c(
              "sw-tabs",
              [
                _c(
                  "sw-tab-item",
                  { staticClass: "mt-5", attrs: { title: "Informazioni" } },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("customers.display_name"),
                          error: _vm.nameError,
                          variant: "horizontal",
                          required: "",
                        },
                      },
                      [
                        _c("sw-input", {
                          ref: "name",
                          staticClass: "mt-1 md:mt-0",
                          attrs: {
                            invalid: _vm.$v.formData.name.$error,
                            type: "text",
                            name: "name",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.name.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("login.email"),
                          error: _vm.emailError,
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: {
                            invalid: _vm.$v.formData.email.$error,
                            type: "text",
                            name: "email",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.email.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.email,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("customers.phone"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: { type: "text", name: "phone" },
                          model: {
                            value: _vm.formData.phone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("customers.fiscalcode"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: { type: "text", name: "fiscalcode" },
                          model: {
                            value: _vm.billing.fiscalcode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.billing,
                                "fiscalcode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "billing.fiscalcode",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("customers.vatnumber"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: { type: "text", name: "vatnumber" },
                          model: {
                            value: _vm.billing.vatnumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.billing,
                                "vatnumber",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "billing.vatnumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-tab-item",
                  { staticClass: "mt-5", attrs: { title: "Indirizzo" } },
                  [
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("customers.address"),
                          error: _vm.bill1Error,
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-textarea", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: {
                            placeholder: _vm.$t("general.street_1"),
                            rows: "2",
                            cols: "50",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.billing.address_street_1.$touch()
                            },
                          },
                          model: {
                            value: _vm.billing.address_street_1,
                            callback: function ($$v) {
                              _vm.$set(_vm.billing, "address_street_1", $$v)
                            },
                            expression: "billing.address_street_1",
                          },
                        }),
                        _vm._v(" "),
                        _c("br"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("customers.zip_code"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: { type: "text" },
                          model: {
                            value: _vm.billing.zip,
                            callback: function ($$v) {
                              _vm.$set(_vm.billing, "zip", $$v)
                            },
                            expression: "billing.zip",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("customers.city"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: { type: "text", name: "billingCity" },
                          model: {
                            value: _vm.billing.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.billing, "city", $$v)
                            },
                            expression: "billing.city",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("customers.province"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: { type: "text", name: "billingState" },
                          model: {
                            value: _vm.billing.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.billing, "state", $$v)
                            },
                            expression: "billing.state",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          label: _vm.$t("customers.country"),
                          variant: "horizontal",
                        },
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1 md:mt-0",
                          attrs: {
                            options: _vm.countries,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("general.select_country"),
                            "allow-empty": false,
                            "track-by": "id",
                            label: "name",
                          },
                          model: {
                            value: _vm.billingCountry,
                            callback: function ($$v) {
                              _vm.billingCountry = $$v
                            },
                            expression: "billingCountry",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3 text-sm",
                attrs: { type: "button", variant: "primary-outline" },
                on: { click: _vm.cancelCustomer },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.cancel")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  variant: "primary",
                  type: "submit",
                  loading: _vm.isLoading,
                },
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("general.save")) + "\n        "
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }