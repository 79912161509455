<template>
  <tr class="box-border bg-white border border-gray-200 border-solid rounded-b">
    <td colspan="12" class="p-0 text-left align-top">
      <table class="w-full">
        <colgroup>
          <col style="width: 30%" />
          <col style="width: 20%" />
          <col style="width: 35%" />
          <col style="width: 5%" />
          <col style="width: 10%" />
        </colgroup>
        <tbody>
          <tr>
            <!-- Data -->
            <td class="px-2 py-4 text-left align-top">
              <div class="flex justify-start">
                <div
                  class="flex items-center justify-center w-12 h-5 mt-2 text-gray-400 cursor-move handle"
                >
                  <drag-icon />
                </div>
                
                <base-date-picker
                  v-model="deadline.deadline_date"
                  :invalid="$v.deadline.deadline_date.$error"
                  :calendar-button="true"
                  calendar-button-icon="calendar"
                  @input="$v.deadline.deadline_date.$touch()"
                  @change="updateDeadline"
                />
              </div>
            </td>

            <!-- Importo -->
            <td class="px-2 py-4 text-left align-top">
              <sw-money
                v-model="amount"
                :currency="deadlineCurrency"
                :invalid="$v.deadline.amount.$error"
                @input="$v.deadline.amount.$touch()"
                @keyup="updateDeadline"
              />
            </td>

            <!-- Modalità -->
            <td class="px-2 py-4 text-left align-top">
              <sw-select
                ref="baseSelect"
                v-model="selectedMethod"
                :options="paymentModesVrb"
                :searchable="true"
                :show-labels="false"
                :placeholder="$t('invoices.deadline.select_a_method')"
                label="name"
                track-by="id"
                @select="(val) => onSelectMethod(val)"
              >
              </sw-select>
            </td>

            <!-- Ritenuta -->
            <td>
              <div class="flex items-center w-full h-10 mx-2 cursor-pointer">
                <span v-if="showRitenutaIcon" class="h-5 text-gray-700">R</span>
              </div>
            </td>
            
            <!-- Pulsanti -->
            <td>
              <div class="flex items-center w-full h-10 mx-2 cursor-pointer">
                <currency-euro-icon
                  v-if="showPayDeadlineIcon"
                  class="h-5 text-gray-700"
                  @click.prevent="payDeadline"
                />

                <check-circle-icon
                  v-if="showPaidDeadlineIcon"
                  class="h-5 text-green-700"
                />

                <trash-icon
                  v-if="showRemoveDeadlineIcon"
                  class="h-5 text-gray-700"
                  @click="removeDeadline"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TrashIcon, ViewGridIcon, ChevronDownIcon, ShoppingCartIcon, CurrencyEuroIcon, CheckCircleIcon } from '@vue-hero-icons/solid'
import DragIcon from '@/components/icon/DragIcon'
const {
  required,
  between,
} = require('vuelidate/lib/validators')

export default {
  components: {
    TrashIcon,
    ViewGridIcon,
    ChevronDownIcon,
    DragIcon,
    ShoppingCartIcon,
    CurrencyEuroIcon,
    CheckCircleIcon,
  },
  props: {
    deadlineData: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    currency: {
      type: [Object, String],
      required: true,
    },
    invoiceDeadlines: {
      type: Array,
      default: null,
    },
    invoiceTotal: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: null,
    },
    documentType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      deadline: { ...this.deadlineData },
      selectedMethod: null,
      //changeSelected: null,
      paymentModesVrb: null,
      total: this.invoiceTotal
    }
  },
  
  validations() {
    return {
      deadline: {
        deadline_date: {
          required,
        },
        amount: {
          required
        }
      }
    }
  },

  computed: {
    ...mapGetters('deadline', ['deadlines']),
    ...mapGetters('paymentMethod', ['paymentModes']),

    deadlineCurrency() {
      if (this.currency) {
        return {
          decimal: this.currency.decimal_separator,
          thousands: this.currency.thousand_separator,
          prefix: this.currency.symbol + ' ',
          precision: this.currency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },

    showRitenutaIcon() {
      if(!this.deadline.ritenuta) {
        return false
      }
      return true
    },

    showPayDeadlineIcon() {
      if(this.isEdit && this.deadline.sold == 0 && this.deadline.new != 1) {
        return true
      }
      return false
    },

    showPaidDeadlineIcon() {
      if(this.isEdit && this.deadline.sold == 1) {
        return true
      }
      return false
    },

    showRemoveDeadlineIcon() {
      if (this.invoiceDeadlines.length == 1 || this.deadline.sold == 1) {
        return false
      }
      return true
    },
    
    amount: {
      get: function () {
        if (parseFloat(this.deadline.amount) > 0) {
          return this.deadline.amount / 100
        }

        return this.deadline.amount
      },
      set: function (newValue) {
        if (parseFloat(newValue) > 0) {
          this.deadline.amount = Math.round(newValue * 100)
          this.maxDiscount = this.deadline.amount
        } else {
          this.deadline.amount = newValue
        }
      },
    },
  },

  watch: {
    deadline: {
      handler: 'updateDeadline',
      deep: true,
    },
    documentType: {
      handler: function(value){
        this.setMethodTD(value)
      },
      deep: true,
    }
  },

  created() {
    window.hub.$on('checkDeadlines', this.validateDeadline)
    window.hub.$on('totalChanged', (val) => {
      this.total = val
    })
    this.paymentModesVrb = this.paymentModes
    this.setMethodTD(this.document_type)
    this.loadMethods()
  },

  mounted() {
    this.$v.deadline.$reset()
  },

  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('paymentMethod', ['fetchPaymentModes']),

    async loadMethods() {
      let isCreditNote = this.documentType == "TD04" ? 1 : 0
      let methods = {}
      await this.fetchPaymentModes({ limit: 'all', creditnote: isCreditNote }).then((response) => {
          methods = response.data.paymentMethods.data
      })
      methods.push({id: 1, name: 'Stornata'})
      
      if (this.deadline.deadline_method_id > 0) {
        this.selectedMethod = methods.find(
          (_method) => _method.id === this.deadline.deadline_method_id
        )
      }
    },

    onSelectMethod(val) {
      this.deadline.deadline_method_id = val.id

      this.updateDeadline()
    },

    updateDeadline() {
      this.$emit('update', {
        index: this.index,
        deadline: {...this.deadline},
      })
    },

    removeDeadline() {
      this.$emit('remove', this.index)
    },

    validateDeadline() {
      this.$v.deadline.$touch()

      if (this.deadline !== null) {
        this.$emit('deadlineValidate', this.index, !this.$v.$invalid)
      } else {
        this.$emit('deadlineValidate', this.index, false)
      }
    },

    payDeadline() {
      this.openModal({
        title: this.$t('payments.new_payment'),
        componentName: 'PaymentModal',
        data: {...this.deadline, currency: this.currency, document_type: this.documentType},
      })
    },

    setMethodTD(value){
      if(value == 'TD04'){
          if(this.paymentModesVrb[this.paymentModesVrb.length-1].id != 1)
            this.paymentModesVrb.push({id: 1, name: 'Stornata'})
        }else{
          if(this.paymentModesVrb[this.paymentModesVrb.length-1].id == 1)
            this.paymentModesVrb.splice(this.paymentModesVrb.length-1, 1)
        }
    }
  }
}
</script>
