var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    { staticClass: "customer-create" },
    [
      !_vm.initLoad
        ? _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitCustomerData.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-page-header",
                { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
                [
                  _c(
                    "sw-breadcrumb",
                    { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                    [
                      _c("sw-breadcrumb-item", {
                        attrs: {
                          to: "/admin/dashboard",
                          title: _vm.$t("general.home"),
                        },
                      }),
                      _vm._v(" "),
                      _vm.shouldShowBreadcrumb
                        ? _c("li", { staticClass: "pr-2 text-sm" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "router-link-exact-active active pr-2 m-0 leading-5 text-gray-900 text-sm cursor-pointer",
                                on: { click: _vm.goToPreviousPage },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$tc("customers.customer", 2)) +
                                    " "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "px-1" }, [_vm._v("/")]),
                          ])
                        : _c("sw-breadcrumb-item", {
                            attrs: {
                              to: "/admin/customers",
                              title: _vm.$tc("customers.customer", 2),
                            },
                          }),
                      _vm._v(" "),
                      _vm.$route.name === "customers.edit"
                        ? _c("sw-breadcrumb-item", {
                            attrs: {
                              to: "#",
                              title: _vm.$t("customers.edit_customer"),
                              active: "",
                            },
                          })
                        : _c("sw-breadcrumb-item", {
                            attrs: {
                              to: "#",
                              title: _vm.$t("customers.new_customer"),
                              active: "",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "actions" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "hidden md:relative md:flex",
                          attrs: {
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n\n          " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("customers.update_customer")
                                  : _vm.$t("customers.save_customer")
                              ) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "sw-card",
                { attrs: { variant: "customer-card" } },
                [
                  _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
                    _c(
                      "h6",
                      {
                        staticClass:
                          "col-span-5 sw-section-title lg:col-span-1",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("customers.basic_info")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                      },
                      [
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "md:col-span-3",
                            attrs: {
                              label: _vm.$t("customers.display_name"),
                              error: _vm.displayNameError,
                              required: "",
                            },
                          },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid: _vm.$v.formData.name.$error,
                                focus: "",
                                type: "text",
                                name: "name",
                                tabindex: "1",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.name.$touch()
                                },
                              },
                              model: {
                                value: _vm.formData.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "name", $$v)
                                },
                                expression: "formData.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "md:col-span-3" }),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "md:col-span-3",
                            attrs: {
                              label: _vm.$t("customers.email"),
                              error: _vm.emailError,
                            },
                          },
                          [
                            _c("sw-input", {
                              attrs: {
                                invalid: _vm.$v.formData.email.$error,
                                type: "text",
                                name: "email",
                                tabindex: "2",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.formData.email.$touch()
                                },
                              },
                              model: {
                                value: _vm.formData.email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "email",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "formData.email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "md:col-span-3",
                            attrs: { label: _vm.$t("customers.phone") },
                          },
                          [
                            _c("sw-input", {
                              attrs: {
                                type: "text",
                                name: "phone",
                                tabindex: "3",
                              },
                              model: {
                                value: _vm.formData.phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "phone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "formData.phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t("customers.fiscalcode"),
                                },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    tabindex: "4",
                                    type: "text",
                                    name: "fiscalcode",
                                  },
                                  model: {
                                    value: _vm.billing.fiscalcode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billing,
                                        "fiscalcode",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "billing.fiscalcode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "md:col-span-3",
                                attrs: { label: _vm.$t("customers.vatnumber") },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    name: "vatnumber",
                                    type: "text",
                                    tabindex: "5",
                                  },
                                  model: {
                                    value: _vm.billing.vatnumber,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.billing, "vatnumber", $$v)
                                    },
                                    expression: "billing.vatnumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("sw-divider", { staticClass: "mb-5 md:mb-8" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "grid grid-cols-5 gap-4 mb-8" }, [
                    _c(
                      "h6",
                      {
                        staticClass:
                          "col-span-5 sw-section-title lg:col-span-1",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("customers.address")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                attrs: {
                                  label: _vm.$t("customers.address"),
                                  error: _vm.billAddress1Error,
                                },
                              },
                              [
                                _c("sw-textarea", {
                                  attrs: {
                                    placeholder: _vm.$t("general.street_1"),
                                    type: "text",
                                    name: "billing_street1",
                                    rows: "2",
                                    tabindex: "6",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.billing.address_street_1.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.billing.address_street_1,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billing,
                                        "address_street_1",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "billing.address_street_1",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "md:col-span-3" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                attrs: { label: _vm.$t("customers.zip_code") },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    tabindex: "7",
                                    type: "text",
                                    name: "zip",
                                  },
                                  model: {
                                    value: _vm.billing.zip,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.billing,
                                        "zip",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "billing.zip",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:col-span-3" },
                          [
                            _c(
                              "sw-input-group",
                              {
                                staticClass: "md:col-span-3",
                                attrs: { label: _vm.$t("customers.city") },
                              },
                              [
                                _c("sw-input", {
                                  attrs: {
                                    name: "billing.city",
                                    type: "text",
                                    tabindex: "8",
                                  },
                                  model: {
                                    value: _vm.billing.city,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.billing, "city", $$v)
                                    },
                                    expression: "billing.city",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "md:col-span-3",
                            attrs: { label: _vm.$t("customers.province") },
                          },
                          [
                            _c("sw-input", {
                              attrs: {
                                name: "billing.state",
                                type: "text",
                                tabindex: "9",
                              },
                              model: {
                                value: _vm.billing.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.billing, "state", $$v)
                                },
                                expression: "billing.state",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-input-group",
                          {
                            staticClass: "md:col-span-3",
                            attrs: { label: _vm.$t("customers.country") },
                          },
                          [
                            _c("sw-select", {
                              attrs: {
                                options: _vm.countries,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": true,
                                tabindex: 10,
                                placeholder: _vm.$t("general.select_country"),
                                label: "name",
                                "track-by": "id",
                              },
                              model: {
                                value: _vm.billing_country,
                                callback: function ($$v) {
                                  _vm.billing_country = $$v
                                },
                                expression: "billing_country",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.customFields.length > 0
                    ? _c("sw-divider", { staticClass: "mb-5 md:mb-8" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.customFields.length > 0
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-5 gap-4 mb-8" },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "col-span-5 sw-section-title lg:col-span-1",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("settings.custom_fields.title")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid col-span-5 lg:col-span-4 gap-y-6 gap-x-4 md:grid-cols-6",
                            },
                            _vm._l(_vm.customFields, function (field, index) {
                              return _c(
                                "sw-input-group",
                                {
                                  key: index,
                                  staticClass: "md:col-span-3",
                                  attrs: {
                                    label: field.label,
                                    required: field.is_required ? true : false,
                                  },
                                },
                                [
                                  _c(field.type + "Field", {
                                    tag: "component",
                                    attrs: {
                                      type: field.type.label,
                                      field: field,
                                      isEdit: _vm.isEdit,
                                      "invalid-fields": _vm.invalidFields,
                                      tabindex: 23 + index,
                                    },
                                    on: { update: _vm.setCustomFieldValue },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "flex w-full sm:hidden md:hidden",
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        variant: "primary",
                        type: "submit",
                        size: "lg",
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                        : _vm._e(),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.isEdit
                              ? _vm.$t("customers.update_customer")
                              : _vm.$t("customers.save_customer")
                          ) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("base-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }