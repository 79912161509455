var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    { staticClass: "deadlines" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("deadlines.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "dashboard", title: _vm.$t("general.home") },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("deadlines.deadline", 2),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalDeadlines,
                      expression: "totalDeadlines",
                    },
                  ],
                  attrs: { variant: "primary-outline", size: "lg" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "mt-3",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: {
                    label: _vm.$t("general.from"),
                    color: "black-light",
                  },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.from_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "from_date", $$v)
                      },
                      expression: "filters.from_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "hidden w-4 h-0 mx-4 border border-gray-400 border-solid xl:block",
                staticStyle: { "margin-top": "3.5rem" },
              }),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: { label: _vm.$t("general.to"), color: "black-light" },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.to_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "to_date", $$v)
                      },
                      expression: "filters.to_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 lg:ml-6",
                  attrs: {
                    label: _vm.$t("payments.customer"),
                    color: "black-light",
                  },
                },
                [
                  _c("base-customer-select", {
                    ref: "customerSelect",
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 lg:ml-6",
                  attrs: { label: _vm.$t("deadlines.ref_number") },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.deadlines.ref_number),
                      name: "invoice_number",
                    },
                    model: {
                      value: _vm.filters.invoice_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "invoice_number", $$v)
                      },
                      expression: "filters.invoice_number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 lg:ml-6",
                  attrs: { label: _vm.$t("deadlines.invoice_number") },
                },
                [
                  _c("sw-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.deadlines.invoice_number),
                      name: "ref_number",
                    },
                    model: {
                      value: _vm.filters.ref_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "ref_number", $$v)
                      },
                      expression: "filters.ref_number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 lg:ml-6",
                  attrs: { label: _vm.$t("payments.payment_mode") },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.paymentModes,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("payments.payment_mode"),
                      label: "name",
                    },
                    model: {
                      value: _vm.filters.payment_mode,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "payment_mode", $$v)
                      },
                      expression: "filters.payment_mode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEmptyScreen
        ? _c(
            "sw-empty-table-placeholder",
            {
              attrs: {
                title: _vm.$t("deadlines.no_deadlines"),
                description: _vm.$t("deadlines.list_of_deadlines"),
              },
            },
            [_c("capsule-icon", { staticClass: "mt-5 mb-4" })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.deadlines.length))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalDeadlines))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("deadlines.date"),
                  "sort-as": "deadline_date",
                  show: "formattedDeadlineDate",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("deadlines.payment_mode"),
                  show: "method",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("deadlines.ref_number"),
                  show: "invoice_number",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("deadlines.ref_number"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: {
                                path: "invoices/" + row.invoice_id + "/edit",
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.invoice_number) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("deadlines.invoice_number"),
                  show: "reference_number",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("deadlines.description"),
                  show: "description",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("deadlines.customer"),
                  show: "supplier",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: true, label: _vm.$t("deadlines.amount") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("deadlines.amount"))),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.amount,
                                row.user.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("deadlines.ritenuta"),
                  show: "ritenuta",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("deadlines.ritenuta"))),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.ritenuta ? "Sì" : "No") +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("payments.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: "invoices/" + row.invoice_id + "/edit",
                                },
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.payDeadline(row)
                                  },
                                },
                              },
                              [
                                _c("currency-euro-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("deadlines.pay")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "text-right mt-8",
        },
        [
          _c(
            "sw-button",
            {
              attrs: { size: "lg", variant: "primary" },
              on: {
                click: function ($event) {
                  return _vm.download()
                },
              },
            },
            [
              _c("download-icon", { staticClass: "h-5 mr-1 -ml-2" }),
              _vm._v(
                "\n      " + _vm._s(_vm.$t("reports.download_excel")) + "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }