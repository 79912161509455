var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex items-center justify-between mb-3" }, [
    _c(
      "div",
      { staticClass: "flex items-center", staticStyle: { flex: "3" } },
      [
        _c("sw-select", {
          attrs: {
            options: _vm.filteredTypes,
            "allow-empty": false,
            "show-labels": false,
            "custom-label": _vm.customLabel,
            placeholder: _vm.$t("general.select_a_tax"),
            "track-by": "name",
            label: "name",
          },
          on: {
            select: function (val) {
              return _vm.onSelectTax(val)
            },
          },
          model: {
            value: _vm.selectedTax,
            callback: function ($$v) {
              _vm.selectedTax = $$v
            },
            expression: "selectedTax",
          },
        }),
        _vm._v(" "),
        _c("br"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
      },
      [
        _c("trash-icon", {
          staticClass: "h-5 text-gray-700",
          on: { click: _vm.removeTax },
        }),
      ],
      1
    ),
    _vm._v(" "),
    this.tax.percent != 0
      ? _c("div", [
          _c("div", {
            staticClass: "text-xs text-right",
            staticStyle: { flex: "2" },
            domProps: {
              innerHTML: _vm._s(
                _vm.$utils.formatMoney(_vm.taxAmount, _vm.currency)
              ),
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    this.tax.percent == 0
      ? _c(
          "div",
          { staticClass: "flex items-center", staticStyle: { flex: "2" } },
          [
            _c("sw-select", {
              attrs: {
                options: _vm.filteredMotivation,
                "allow-empty": false,
                "show-labels": false,
                placeholder: _vm.$t("general.tax_motivation"),
                "custom-label": _vm.motivationsLabel,
                "track-by": "name",
                label: "name",
              },
              on: {
                select: function (val) {
                  return _vm.onSelectMotivation(val)
                },
              },
              model: {
                value: _vm.tax_motivation,
                callback: function ($$v) {
                  _vm.tax_motivation = $$v
                },
                expression: "tax_motivation",
              },
            }),
            _vm._v(" "),
            _c("information-circle-icon", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.left",
                  value: {
                    content:
                      "N1: operazioni escluse ex articolo 15<br>" +
                      "N2.1: non soggette ad IVA (artt. da 7 a 7-septies del DPR 633/72)<br>" +
                      "N2.2: non soggette (altri casi)<br>" +
                      "N3.1: non imponibili per l’esportazioni<br>" +
                      "N3.2: non imponibili per cessioni intracomunitarie<br>" +
                      "N3.3: non imponibili per cessioni verso San Marino<br>" +
                      "N3.4: non imponibili per operazioni assimilate alle cessioni all’esportazione<br>" +
                      "N3.5: non imponibili a seguito di dichiarazioni d’intento<br>" +
                      "N3.6: non imponibili per altre operazioni che non concorrono alla formazione del plafond<br>" +
                      "N4: esenti<br>" +
                      "N5: regime del margine IVA non esposta in fattura<br>" +
                      "N6.1: inversione contabile per la cessione di rottami e altri materiali di recupero<br>" +
                      "N6.2: inversione contabile per la cessione di oro e argento puro<br>" +
                      "N6.3: inversione contabile per la subappalto nel settore edile<br>" +
                      "N6.4: inversione contabile per la cessione di fabbricati<br>" +
                      "N6.5: inversione contabile per la cessione di telefoni cellulari<br>" +
                      "N6.6: inversione contabile per la cessione di prodotti elettronici<br>" +
                      "N6.7: inversione contabile per la prestazioni comparto edile e settori connessi<br>" +
                      "N6.8: inversione contabile per la operazioni settore energetico<br>" +
                      "N6.9: inversione contabile per la altri casi<br>" +
                      "N7: IVA assolta in altro stato UE",
                  },
                  expression:
                    "{\n        content:\n          'N1: operazioni escluse ex articolo 15<br>' +\n          'N2.1: non soggette ad IVA (artt. da 7 a 7-septies del DPR 633/72)<br>' +\n          'N2.2: non soggette (altri casi)<br>' +\n          'N3.1: non imponibili per l’esportazioni<br>' +\n          'N3.2: non imponibili per cessioni intracomunitarie<br>' +\n          'N3.3: non imponibili per cessioni verso San Marino<br>' +\n          'N3.4: non imponibili per operazioni assimilate alle cessioni all’esportazione<br>' +\n          'N3.5: non imponibili a seguito di dichiarazioni d’intento<br>' +\n          'N3.6: non imponibili per altre operazioni che non concorrono alla formazione del plafond<br>' +\n          'N4: esenti<br>' +\n          'N5: regime del margine IVA non esposta in fattura<br>' +\n          'N6.1: inversione contabile per la cessione di rottami e altri materiali di recupero<br>' +\n          'N6.2: inversione contabile per la cessione di oro e argento puro<br>' +\n          'N6.3: inversione contabile per la subappalto nel settore edile<br>' +\n          'N6.4: inversione contabile per la cessione di fabbricati<br>' +\n          'N6.5: inversione contabile per la cessione di telefoni cellulari<br>' +\n          'N6.6: inversione contabile per la cessione di prodotti elettronici<br>' +\n          'N6.7: inversione contabile per la prestazioni comparto edile e settori connessi<br>' +\n          'N6.8: inversione contabile per la operazioni settore energetico<br>' +\n          'N6.9: inversione contabile per la altri casi<br>' +\n          'N7: IVA assolta in altro stato UE',\n      }",
                  modifiers: { left: true },
                },
              ],
              staticClass: "h-5 text-gray-700",
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }