<template>
  <div class="grid gap-8 md:grid-cols-12">
    <div class="col-span-12 mt-12 md:col-span-12">
      <div class="grid grid-cols-12">
        <sw-input-group
          :label="$t('reports.registri_iva.date_range')"
          :error="dateRangeError"
          class="col-span-6 md:col-span-3"
        >
          <sw-select
            v-model="selectedRange"
            :options="dateRange"
            :allow-empty="false"
            :show-labels="false"
            class="mt-2"
            @input="onChangeDateRange"
          />
        </sw-input-group>
      
        <sw-input-group
          :label="$t('reports.registri_iva.from_date')"
          :error="fromDateError"
          class="col-span-6 md:col-span-3 ml-4 mr-4"
        >
          <base-date-picker
            v-model="formData.from_date"
            :invalid="$v.formData.from_date.$error"
            :calendar-button="true"
            calendar-button-icon="calendar"
            class="mt-2 mr-4"
            @input="$v.formData.from_date.$touch()"
          />
        </sw-input-group>
      
        <sw-input-group
          :label="$t('reports.registri_iva.to_date')"
          :error="toDateError"
          class="col-span-6 md:col-span-3 ml-4"
        >
          <base-date-picker
            v-model="formData.to_date"
            :invalid="$v.formData.to_date.$error"
            :calendar-button="true"
            calendar-button-icon="calendar"
            class="mt-2 mr-4"
            @input="$v.formData.to_date.$touch()"
          />
        </sw-input-group>

        <sw-button
          variant="primary-outline"
          class="content-center hidden mt-0 w-md md:flex md:mt-8 col-span-4 md:col-span-2 ml-5"
          type="submit"
          @click.prevent="getReports()"
        >
          {{ $t('reports.update_report') }}
        </sw-button>

      </div>
    </div>
    <div class="col-span-12 mt-0 md:mt-12">
      <iframe
        :src="getReportUrl"
        class="hidden w-full h-screen border-gray-100 border-solid rounded md:flex"
      />
      <a
        class="flex items-center justify-center h-10 px-5 py-1 text-sm font-medium leading-none text-center text-white whitespace-nowrap rounded md:hidden bg-primary-500"
        @click="viewReportsPDF"
      >
        <document-text-icon />

        <span>{{ $t('reports.view_pdf') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DocumentTextIcon } from '@vue-hero-icons/solid'
import moment from 'moment'

const { required } = require('vuelidate/lib/validators')

export default {
  components: {
    DocumentTextIcon,
  },

  data() {
    return {
      dateRange: [
        'Oggi',
        'Questa settimana',
        'Questo mese',
        'Questo trimestre',
        'Quest\'anno',
        'Settimana scorsa',
        'Mese scorso',
        'Trimestre scorso',
        'Anno scorso',
        'Personalizzato',
      ],

      selectedRange: 'Quest\'anno',
      range: new Date(),
      formData: {
        from_date: moment().startOf('year').toString(),
        to_date: moment().endOf('year').toString(),
      },
      url: null,
      siteURL: null,
    }
  },

  validations: {
    range: {
      required,
    },
    formData: {
      from_date: {
        required,
      },
      to_date: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters('company', ['getSelectedCompany']),

    getReportUrl() {
      return this.url
    },

    dateRangeError() {
      if (!this.$v.range.$error) {
        return ''
      }

      if (!this.$v.range.required) {
        return this.$t('validation.required')
      }
    },

    fromDateError() {
      if (!this.$v.formData.from_date.$error) {
        return ''
      }

      if (!this.$v.formData.from_date.required) {
        return this.$t('validation.required')
      }
    },

    toDateError() {
      if (!this.$v.formData.to_date.$error) {
        return ''
      }

      if (!this.$v.formData.to_date.required) {
        return this.$t('validation.required')
      }
    },

    dateRangeUrl() {
      return `${this.siteURL}?from_date=${moment(
        this.formData.from_date
      ).format('YYYY-MM-DD')}&to_date=${moment(this.formData.to_date).format(
        'YYYY-MM-DD'
      )}`
    },
  },

  watch: {
    range(newRange) {
      this.formData.from_date = moment(newRange).startOf('year').toString()
      this.formData.to_date = moment(newRange).endOf('year').toString()
    },
  },

  mounted() {
    this.siteURL = `/reports/journal/${this.getSelectedCompany.unique_hash}`
    this.getInitialReport()
  },

  methods: {
    getThisDate(type, time) {
      return moment()[type](time).toString()
    },

    getPreDate(type, time) {
      return moment().subtract(1, time)[type](time).toString()
    },

    onChangeDateRange() {
      switch (this.selectedRange) {
        case 'Oggi':
          this.formData.from_date = moment().toString()
          this.formData.to_date = moment().toString()
          break

        case 'Questa settimana':
          this.formData.from_date = this.getThisDate('startOf', 'isoWeek')
          this.formData.to_date = this.getThisDate('endOf', 'isoWeek')
          break

        case 'Questo mese':
          this.formData.from_date = this.getThisDate('startOf', 'month')
          this.formData.to_date = this.getThisDate('endOf', 'month')
          break

        case 'Questo trimestre':
          this.formData.from_date = this.getThisDate('startOf', 'quarter')
          this.formData.to_date = this.getThisDate('endOf', 'quarter')
          break

        case 'Quest\'anno':
          this.formData.from_date = this.getThisDate('startOf', 'year')
          this.formData.to_date = this.getThisDate('endOf', 'year')
          break

        case 'Settimana scorsa':
          this.formData.from_date = this.getPreDate('startOf', 'isoWeek')
          this.formData.to_date = this.getPreDate('endOf', 'isoWeek')
          break

        case 'Mese scorso':
          this.formData.from_date = this.getPreDate('startOf', 'month')
          this.formData.to_date = this.getPreDate('endOf', 'month')
          break

        case 'Trimestre scorso':
          this.formData.from_date = this.getPreDate('startOf', 'quarter')
          this.formData.to_date = this.getPreDate('endOf', 'quarter')
          break

        case 'Anno scorso':
          this.formData.from_date = this.getPreDate('startOf', 'year')
          this.formData.to_date = this.getPreDate('endOf', 'year')
          break

        default:
          break
      }
    },

    setRangeToCustom() {
      this.selectedRange = 'Personalizzato'
    },

    async getInitialReport() {
      this.url = this.dateRangeUrl
      return true
    },

    async viewReportsPDF() {
      let data = await this.getReports()
      window.open(this.getReportUrl, '_blank')
      return data
    },

    async getReports(isDownload = false) {
      this.$v.range.$touch()
      this.$v.formData.$touch()
      if (this.$v.$invalid) {
        return true
      }
      this.url = this.dateRangeUrl
      return true
    },

    downloadReport() {
      if (!this.getReports()) {
        return false
      }

      window.open(this.getReportUrl + '&download=true')
      setTimeout(() => {
        this.url = this.dateRangeUrl
        return true
      }, 200)
    },
  },
}
</script>
