import { render, staticRenderFns } from "./DotIcon.vue?vue&type=template&id=5d2ded7a&"
import script from "./DotIcon.vue?vue&type=script&lang=js&"
export * from "./DotIcon.vue?vue&type=script&lang=js&"
import style0 from "./DotIcon.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\_Lavoro\\ALCYON\\Homestead\\code\\reporting\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d2ded7a')) {
      api.createRecord('5d2ded7a', component.options)
    } else {
      api.reload('5d2ded7a', component.options)
    }
    module.hot.accept("./DotIcon.vue?vue&type=template&id=5d2ded7a&", function () {
      api.rerender('5d2ded7a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/icon/DotIcon.vue"
export default component.exports