var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c("div", { staticClass: "absolute bottom-0 right-0 z-10" }),
      _vm._v(" "),
      _c("sw-editor", {
        staticClass: "text-area-field",
        attrs: {
          "set-editor": _vm.inputValue,
          disabled: _vm.disabled,
          invalid: _vm.isFieldValid,
          placeholder: _vm.placeholder,
          variant: "header-editor",
          "input-class": "border-none",
        },
        on: {
          input: _vm.handleInput,
          change: _vm.handleChange,
          keyup: _vm.handleKeyupEnter,
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }