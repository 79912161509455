<template>
  <div style="border-radius: 0.25rem !important;background-color: #fff !important;padding: 1.25rem;">
    <form id="registerForm" @submit.prevent="validateBeforeSubmit">
      <input type="hidden" name="_token" :value="csrf">
      <sw-input-group
        :label="$t('login.username')"
        :error="nameError"
        class="mb-4"
        required
      >
        <sw-input
          :invalid="$v.loginData.name.$error"
          v-model="loginData.name"
          focus
          type="text"
          name="name"
          @input="$v.loginData.name.$touch()"
        />
      </sw-input-group>

      <sw-input-group
        :label="$t('login.email')"
        :error="emailError"
        class="mb-4"
        required
      >
        <sw-input
          :invalid="$v.loginData.email.$error"
          v-model="loginData.email"
          focus
          type="email"
          name="email"
          @input="$v.loginData.email.$touch()"
        />
      </sw-input-group>

      <sw-input-group
        :label="$t('login.password')"
        :error="passwordError"
        class="mb-4"
        required
      >
        <sw-input
          v-model="loginData.password"
          :invalid="$v.loginData.password.$error"
          :type="getInputType"
          name="password"
          @input="$v.loginData.password.$touch()"
        >
          <template v-slot:rightIcon>
            <eye-off-icon
              v-if="isShowPassword"
              class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
              @click="isShowPassword = !isShowPassword"
            />
            <eye-icon
              v-else
              class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
              @click="isShowPassword = !isShowPassword"
            />
          </template>
        </sw-input>
      </sw-input-group>
      <sw-input-group
        :label="$t('login.retype_password')"
        :error="passwordError"
        class="mb-4"
        required
      >
        <sw-input
          v-model="loginData.password_confirmation"
          :invalid="$v.loginData.password.$error"
          :type="getInputType"
          name="password_confirmation"
          @input="$v.loginData.password_confirmation.$touch()"
        >
          <template v-slot:rightIcon>
            <eye-off-icon
              v-if="isShowPassword"
              class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
              @click="isShowPassword = !isShowPassword"
            />
            <eye-icon
              v-else
              class="w-5 h-5 mr-1 text-gray-500 cursor-pointer"
              @click="isShowPassword = !isShowPassword"
            />
          </template>
        </sw-input>
      </sw-input-group>
      <!-- {{ csrf_field() }} -->
      <!-- <div class="form-group">
        <input
          :placeholder="$t('login.enter_email')"
          type="email"
          class="form-control form-control-danger"
          name="email"
        />
      </div>
      <div class="form-group">
        <input
          id="password"
          type="password"
          class="form-control form-control-danger"
          placeholder="Enter Password"
          name="password"
        />
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control form-control-danger"
          placeholder="Retype Password"
          name="password_confirmation"
        />
      </div> -->
      <div class="mt-5 mb-8">
        <div class="mb-4">
          <router-link
            to="login"
            class="text-sm text-primary-400 hover:text-gray-700"
          >
            {{ $t('login.or_signIn_with') }}
          </router-link>
        </div>
      </div>

       <sw-button
        :loading="isLoading"
        :disabled="isLoading"
        type="submit"
        variant="primary"
        class="w-full"
      >
        {{ $t('login.register') }}
      </sw-button>
    </form>
  </div>
</template>
<script type="text/babel">
import { mapActions } from 'vuex'
import { EyeIcon, EyeOffIcon } from '@vue-hero-icons/outline'
import IconFacebook from '../../components/icon/facebook'
import IconTwitter from '../../components/icon/twitter'
import IconGoogle from '../../components/icon/google'
const { required, email, minLength } = require('vuelidate/lib/validators')

export default {
  components: {
    IconFacebook,
    IconTwitter,
    IconGoogle,
    EyeIcon,
    EyeOffIcon,
  },
  data() {
    return {
      loginData: {
        email: '',
        password: '',
        password_confirmation: '',
      },
      submitted: false,
      isLoading: false,
      isShowPassword: false,
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    }
  },
  validations: {
    loginData: {
      name:{
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  computed: {
    nameError() {
      if (!this.$v.loginData.name.$error) {
        return ''
      }
      if (!this.$v.loginData.name.required) {
        return this.$tc('validation.required')
      }
    },
    emailError() {
      if (!this.$v.loginData.email.$error) {
        return ''
      }
      if (!this.$v.loginData.email.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.loginData.email.email) {
        return this.$tc('validation.email_incorrect')
      }
    },

    passwordError() {
      if (!this.$v.loginData.password.$error) {
        return ''
      }
      if (!this.$v.loginData.password.required) {
        return this.$tc('validation.required')
      }
      if (!this.$v.loginData.password.minLength) {
        return this.$tc(
          'validation.password_min_length',
          this.$v.loginData.password.$params.minLength.min,
          { count: this.$v.loginData.password.$params.minLength.min }
        )
      }
    },

    getInputType() {
      if (this.isShowPassword) {
        return 'text'
      }
      return 'password'
    },
  },
  methods: {
    ...mapActions('auth', ['register']),
    async validateBeforeSubmit() {      
      console.log('1')
      console.log(this.loginData)
      this.$v.loginData.$touch()
      if (this.$v.$invalid) {
        return true
      }

      this.isLoading = true
      console.log('2')
      console.log(this.loginData)
      try {
        console.log('3')
      console.log(this.loginData)
        await this.register(this.loginData)
        this.$router.push('/admin/dashboard')
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
  },
}
</script>

<!-- <script type="text/babel">
export default {
  data() {
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
    }
  },
  methods: {
    validateBeforeSubmit(e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // eslint-disable-next-line
          alert('Form Submitted!')
        }
      })
    },
  },
}
</script> -->
