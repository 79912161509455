import * as types from './mutation-types'
//METTERE A POSTO TUTTI I GET ('') CON QUELLI DI STRUCTUREd
export const fetchStructures = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/structures`, { params })
      .then((response) => {
        commit(types.SET_STRUCTURES, response.data.structures.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchStructuresList = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/structures?limit=500`)//-modificata
      .then((response) => {
        commit(types.SET_STRUCTURES, response.data.structures)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addStructure = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/v1/structures', data)
      .then((response) => {
        commit(types.ADD_STRUCTURE, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchStructure = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/structures/${id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateStructure = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/v1/structures/${data.id}`, data)
      .then((response) => {
        commit(types.UPDATE_STRUCTURE, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteStructure = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .delete(`/api/v1/structures/${id}`)
      .then((response) => {
        if (response.data.success) {
          commit(types.DELETE_STRUCTURE, id)
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
