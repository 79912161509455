<template>
  <base-page class="deadlines">
    <sw-page-header :title="$t('deadlines.title')">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="dashboard" :title="$t('general.home')" />
        <sw-breadcrumb-item to="#" :title="$tc('deadlines.deadline', 2)" active />
      </sw-breadcrumb>

      <template slot="actions">
        <sw-button v-show="totalDeadlines" variant="primary-outline" size="lg" @click="toggleFilter">
          {{ $t('general.filter') }}
          <component :is="filterIcon" class="w-4 h-4 ml-2 -mr-1" />
        </sw-button>
      </template>
    </sw-page-header>

    <slide-y-up-transition>
      <sw-filter-wrapper v-show="showFilters" class="mt-3">

        <sw-input-group :label="$t('general.from')" color="black-light" class="flex-1 mt-2">
          <base-date-picker v-model="filters.from_date" :calendar-button="true" calendar-button-icon="calendar" />
        </sw-input-group>

        <div class="hidden w-4 h-0 mx-4 border border-gray-400 border-solid xl:block" style="margin-top: 3.5rem" />

        <sw-input-group :label="$t('general.to')" color="black-light" class="flex-1 mt-2">
          <base-date-picker v-model="filters.to_date" :calendar-button="true" calendar-button-icon="calendar" />
        </sw-input-group>

        <sw-input-group :label="$t('payments.customer')" color="black-light" class="flex-1 mt-2 lg:ml-6">
          <base-customer-select ref="customerSelect" @select="onSelectCustomer" @deselect="clearCustomerSearch" />
        </sw-input-group>

        <sw-input-group :label="$t('deadlines.ref_number')" class="flex-1 mt-2 lg:ml-6">
          <sw-input v-model="filters.invoice_number" :placeholder="$t(deadlines.ref_number)" name="invoice_number" />
        </sw-input-group>

        <sw-input-group :label="$t('deadlines.invoice_number')" class="flex-1 mt-2 lg:ml-6">
          <sw-input v-model="filters.ref_number" :placeholder="$t(deadlines.invoice_number)" name="ref_number" />
        </sw-input-group>

        <sw-input-group :label="$t('payments.payment_mode')" class="flex-1 mt-2 lg:ml-6">
          <sw-select v-model="filters.payment_mode" :options="paymentModes" :searchable="true" :show-labels="false"
            :placeholder="$t('payments.payment_mode')" label="name" />
        </sw-input-group>

        <label class="absolute text-sm leading-snug text-gray-900 cursor-pointer" style="top: 10px; right: 15px"
          @click="clearFilter">{{ $t('general.clear_all') }}</label>
      </sw-filter-wrapper>
    </slide-y-up-transition>

    <sw-empty-table-placeholder v-if="showEmptyScreen" :title="$t('deadlines.no_deadlines')"
      :description="$t('deadlines.list_of_deadlines')">
      <capsule-icon class="mt-5 mb-4" />
    </sw-empty-table-placeholder>

    <div v-show="!showEmptyScreen" class="relative table-container">
      <div
        class="relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid">
        <p class="text-sm">
          {{ $t('general.showing') }}: <b>{{ deadlines.length }}</b>
          {{ $t('general.of') }} <b>{{ totalDeadlines }}</b>
        </p>
      </div>

      <sw-table-component ref="table" :data="fetchData" :show-filter="false" table-class="table">
        <sw-table-column :sortable="true" :label="$t('deadlines.date')" sort-as="deadline_date"
          show="formattedDeadlineDate" />

        <sw-table-column :sortable="true" :label="$t('deadlines.payment_mode')" show="method" />

        <sw-table-column :sortable="true" :label="$t('deadlines.ref_number')" show="invoice_number">
          <template slot-scope="row">
            <span>{{ $t('deadlines.ref_number') }}</span>
            <router-link :to="{ path: `invoices/${row.invoice_id}/edit` }" class="font-medium text-primary-500">
              {{ row.invoice_number }}
            </router-link>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('deadlines.invoice_number')" show="reference_number" />

        <sw-table-column :sortable="true" :label="$t('deadlines.description')" show="description" />

        <sw-table-column :sortable="true" :label="$t('deadlines.customer')" show="supplier" />

        <sw-table-column :sortable="true" :label="$t('deadlines.amount')">
          <template slot-scope="row">
            <span>{{ $t('deadlines.amount') }}</span>
            <div v-html="$utils.formatMoney(row.amount, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('deadlines.ritenuta')" show="ritenuta">
          <template slot-scope="row">
            <span>{{ $t('deadlines.ritenuta') }}</span>
            <span>
              {{ row.ritenuta ? 'Sì' : 'No' }}
            </span>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="false" :filterable="false" cell-class="action-dropdown">
          <template slot-scope="row">
            <span>{{ $t('payments.action') }}</span>
            <sw-dropdown>
              <dot-icon slot="activator" />

              <sw-dropdown-item tag-name="router-link" :to="`invoices/${row.invoice_id}/edit`">
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item>

              <sw-dropdown-item @click.prevent="payDeadline(row)">
                <currency-euro-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('deadlines.pay') }}
              </sw-dropdown-item>

            </sw-dropdown>
          </template>
        </sw-table-column>
      </sw-table-component>
    </div>

    <div class="text-right mt-8" v-show="!showEmptyScreen">
      <sw-button size="lg" variant="primary" @click="download()">
        <download-icon class="h-5 mr-1 -ml-2" />
        {{ $t('reports.download_excel') }}
      </sw-button>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CapsuleIcon from '@/components/icon/CapsuleIcon'
import {
  PlusIcon,
  FilterIcon,
  XIcon,
  ChevronDownIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
  CurrencyEuroIcon,
  DownloadIcon
} from '@vue-hero-icons/solid'

export default {
  components: {
    CapsuleIcon,
    PlusIcon,
    FilterIcon,
    XIcon,
    ChevronDownIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
    CurrencyEuroIcon,
    DownloadIcon
  },

  data() {
    return {
      showFilters: false,
      sortedBy: 'deadline_date',
      isRequestOngoing: true,
      totalDeadlines: 0,

      filters: {
        from_date: '',
        to_date: '',
        customer: '',
        payment_mode: '',
        invoice_number: '',
      },
    }
  },

  computed: {
    showEmptyScreen() {
      return !this.totalDeadlines && !this.isRequestOngoing
    },

    filterIcon() {
      return this.showFilters ? 'x-icon' : 'filter-icon'
    },

    ...mapGetters('customer', ['customers']),

    ...mapGetters('deadline', ['deadlines']),

    ...mapGetters('payment', [
      'selectedPayments',
      'totalPayments',
      'payments',
      'selectAllField',
      'paymentModes',
    ]),

  },

  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  mounted() {
    this.fetchPaymentModes({ limit: 'all' })
    this.loadFiltersFromUrl()
  },

  methods: {
    ...mapActions('payment', [
      'fetchPayments',
      'selectAllPayments',
      'selectPayment',
      'deletePayment',
      'deleteMultiplePayments',
      'setSelectAllState',
      'fetchPaymentModes',
      'fetchPaymentMode',
    ]),

    ...mapActions('customer', ['fetchCustomer']),

    ...mapActions('deadline', ['fetchDeadlines']),

    ...mapActions('modal', ['openModal']),

    async fetchData({ page, filter, sort }) {
      let data = {
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        customer_id: this.filters.customer ? this.filters.customer.id : '',
        deadline_method_id:
          this.filters.payment_mode !== null
            ? this.filters.payment_mode.id
            : '',
        invoice_number: this.filters.invoice_number,
        ref_number: this.filters.ref_number,
        orderByField: sort.fieldName || 'deadline_date',
        orderBy: sort.order || 'asc',
        page,
        sold: 0
      }

      this.isRequestOngoing = true
      let response = await this.fetchDeadlines(data)
      this.isRequestOngoing = false

      this.totalDeadlines = response.data.deadlineTotalCount

      return {
        data: response.data.deadlines.data,
        pagination: {
          totalPages: response.data.deadlines.last_page,
          currentPage: page,
          count: response.data.deadlines.count,
        },
      }
    },

    refreshTable() {
      this.$refs.table.refresh()
    },

    setFilters() {
      this.refreshTable()
      this.updateUrlWithFilters()
    },

    clearFilter() {
      if (this.filters.customer) {
        this.$refs.customerSelect.$refs.baseSelect.removeElement(
          this.filters.customer
        )
      }

      this.filters = {
        from_date: '',
        to_date: '',
        customer: '',
        payment_mode: '',
        invoice_number: '',
        ref_number: '',
      }

      // pulisco url
      if (this.$router) {
        this.$router.replace({ query: {} }, () => {
          console.log('Router replace succeeded');
        }, (err) => {
          if (err !== undefined) {
            console.error('Error details:', err);
          }
        });
      } else {
        console.error('Router is not defined');
      }
    },

    updateUrlWithFilters() {
      const query = {};

      for (var prop in this.filters) {
        if (this.filters.hasOwnProperty(prop)) {
          const value = this.filters[prop];
          console.log(prop);
          if ((prop === 'customer' || prop === 'payment_mode') && typeof value === 'object') {
            // per i campi select
            console.log('OBJECT', value);
            if (value !== null && value.id !== null && value.id !== undefined && value.id !== '') {
              query[prop] = value.id;  // Usa `prop` invece di `key`
            }
          } else if (value !== null && value !== '' && typeof value !== 'object') {
            console.log('STANDARD');
            // per i campi di input standard
            query[prop] = value;  // Usa `prop` invece di `key`
          }
        }
      }

      if (this.$router) {
        console.log('Replacing router with query:', query);
        this.$router.replace({ query }, () => {
          console.log('Router replace succeeded');
        }, (err) => {
          if (err !== undefined) {
            console.error('Error details:', err);
          }
        });
      } else {
        console.error('Router is not defined');
      }
    },

    async loadFiltersFromUrl() {
      const query = this.$route.query;
      console.log('Loading filters from URL:', query);
      console.log(this.$refs.customerSelect.selectCustomer);
      for (var key of Object.keys(query)) {
        if (this.filters.hasOwnProperty(key)) {
          switch (key) {
            case 'customer':
              {
                let params = {
                  id: query[key],
                };
                let response = await this.fetchCustomer(params); // chiamo l'action che mi prende il customer singolo
                this.filters[key] = response.data.customer;
                this.$refs.customerSelect.selectCustomer(response.data.customer);
              }
              break;
            case 'payment_mode':
              {
                let params = {
                  id: query[key],
                };

                let response = await this.fetchPaymentMode(params);
                this.filters[key] = response.data.paymentMethod;
              }
              break;
            default:
              this.filters[key] = query[key];
              break;
          }
        }
      }

      console.log('Filters loaded from URL', this.filters);
      this.refreshTable(); // Aggiorna la tabella dopo aver caricato i filtri
    },

    toggleFilter() {
      if (this.showFilters) {
        this.clearFilter()
      }

      this.showFilters = !this.showFilters
    },

    onSelectCustomer(customer) {
      this.filters.customer = customer
    },

    async clearCustomerSearch(removedOption, id) {
      this.filters.customer = ''
      this.refreshTable()
    },

    payDeadline(row) {
      this.openModal({
        title: this.$t('payments.new_payment'),
        componentName: 'PaymentModal',
        data: { ...row, currency: row.user.currency, document_type: row.document_type },
      })
    },

    download() {
      let params = {
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        customer_id: this.filters.customer ? this.filters.customer.id : '',
        deadline_method_id:
          this.filters.payment_mode !== null
            ? this.filters.payment_mode.id
            : '',
        invoice_number: this.filters.invoice_number,
        ref_number: this.filters.ref_number,
        orderByField: 'deadline_date',
        orderBy: 'asc',
        sold: 0
      }

      axios.get(`/deadlines/report`, { params })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));

          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'scadenze.csv');
          document.body.appendChild(fileLink);
          fileLink.click();

          window.toastr['success'](this.$t('invoices.exported_list'))
        })
        .catch((err) => {
          reject(err)
        })
    },

  },
}
</script>
