<!-- QUI SIAMO NELLA VIEW DI MODIFICA SPESA -->
<template>
  <base-page class="relative invoice-create-page">
    <form v-if="!isLoadingInvoice && !isLoadingData" @submit.prevent="submitForm">
      <sw-page-header :title="pageTitle">
        <sw-breadcrumb slot="breadcrumbs">
          <sw-breadcrumb-item :title="$t('general.home')" to="/admin/dashboard" />
          <li v-if="shouldShowBreadcrumb" class="pr-2 text-sm"><span @click="goToPreviousPage"
              class="router-link-exact-active active pr-2 m-0 leading-5 text-gray-900 text-sm cursor-pointer"> {{ $tc('expenses.expense', 2) }} </span><span
              class="px-1">/</span></li>
          <sw-breadcrumb-item v-else :title="$tc('expenses.expense', 2)" to="/admin/invoices" />
          <sw-breadcrumb-item v-if="$route.name === 'invoices.edit'" :title="$t('expenses.edit_expense')" to="#"
            active />
          <sw-breadcrumb-item v-else :title="$t('expenses.new_expense')" to="#" active />
        </sw-breadcrumb>

        <template slot="actions">
          <!--
          <sw-button
            v-if="$route.name === 'invoices.edit'"
            :disabled="isLoading"
            :href="`/invoices/pdf/${newInvoice.unique_hash}`"
            tag-name="a"
            variant="primary-outline"
            class="mr-3"
            target="_blank"
          >
            {{ $t('general.view_pdf') }}
          </sw-button>
                -->
          <sw-button :loading="isLoading" :disabled="isLoading" variant="primary"
            class="flex justify-center w-full lg:w-auto" type="submit" size="lg">
            <save-icon v-if="!isLoading" class="mr-2 -ml-1" />
            {{ $t('expenses.save_expense') }}
          </sw-button>
        </template>
      </sw-page-header>

      <!-- Select Customer & Basic Fields  -->
      <div class="grid-cols-12 gap-8 mt-6 mb-8 lg:grid">
        <customer-select :valid="$v.selectedCustomer" :customer-id="customerId" class="col-span-4 pr-0" />

        <div class="grid grid-cols-2 col-span-7 gap-4 mt-8 lg:gap-6 lg:mt-0 lg:grid-cols-4">
          <sw-input-group :label="$t('invoices.registration_date')" :error="registrationDateError" required>
            <base-date-picker v-model="newInvoice.registration_date" :invalid="$v.newInvoice.registration_date.$error"
              :calendar-button="true" calendar-button-icon="calendar" class="mt-2"
              @input="$v.newInvoice.registration_date.$touch()" />
          </sw-input-group>

          <sw-input-group :label="$t('invoices.invoice_date')" :error="invoiceDateError" required>
            <base-date-picker v-model="newInvoice.invoice_date" :calendar-button="true" calendar-button-icon="calendar"
              class="mt-2" @input="$v.newInvoice.invoice_date.$touch()" />
          </sw-input-group>

          <sw-input-group :label="$t('invoices.ref_number')" :error="invoiceNumError" class="lg:mt-0" required>
            <!--
            <sw-input
              :prefix="`${invoicePrefix} - `"
              v-model="invoiceNumAttribute"
              :invalid="$v.invoiceNumAttribute.$error"
              class="mt-2"
              @input="$v.invoiceNumAttribute.$touch()"
            >
              <hashtag-icon slot="leftIcon" class="h-4 ml-1 text-gray-500" />
                  </sw-input>-->
            <sw-input v-model="invoiceNumAttribute" :invalid="$v.invoiceNumAttribute.$error" class="mt-2"
              @input="$v.invoiceNumAttribute.$touch()">
              <hashtag-icon slot="leftIcon" class="h-4 ml-1 text-gray-500" />
            </sw-input>
          </sw-input-group>

          <sw-input-group :label="$t('invoices.invoice_number')" :error="referenceError" class="lg:mt-0">
            <sw-input v-model="newInvoice.reference_number" :invalid="$v.newInvoice.reference_number.$error"
              class="mt-2" @input="$v.newInvoice.reference_number.$touch()">
              <hashtag-icon slot="leftIcon" class="h-4 ml-1 text-gray-500" />
            </sw-input>
          </sw-input-group>

          <sw-input-group :label="$t('invoices.document_type')" :error="documentTypeError" class="lg:mt-0 col-span-1"
            required>
            <sw-select ref="baseSelect" v-model="selectedDocumentType" :options="documentTypes" :searchable="true"
              :show-labels="false" :placeholder="$t('invoices.select_a_document_type')" label="description"
              track-by="code" class="mt-2" :invalid="$v.newInvoice.document_type.$error"
              @select="(val) => onSelectDocumentType(val)">
            </sw-select>
          </sw-input-group>
          <!-- AGGIUNTO 24/06/22 
                :invalid="$v.newInvoice.structure.$error" -> TOLTO PERCHE DAVA ERRORE-->
          <sw-input-group v-if="structs_enabled" :label="$t('settings.structure.invoice_code')" :error="structureError"
            class="lg:mt-0 col-span-1" required>
            <sw-select ref="baseSelect" v-model="selectedStructure" :options="structs" :searchable="true"
              :show-labels="false" :placeholder="$t('settings.structure.select_a_structure')" label="formattedStructure"
              track-by="id" class="mt-2" @select="(val) => onSelectStructure(val)">
            </sw-select>
          </sw-input-group>

          <sw-input-group :label="$t('invoices.description')" :error="descriptionError" class="lg:mt-0 col-span-2">
            <sw-input v-model="newInvoice.description" :invalid="$v.newInvoice.description.$error" class="mt-2"
              @input="$v.newInvoice.description.$touch()">
            </sw-input>
          </sw-input-group>

          <sw-input-group :label="$t('invoices.reverse_charge')" class="lg:mt-0 col-span-1">
            <sw-switch v-model="newInvoice.reverse_charge" style="margin-top: -10px" class="mt-0">
            </sw-switch>
          </sw-input-group>
        </div>
      </div>

      <!-- Items -->
      <table class="w-full text-center item-table">
        <colgroup>
          <col style="width: 16%" />
          <col style="width: 14%" />
          <col style="width: 15%" />
          <col style="width: 9%" />
          <!--<col v-if="discountPerItem === 'YES'" style="width: 15%" />-->
          <col style="width: 16%" />
          <col style="width: 9%" />
          <col style="width: 7%" />
          <col style="width: 7%" />
          <col style="width: 7%" />
        </colgroup>
        <thead class="bg-white border border-gray-200 border-solid">
          <tr>
            <th
              class="px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              <span class="pl-12">
                {{ $tc('invoices.item.category', 2) }}
              </span>
            </th>
            <th
              class="px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              {{ $tc('invoices.item.subcategory', 2) }}
            </th>
            <th
              class="px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              {{ $tc('invoices.item.description') }}
            </th>
            <th
              class="px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              {{ $t('invoices.item.price') }}
            </th>
            <th
              class="px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              {{ $t('invoices.item.vat') }}
            </th>
            <th
              class="px-4 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              {{ $t('invoices.item.amount') }}
            </th>
            <th
              class="px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              {{ $t('invoices.item.enpav') }}
            </th>
            <th
              class="px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              {{ $t('invoices.item.vatfc') }}
            </th>
            <th
              class="px-2 py-3 text-sm not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
              {{ $t('invoices.item.vatind') }}
            </th>
          </tr>
        </thead>

        <draggable v-model="newInvoice.items" class="item-body" tag="tbody" handle=".handle">
          <invoice-item v-for="(item, index) in newInvoice.items" :key="item.id" :index="index" :item-data="item"
            :invoice-items="newInvoice.items" :currency="currency" :tax-per-item="taxPerItem"
            :discount-per-item="discountPerItem" @remove="removeItem" @update="updateItem"
            @itemValidate="checkItemsData" />
        </draggable>
      </table>

      <div
        class="flex items-center justify-center w-full px-6 py-3 text-base border-b border-gray-200 border-solid cursor-pointer text-primary-400 hover:bg-gray-200"
        @click="addItem">
        <shopping-cart-icon class="h-5 mr-2" />
        {{ $t('invoices.add_item') }}
      </div>

      <!-- Notes, Custom Fields & Total Section -->
      <div class="block my-10 invoice-foot lg:justify-between lg:flex lg:items-start">
        <!-- COMMENTATO PER SPOSTARE LE DEADLINE (SCADENZE) A SINISTRA -->
        <!-- <div class="w-full lg:w-1/4">

          <div v-if="customFields.length > 0"
            class="grid gap-x-4 gap-y-2 md:gap-x-8 md:gap-y-4 grid-col-1 md:grid-cols-2">
            <sw-input-group v-for="(field, index) in customFields" :label="field.label"
              :required="field.is_required ? true : false" :key="index">
              <component :type="field.type.label" :field="field" :is-edit="isEdit" :is="field.type + 'Field'"
                :invalid-fields="invalidFields" @update="setCustomFieldValue" />
            </sw-input-group>
          </div>

          
          <sw-input-group
            :label="$t('invoices.invoice_template')"
            class="mt-6 mb-1"
            required
          >
            <sw-button
              type="button"
              class="flex justify-center w-full text-sm text-black lg:w-auto hover:bg-gray-400"
              variant="gray"
              @click="openTemplateModal"
            >
              <span class="flex text-black">
                {{ $t('invoices.template') }} {{ getTemplateId }}
                <pencil-icon class="h-5 ml-2 -mr-1" />
              </span>
            </sw-button>
          </sw-input-group>


              </div> -->

        <div class="w-full lg:w-2/5">
          <div class="mb-2 flex items-center w-full justify-between">
            <div>
              {{ $t('invoices.deadlines') }}
            </div>

            <div class="flex text-xs cursor-pointer text-primary-400 hover:bg-gray-200 ml-5 font-semibold"
              @click="addDeadline">
              <plus-sm-icon />
              {{ $t('invoices.add_deadline') }}
            </div>
          </div>

          <table class="w-full text-center item-table">
            <colgroup>
              <col style="width: 30%" />
              <col style="width: 20%" />
              <col style="width: 35%" />
              <col style="width: 5%" />
              <col style="width: 10%" />
            </colgroup>
            <thead class="bg-white border border-gray-200 border-solid">
              <tr>
                <th
                  class="px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
                  <span class="pl-12">
                    {{ $t('invoices.deadline.date') }}
                  </span>
                </th>
                <th
                  class="px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
                  {{ $t('invoices.deadline.amount') }}
                </th>
                <th
                  class="px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
                  {{ $t('invoices.deadline.method') }}
                </th>
                <th
                  class="px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
                </th>
                <th
                  class="px-2 py-3 text-xs not-italic font-medium leading-5 text-left text-gray-700 border-t border-b border-gray-200 border-solid">
                </th>
              </tr>
            </thead>

            <draggable v-model="newInvoice.deadlines" class="deadline-body" tag="tbody" handle=".handle">
              <deadline v-for="(deadline, index) in newInvoice.deadlines" :key="deadline.id" :index="index"
                :deadline-data="deadline" :invoice-deadlines="newInvoice.deadlines" :currency="currency"
                :invoice-total="total" :is-edit="isEdit" :documentType="newInvoice.document_type"
                :payment_methods="payment_methods"
                :ritenuta_val="deadline.ritenuta === 1 ? newInvoice.ritenuta_val : null" @update="updateDeadline"
                @remove="removeDeadline" @deadlineValidate="checkDeadlinesData" />
            </draggable>
          </table>
        </div>

        <div class="px-5 py-4 mt-6 bg-white border border-gray-200 border-solid rounded invoice-total lg:mt-0">
          <div class="flex items-center justify-between w-full">
            <label class="text-sm font-semibold leading-5 text-gray-500 uppercase">{{ $t('invoices.net_total')
              }}</label>
            <label class="flex items-center justify-center m-0 text-lg text-black uppercase">
              <div v-html="$utils.formatMoney(subtotal, currency)" />
            </label>
          </div>
          <div v-for="tax in allTaxes" :key="tax.tax_type_id" class="flex items-center justify-between w-full">
            <label class="m-0 text-sm font-semibold leading-5 text-gray-500 uppercase">{{ tax.name }} - {{ tax.percent
              }}%
            </label>
            <label class="flex items-center justify-center m-0 text-lg text-black uppercase" style="font-size: 18px">
              <div v-html="$utils.formatMoney(tax.amount, currency)" />
            </label>
          </div>
          <div v-if="enpav != 0" class="flex items-center justify-between w-full">
            <label class="m-0 text-sm font-semibold leading-5 text-gray-500 uppercase">{{ $t('invoices.enpav') }}
            </label>
            <label class="flex items-center justify-center m-0 text-lg text-black uppercase" style="font-size: 18px">
              <div v-html="$utils.formatMoney(enpav, currency)" />
            </label>
          </div>
          <div class="flex items-center justify-between w-full">
            <label class="text-sm font-semibold leading-5 text-gray-500 uppercase">
              {{ $t('invoices.total') }} {{ $t('invoices.document') }}:
            </label>
            <label class="flex items-center justify-center m-0 text-lg uppercase text-black">
              <div v-html="$utils.formatMoney(totalDocument, currency)" />
            </label>
          </div>
          <!--
          <div
            v-if="discountPerItem === 'NO' || discountPerItem === null"
            class="flex items-center justify-between w-full mt-2"
          >
            <label
              class="text-sm font-semibold leading-5 text-gray-500 uppercase"
              >{{ $t('invoices.discount') }}</label
            >
            <div class="flex" style="width: 105px" role="group">
              <sw-input
                v-model="discount"
                :invalid="$v.newInvoice.discount_val.$error"
                class="border-r-0 rounded-tr-sm rounded-br-sm"
                @input="$v.newInvoice.discount_val.$touch()"
              />
              <sw-dropdown position="bottom-end">
                <sw-button
                  slot="activator"
                  type="button"
                  data-toggle="dropdown"
                  size="discount"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="height: 43px"
                  variant="white"
                >
                  <span class="flex">
                    {{
                      newInvoice.discount_type == 'fixed'
                        ? currency.symbol
                        : '%'
                    }}
                    <chevron-down-icon class="h-5" />
                  </span>
                </sw-button>

                <sw-dropdown-item @click="selectFixed">
                  {{ $t('general.fixed') }}full
                </sw-dropdown-item>

                <sw-dropdown-item @click="selectPercentage">
                  {{ $t('general.percentage') }}
                </sw-dropdown-item>
              </sw-dropdown>
            </div>
          </div>
                -->

          <div class="flex items-center justify-between w-full mt-2">
            <label class="text-sm font-semibold leading-5 text-gray-500 uppercase">{{ $t('invoices.ritenuta') }}</label>
            <div class="flex" style="width: 105px">
              <sw-money v-model="ritenuta" :currency="ritenutaCurrency" :invalid="$v.newInvoice.ritenuta_val.$error"
                @input="$v.newInvoice.ritenuta_val.$touch()" />
            </div>
          </div>

          <div v-if="$v.newInvoice.ritenuta_val.$error" class="flex items-center justify-between w-full mt-2">
            <label class="text-sm font-semibold leading-5 text-gray-500 uppercase"></label>
            <div v-if="$v.newInvoice.ritenuta_val.$error">
              <span v-if="!$v.newInvoice.ritenuta_val.between" class="text-danger">
                {{ $t('validation.ritenuta_between') }}
              </span>
            </div>
          </div>

          <div v-if="taxPerItem ? 'NO' : null">
            <tax v-for="(tax, index) in newInvoice.taxes" :index="index" :total="subtotalWithDiscount" :key="tax.id"
              :tax="tax" :taxes="newInvoice.taxes" :currency="currency" :total-tax="totalSimpleTax"
              @remove="removeInvoiceTax" @update="updateTax" />
          </div>

          <sw-popup v-if="taxPerItem === 'NO' || taxPerItem === null" ref="taxModal"
            class="my-3 text-sm font-semibold leading-5 text-primary-400">
            <div slot="activator" class="float-right pt-2 pb-5">
              + {{ $t('invoices.add_tax') }}
            </div>
            <tax-select-popup :taxes="newInvoice.taxes" @select="onSelectTax" />
          </sw-popup>


          <div class="flex items-center justify-between w-full pt-2 mt-5 border-t border-gray-200 border-solid">
            <label class="text-sm font-semibold leading-5 text-gray-500 uppercase">
              {{ $t('invoices.total') }} {{ $t('invoices.amount') }}:
            </label>
            <label class="flex items-center justify-center text-lg uppercase text-primary-400">
              <div v-html="$utils.formatMoney(total, currency)" />
            </label>
          </div>

          <div v-if="isEdit" class="flex items-center justify-between w-full pt-2 mt-2">
            <label class="text-sm font-semibold leading-5 text-gray-500 uppercase">
              {{ $t('estimates.amount_due') }}:
            </label>
            <label class="flex items-center justify-center text-lg uppercase text-primary-400">
              <div v-html="$utils.formatMoney(dueAmount, currency)" />
            </label>
          </div>
        </div>
      </div>
    </form>
    <base-loader v-else />
  </base-page>
</template>

<script>
import draggable from 'vuedraggable'
import InvoiceItem from './Item'
import CustomerSelect from './CustomerSelect'
import InvoiceStub from '../../stub/invoice'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Guid from 'guid'
import TaxStub from '../../stub/tax'
import Tax from './InvoiceTax'
import { PlusSmIcon } from '@vue-hero-icons/outline'
import {
  ChevronDownIcon,
  PencilIcon,
  ShoppingCartIcon,
  HashtagIcon,
} from '@vue-hero-icons/solid'
import CustomFieldsMixin from '../../mixins/customFields'
import invoice from '../../stub/invoice'
import Deadline from './Deadline.vue'
import DeadlineStub from '../../stub/deadline'

const {
  required,
  between,
  maxLength,
  numeric,
} = require('vuelidate/lib/validators')

export default {
  components: {
    InvoiceItem,
    CustomerSelect,
    Tax,
    draggable,
    PlusSmIcon,
    ChevronDownIcon,
    PencilIcon,
    ShoppingCartIcon,
    HashtagIcon,
    Deadline,
  },
  mixins: [CustomFieldsMixin],

  data() {
    return {
      newInvoice: {
        invoice_date: null,
        due_date: null,
        registration_date: null,
        invoice_number: null,
        document_type: null,
        user_id: null,
        invoice_template_id: 1,
        sub_total: null,
        total: null,
        tax: null,
        paid_status: null,
        discount_type: 'fixed',
        discount_val: 0,
        discount: 0,
        ritenuta_val: 0,
        ritenuta: 0,
        due_amount: 0,
        reference_number: null,
        items: [
          {
            ...InvoiceStub,
            id: Guid.raw(),
            taxes: [{ ...TaxStub, id: Guid.raw() }],
          },
        ],
        deadlines: [
          {
            ...DeadlineStub,
            id: Guid.raw(),
            deadline_date: moment().toString(),
          },
        ],
        taxes: [],
        reverse_charge: false,
        structure_id: null,
        structs: [],
        structs_enabled: null,
      },
      selectedCurrency: '',
      taxPerItem: null,
      discountPerItem: null,
      isLoadingInvoice: false,
      isLoadingData: false,
      isLoading: false,
      maxDiscount: 0,
      //invoicePrefix: null,
      invoiceNumAttribute: null,
      InvoiceFields: [
        'customer',
        'customerCustom',
        'company',
        'invoice',
        'invoiceCustom',
      ],
      selectedDocumentType: null,
      selectedStructure: null,
      documentTypes: [
        {
          id: Guid.raw(),
          code: 'TD01',
          description: 'TD01 - Fattura',
        },
        {
          id: Guid.raw(),
          code: 'TD02',
          description: 'TD02 - Acconto/anticipo su fattura',
        },
        {
          id: Guid.raw(),
          code: 'TD03',
          description: 'TD03 - Acconto/anticipo su parcella',
        },
        {
          id: Guid.raw(),
          code: 'TD04',
          description: 'TD04 - Nota di credito',
        },
        {
          id: Guid.raw(),
          code: 'TD05',
          description: 'TD05 - Nota di debito',
        },
        {
          id: Guid.raw(),
          code: 'TD06',
          description: 'TD06 - Parcella',
        },
        {
          id: Guid.raw(),
          code: 'TD16',
          description: 'TD16 - Integrazione fattura reverse charge interno',
        },
        {
          id: Guid.raw(),
          code: 'TD17',
          description: 'TD17 - Integrazione/autofattura per acquisto servizi dall\'estero',
        },
        {
          id: Guid.raw(),
          code: 'TD18',
          description: 'TD18 - Integrazione/autofattura per acquisto di beni intracomunitari',
        },
        {
          id: Guid.raw(),
          code: 'TD19',
          description: 'TD19 - Integrazione/autofattura per acquisto di beni intracomunitari',
        },
        {
          id: Guid.raw(),
          code: 'TD20',
          description: 'TD20 - Autofattura per regolazzazione e intregrazione delle fatture',
        },
        {
          id: Guid.raw(),
          code: 'TD21',
          description: 'TD21 - Autofattura per splafonamento',
        },
        {
          id: Guid.raw(),
          code: 'TD22',
          description: 'TD22 - Estrazione beni da Deposito IVA',
        },
        {
          id: Guid.raw(),
          code: 'TD23',
          description: 'TD23 - Estrazione beni da Deposito IVA con versamento dell\'IVA',
        },
        {
          id: Guid.raw(),
          code: 'TD24',
          description: 'TD24 - Fattura differita di cui all\'art. 21, comma 4, lett. A',
        },
        {
          id: Guid.raw(),
          code: 'TD25',
          description: 'TD25 - Fattura differita di cui all\'art. 21, comma 4, terzo periodo lett. B',
        },
        {
          id: Guid.raw(),
          code: 'TD26',
          description: 'TD26 - Cessione di beni ammortizzabili o Autofattura per passaggi interni (ex art.36 DPR 633/72)',
        },
        {
          id: Guid.raw(),
          code: 'TD27',
          description: 'TD27 - Fattura per autoconsumo o per cessione gratuite senza rivalsa',
        },
        {
          id: Guid.raw(),
          code: 'TD28',
          description: 'TD28 - Acquisti da San Marino con IVA (fattura cartacea)',
        },
      ],
      customerId: null,
      payment_methods: [],
    }
  },

  validations() {
    return {
      newInvoice: {
        invoice_date: {
          required,
        },
        registration_date: {
          required,
        },
        discount_val: {
          between: between(0, this.subtotal),
        },
        ritenuta_val: {
          between: between(0, this.subtotal + this.totalTax),
        },
        reference_number: {
          maxLength: maxLength(255),
        },
        description: {
          maxLength: maxLength(255),
        },
        document_type: {
          required,
        },
      },
      selectedCustomer: {
        required,
      },
      invoiceNumAttribute: {
        required,
        // numeric,
      },
    }
  },

  computed: {
    ...mapGetters('company', ['itemDiscount']),

    ...mapGetters('company', ['defaultCurrency']),

    ...mapGetters('structure', ['structures']), //AGGIUNTO 24/06/22

    ...mapGetters('deadline', ['deadlines']),

    ...mapGetters('invoice', [
      'getTemplateId',
      'selectedCustomer',
      'selectedNote',
    ]),

    ...mapGetters('invoiceTemplate', ['getInvoiceTemplates']),

    currency() {
      return this.selectedCurrency
    },

    shouldShowBreadcrumb() {
      return this.controllPreviusUrl();
    },

    ritenutaCurrency() {
      if (this.currency) {
        return {
          decimal: this.currency.decimal_separator,
          thousands: this.currency.thousand_separator,
          prefix: this.currency.symbol + ' ',
          precision: this.currency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },

    pageTitle() {
      if (this.isEdit) {
        return this.$t('expenses.edit_expense')
      }
      return this.$t('expenses.new_expense')
    },

    isEdit() {
      if (this.$route.name === 'invoices.edit') {
        return true
      }
      return false
    },

    subtotalWithDiscount() {
      return this.subtotal - this.newInvoice.discount_val
    },

    total() {
      let temptotal =
        this.subtotalWithDiscount + this.totalTax + this.enpav - this.ritenuta * 100
      window.hub.$emit('totalChanged', temptotal)
      this.newInvoice.due_amount = temptotal

      return temptotal
    },

    totalDocument() {
      let temptotal = this.subtotalWithDiscount + this.totalTax + this.enpav
      window.hub.$emit('totalDocumentChanged', temptotal)

      return temptotal
    },

    enpav() {
      return this.newInvoice.items.reduce(function (a, b) {
        return a + b['enpav'];
      }, 0)
    },

    dueAmount() {
      let temp = 0
      // if (this.newInvoice.total == 0) {
      //   return (this.newInvoice.due_amount = this.newInvoice.total)
      // }

      if (this.newInvoice.invoice_type == 'Normal') return this.newInvoice.due_amount
      if (this.newInvoice.invoice_type == 'Passive') return this.newInvoice.due_amount
      else {
        if (this.newInvoice.total == null) {
          temp = this.total
        } else if (this.newInvoice.total > this.total) {
          temp = this.total - this.newInvoice.total
          if (temp < 0) {
            temp = temp * -1;
          }
        }
      }
      // alert(this.total + ' - ' + this.newInvoice.total + ' --> temp = ' + temp)

      return this.newInvoice.due_amount + temp
    },
    // dueAmount() {
    //   let temp = 0
    //   // if (this.newInvoice.total == 0) {
    //   //   return (this.newInvoice.due_amount = this.newInvoice.total)
    //   // }

    //   if(this.newInvoice.invoice_type == 'Passive') return this.newInvoice.due_amount
    //   else{
    //     if(this.newInvoice.total == null){
    //     temp = this.total
    //   } else if (this.newInvoice.total > this.total) {
    //     temp = this.total - this.newInvoice.total
    //     if(temp < 0){
    //       temp = temp * -1;
    //       return temp
    //     }
    //   }
    //   }


    //   // alert(this.total + ' - ' + this.newInvoice.total + ' --> temp = ' + temp)

    //   return this.newInvoice.due_amount + temp
    // },

    subtotal() {
      return this.newInvoice.items.reduce(function (a, b) {
        console.log(b, b['tax']);
        return a + (b['total'] - b['enpav'] - b['tax'])
      }, 0)
    },

    discount: {
      get: function () {
        return this.newInvoice.discount
      },
      set: function (newValue) {
        if (this.newInvoice.discount_type === 'percentage') {
          this.newInvoice.discount_val = (this.subtotal * newValue) / 100
        } else {
          this.newInvoice.discount_val = Math.round(newValue * 100)
        }

        this.newInvoice.discount = newValue
      },
    },

    ritenuta: {
      get: function () {
        return this.newInvoice.ritenuta
      },
      set: function (newValue) {
        this.newInvoice.ritenuta_val = Math.round(newValue * 100)
        this.newInvoice.ritenuta = newValue

        this.newRitenutaDeadline()
      },
    },

    totalSimpleTax() {
      return Math.round(
        window._.sumBy(this.newInvoice.taxes, function (tax) {
          if (!tax.compound_tax) {
            return tax.amount
          }

          return 0
        })
      )
    },

    totalCompoundTax() {
      return Math.round(
        window._.sumBy(this.newInvoice.taxes, function (tax) {
          if (tax.compound_tax) {
            return tax.amount
          }

          return 0
        })
      )
    },

    totalTax() {
      if (this.taxPerItem === 'NO' || this.taxPerItem === null) {
        return this.totalSimpleTax + this.totalCompoundTax
      }

      return Math.round(
        window._.sumBy(this.newInvoice.items, function (tax) {
          return tax.tax
        })
      )
    },

    allTaxes() {
      let taxes = []
      let total = 0

      this.newInvoice.items.forEach((item) => {
        item.taxes.forEach((tax) => {
          let found = taxes.find((_tax) => {
            return _tax.tax_type_id === tax.tax_type_id
          })

          if (found) {
            found.amount += tax.amount
            total += tax.amount
          } else if (tax.tax_type_id) {
            total += tax.amount
            taxes.push({
              tax_type_id: tax.tax_type_id,
              amount: tax.amount,
              percent: tax.percent,
              name: tax.name,
            })
          }
        })
      })

      return taxes
    },

    invoiceDateError() {
      if (!this.$v.newInvoice.invoice_date.$error) {
        return ''
      }
      if (!this.$v.newInvoice.invoice_date.required) {
        return this.$t('validation.required')
      }
    },

    registrationDateError() {
      if (!this.$v.newInvoice.registration_date.$error) {
        return ''
      }
      if (!this.$v.newInvoice.registration_date.required) {
        return this.$t('validation.required')
      }
    },

    invoiceNumError() {
      if (!this.$v.invoiceNumAttribute.$error) {
        return ''
      }

      if (!this.$v.invoiceNumAttribute.required) {
        return this.$tc('validation.required')
      }

      if (!this.$v.invoiceNumAttribute.numeric) {
        return this.$tc('validation.numbers_only')
      }
    },

    referenceError() {
      if (!this.$v.newInvoice.reference_number.$error) {
        return ''
      }

      if (!this.$v.newInvoice.reference_number.maxLength) {
        return this.$tc('validation.ref_number_maxlength')
      }
    },

    descriptionError() {
      if (!this.$v.newInvoice.description.$error) {
        return ''
      }

      if (!this.$v.newInvoice.description.maxLength) {
        return this.$tc('validation.ref_number_maxlength')
      }
    },

    documentTypeError() {
      if (!this.$v.newInvoice.document_type.$error) {
        return ''
      }

      if (!this.$v.newInvoice.document_type.maxLength) {
        return this.$tc('validation.document_type_required')
      }
    },
    //AGGIUNTO 24/06/22
    //DA GESTIRE CORRETTAMENTE L'ERRORE SULLE STRUTTURE, PER ORA VA IN ERRORE
    structureError() {
      return ''
      // if (!this.$v.newInvoice.structure_id.$error) {
      //   console.log(!this.$v.newInvoice.structure_id.$error)
      //    return 'prova'
      //  }
    },
  },

  watch: {
    selectedCustomer(newVal) {
      if (newVal && newVal.currency) {
        this.selectedCurrency = newVal.currency
      } else {
        this.selectedCurrency = this.defaultCurrency
      }
    },

    subtotal(newValue) {
      if (this.newInvoice.discount_type === 'percentage') {
        this.newInvoice.discount_val =
          (this.newInvoice.discount * newValue) / 100
      }
    },

    ritenuta_val(newValue) {
      this.newInvoice.ritenuta_val = Math.round(newValue * 100)
      this.newInvoice.ritenuta = newValue

      this.$refs.Deadline.updateRitenuta(newValue)
    },
  },
  mounted() {
  },

  created() {
    this.setInitialData()
    this.loadData()
    this.fetchInitialData()


    window.hub.$on('newTax', this.onSelectTax)
    if (this.$route.query.customer) {
      this.customerId = parseInt(this.$route.query.customer)
    }
  },

  methods: {
    ...mapActions('modal', ['openModal']),

    ...mapActions('invoice', [
      'addInvoice',
      'fetchInvoice',
      'getInvoiceNumber',
      'selectCustomer',
      'updateInvoice',
      'resetSelectedNote',
    ]),

    ...mapActions('invoiceTemplate', ['fetchInvoiceTemplates']),

    ...mapActions('user', ['fetchCurrentUser']), // AGGIUNTO 25/06/22

    ...mapActions('company', ['fetchCompanySettings']),

    ...mapActions('structure', ['fetchStructures']), //AGGIUNTO 24/06/22

    ...mapActions('item', ['fetchItems']),

    ...mapActions('taxType', ['fetchTaxTypes']),

    ...mapActions('category', ['fetchCategories']),

    ...mapActions('category', ['fetchSubcategory']),

    ...mapActions('paymentMethod', ['fetchPaymentModes']),

    ...mapActions('deadline', ['fetchDeadlines']),

    ...mapActions('customFields', ['fetchCustomFields']),

    selectFixed() {
      if (this.newInvoice.discount_type === 'fixed') {
        return
      }

      this.newInvoice.discount_val = Math.round(this.newInvoice.discount * 100)
      this.newInvoice.discount_type = 'fixed'
    },

    selectPercentage() {
      if (this.newInvoice.discount_type === 'percentage') {
        return
      }

      this.newInvoice.discount_val =
        (this.subtotal * this.newInvoice.discount) / 100

      this.newInvoice.discount_type = 'percentage'
    },

    updateTax(data) {
      Object.assign(this.newInvoice.taxes[data.index], { ...data.item })
    },

    async setInitialData() {
      let response = await this.fetchCurrentUser()
      if (response.data.user) {
        this.structs_enabled = response.data.user.company.structs_enabled
      }
    },

    async fetchInitialData() {
      this.isLoadingData = true

      if (!this.isEdit) {
        let response = await this.fetchCompanySettings([
          'discount_per_item',
          'tax_per_item',
        ])

        if (response.data) {
          this.discountPerItem = response.data.discount_per_item
          this.taxPerItem = response.data.tax_per_item
        }
      }
      // let structures = await this.fetchStructures({ limit: 'all' }) //AGGIUNTO 24/06/22
      // this.structs = structures.data.structures.data

      Promise.all([
        this.fetchItems({
          filter: {},
          orderByField: '',
          orderBy: '',
        }),
        this.fetchInvoiceTemplates(),
        this.resetSelectedNote(),
        this.getInvoiceNumber(),
        this.fetchCompanySettings(['invoice_auto_generate']),
      ])
        .then(async ([res1, res2, res3, res4, res5]) => {
          if (
            !this.isEdit &&
            res5.data &&
            res5.data.invoice_auto_generate === 'YES'
          ) {
            if (res4.data) {
              this.invoiceNumAttribute = res4.data.nextNumber
              //this.invoicePrefix = res4.data.prefix
            }
          } else {
            //this.invoicePrefix = res4.data.prefix
          }

          // this.discountPerItem = res5.data.discount_per_item
          // this.taxPerItem = res5.data.tax_per_item
          this.isLoadingData = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async loadData() {
      let structures = await this.fetchStructures({ limit: 'all' }) //AGGIUNTO 24/06/22
      this.structs = structures.data.structures.data // PASSO I DATI DENTRO STRUCTS DELLA TABELLA STRUCTURES
      if (this.isEdit) {
        this.isLoadingInvoice = true
        Promise.all([
          this.fetchInvoice(this.$route.params.id),
          this.fetchCustomFields({
            type: 'Invoice',
            limit: 'all',
          }),
          this.fetchPaymentModes({ limit: 'all' }),
          this.fetchTaxTypes({ limit: 'all' }),
        ])
          .then(async ([res1, res2, res3]) => {
            if (res1.data) {
              this.customerId = res1.data.invoice.user_id
              this.newInvoice = res1.data.invoice
              this.formData = { ...this.formData, ...res1.data.invoice }

              this.newInvoice.invoice_date = moment(
                res1.data.invoice.invoice_date,
                'YYYY-MM-DD'
              ).toString()

              this.newInvoice.registration_date = moment(
                res1.data.invoice.registration_date,
                'YYYY-MM-DD'
              ).toString()

              this.newInvoice.due_date = moment(
                res1.data.invoice.due_date,
                'YYYY-MM-DD'
              ).toString()

              this.discountPerItem = res1.data.invoice.discount_per_item
              this.newInvoice.ritenuta = res1.data.invoice.ritenuta / 100
              this.selectedCurrency = this.defaultCurrency
              this.invoiceNumAttribute = res1.data.nextInvoiceNumber
              //this.invoicePrefix = res1.data.invoicePrefix
              this.taxPerItem = res1.data.invoice.tax_per_item
              let fields = res1.data.invoice.fields
              this.reverse_charge = res1.data.invoice.reverse_charge
              // SE NON HA UN VALORE ALLORA LO IMPOSTO SU NULL
              if (this.structs_enabled) {


                if (
                  res1.data.invoice.structure_id != '' &&
                  res1.data.invoice.structure_id
                ) {
                  this.newInvoice.structure_id = this.structs.find(
                    (_structure) =>
                      _structure.id === res1.data.invoice.structure_id
                  )
                } else this.newInvoice.structure_id = null

                if (
                  this.newInvoice.structure_id != '' ||
                  this.newInvoice.structure_id != null
                ) {
                  this.selectedStructure = this.structs.find(
                    (_structure) => _structure.id === this.newInvoice.structure_id.id
                  )
                  // ATTIVA MANUALMENTE FUNZION ON SELECT PER ASSEGNARE SOLO L'ID ALTRIMENTI IN
                  // FASE DI EDIT MI PASSA TUTTI I DATI DELLA STRUCTURE SELEZIONATA
                  this.onSelectStructure(this.selectedStructure.id)
                }
              }

              if (this.newInvoice.document_type != '') {
                this.selectedDocumentType = this.documentTypes.find(
                  (_type) => _type.code === this.newInvoice.document_type
                )
              }

              if (res2.data) {
                let customFields = res2.data.customFields.data
                this.setEditCustomFields(fields, customFields)
              }
            }
            // take all payments methods
            if (res3.data) {
              this.payment_methods = res3.data.paymentMethods.data
            }


            this.isLoadingInvoice = false
          })
          .catch((error) => {
            console.log(error)
          })
        await this.fetchCategories({ limit: 'all' })
        // await this.fetchPaymentModes({ limit: 'all' })
        // await this.fetchDeadlines({
        //   limit: 'all',
        //   invoice_id: this.newInvoice.id,
        //   orderByField: 'deadline_date',
        //   orderBy: 'asc',
        // })
        // PRIMA VENIVANO CERCATE LE DEADLINE DI TUTTE LE FATTURE, PERCHE L'ID CHE VIENE PASSATO E 'undefinied' 

        // this.newInvoice.deadlines = this.deadlines
        // console.log(this.newInvoice.structure_id)
        // ESEGUO UN CONTROLLO, SE VEDO CHE E IMPOSTATA UNA STRUTTURA AllORA LA METTO GIA SULLA SELECT ALTRIMENTI NULLA




        return true
      }

      this.isLoadingInvoice = true
      await this.setInitialCustomFields('Invoice')
      await this.fetchTaxTypes({ limit: 'all' })
      await this.fetchCategories({ limit: 'all' })
      await this.fetchPaymentModes({ limit: 'all' })

      this.selectedCurrency = this.defaultCurrency
      this.newInvoice.invoice_date = moment().toString()
      this.newInvoice.registration_date = moment().toString()
      this.newInvoice.due_date = moment().toString()

      this.isLoadingInvoice = false
    },

    openTemplateModal() {
      this.openModal({
        title: this.$t('general.choose_template'),
        componentName: 'InvoiceTemplate',
        data: this.getInvoiceTemplates,
      })
    },

    addItem() {
      this.newInvoice.items.push({
        ...InvoiceStub,
        id: Guid.raw(),
        taxes: [{ ...TaxStub, id: Guid.raw() }],
      })
    },

    removeItem(index) {
      this.newInvoice.items.splice(index, 1)
    },

    updateItem(data) {
      Object.assign(this.newInvoice.items[data.index], { ...data.item })
    },

    addDeadline() {
      this.newInvoice.deadlines.push({
        ...DeadlineStub,
        id: Guid.raw(),
        deadline_date: moment().toString(),
      })
    },

    updateDeadline(data) {
      Object.assign(this.newInvoice.deadlines[data.index], { ...data.deadline })
    },

    newRitenutaDeadline() {
      // Cerco la scadenza della ritenuta
      let ritenutaDeadline = this.newInvoice.deadlines.find(
        (_deadline) => _deadline.ritenuta == 1
      )

      // Se non esiste e ritenuta > 0 l'aggiungo
      if (ritenutaDeadline == null && this.newInvoice.ritenuta > 0) {
        this.newInvoice.deadlines.push({
          ...DeadlineStub,
          id: Guid.raw(),
          amount: this.newInvoice.ritenuta * 100,
          deadline_date: moment()
            .year(moment().year())
            .month(moment().month() + 1)
            .date(16)
            .format('YYYY-MM-DD'),
          ritenuta: 1,
        })
      }

      // Se esiste e ritenuta = 0 la cancello
      if (ritenutaDeadline != null && this.newInvoice.ritenuta == 0) {
        let deadlineindex = null

        this.newInvoice.deadlines.forEach((el, index) => {
          if (el.ritenuta === 1) deadlineindex = index
        })

        if (deadlineindex) {
          this.removeDeadline(deadlineindex)
        }
      }
    },

    removeDeadline(index) {
      this.newInvoice.deadlines.splice(index, 1)
    },

    async submitForm() {
      // return
      let validate = await this.touchCustomField()

      if (!this.checkValid() || validate.error) {
        return false
      }

      this.isLoading = true
      this.newInvoice.invoice_number = this.invoiceNumAttribute //this.invoicePrefix + '-' +
      let data = {
        ...this.formData,
        ...this.newInvoice,
        sub_total: this.subtotal,
        total: this.total,
        tax: this.totalTax,
        user_id: null,
        invoice_template_id: this.getTemplateId,
        invoice_type: 'Normal',
      }
      if (this.selectedCustomer != null) {
        data.user_id = this.selectedCustomer.id
      }
      if (this.$route.name === 'invoices.edit') {
        this.submitUpdate(data)
        return
      }
      this.submitCreate(data)
    },

    submitCreate(data) {
      this.addInvoice(data)
        .then((res) => {
          if (res.data) {
            this.$router.push(`/admin/invoices/${res.data.invoice.id}/edit`)
            this.loadData()

            window.toastr['success'](this.$t('invoices.created_message'))
          }

          this.isLoading = false

          //RIDIREZIONO NELLA PAGINA DELLE FATTURE
          window.location.href =
            'http://reporting.alcyonsoluzionidigitali.it/admin/invoices'
        })
        .catch((err) => {
          this.isLoading = false
        })
    },

    submitUpdate(data) {
      this.updateInvoice(data)
        .then((res) => {
          this.isLoading = false
          if (res.data.success) {
            this.$router.push(`/admin/invoices`)
            // this.$router.push(`/admin/invoices/${res.data.invoice.id}/edit`)
            this.loadData()

            window.toastr['success'](this.$t('invoices.updated_message'))
          }

          if (res.data.error === 'invalid_due_amount') {
            window.toastr['error'](
              this.$t('invoices.invalid_due_amount_message')
            )
          }
        })
        .catch((err) => {
          this.isLoading = false
        })
    },

    checkItemsData(index, isValid) {
      this.newInvoice.items[index].valid = isValid
    },

    checkDeadlinesData(index, isValid) {
      this.newInvoice.deadlines[index].valid = isValid
    },

    onSelectTax(selectedTax) {
      let amount = 0

      if (selectedTax.compound_tax && this.subtotalWithDiscount) {
        amount = Math.round(
          ((this.subtotalWithDiscount + this.totalSimpleTax) *
            selectedTax.percent) /
          100
        )
      } else if (this.subtotalWithDiscount && selectedTax.percent) {
        amount = Math.round(
          (this.subtotalWithDiscount * selectedTax.percent) / 100
        )
      }

      this.newInvoice.taxes.push({
        ...TaxStub,
        id: Guid.raw(),
        name: selectedTax.name,
        percent: selectedTax.percent,
        compound_tax: selectedTax.compound_tax,
        tax_type_id: selectedTax.id,
        amount,
      })

      if (this.$refs) {
        this.$refs.taxModal.close()
      }
    },

    removeInvoiceTax(index) {
      this.newInvoice.taxes.splice(index, 1)
    },

    checkValid() {
      this.$v.newInvoice.$touch()
      this.$v.selectedCustomer.$touch()
      this.$v.invoiceNumAttribute.$touch()

      window.hub.$emit('checkItems') // metodo per far partire il controllo sugli item
      let isValid = true
      let taxValid = true
      this.newInvoice.items.forEach((item) => {
        if (!item.valid) {
          isValid = false
        }
        if (item.taxes.length <= 0 || item.taxes[0].tax_type_id <= 0) {
          isValid = false
          taxValid = false
        }
      })

      if (!taxValid) {
        window.toastr['error'](this.$t('invoices.invalid_tax'))
      }

      let deadlineRitenutaAmount = 0
      let deadlineAmount = 0
      window.hub.$emit('checkDeadlines')
      this.newInvoice.deadlines.forEach((deadline) => {
        if (!deadline.valid) {
          isValid = false
        }

        if (deadline.ritenuta === 0) {
          deadlineAmount = deadlineAmount + deadline.amount
        } else {
          deadlineRitenutaAmount = deadlineRitenutaAmount + deadline.amount
        }
      })

      // SE SONO PAID ME NE SBATTO DEL CONTROLLO, ALTIMENTI MI OBBLIGA AD INSERIRLO
      // --> AGGIUNTA FATTA PER GESTIONE FATTURE PASSIVE
      if (deadlineAmount != this.total && this.newInvoice.paid_status != 'PAID') {
        isValid = false
        window.toastr['error'](
          this.$t('invoices.invalid_deadline_amount_message')
        )
      }

      if (
        this.newInvoice.ritenuta > 0 &&
        deadlineRitenutaAmount / 100 != this.newInvoice.ritenuta
      ) {
        isValid = false

        window.toastr['error'](
          this.$t('invoices.invalid_deadline_ritenuta_amount_message')
        )
      }

      if (
        !this.$v.selectedCustomer.$invalid &&
        !this.$v.invoiceNumAttribute.$invalid &&
        this.$v.newInvoice.$invalid === false &&
        isValid === true
      ) {
        return true
      }

      return false
    },

    onSelectDocumentType(val) {
      this.newInvoice.document_type = val.code

      this.fetchPaymentModes({
        limit: 'all',
        creditnote: this.newInvoice.document_type == 'TD04' ? 1 : 0,
      })
    },
    onSelectStructure(val) {
      if (val.id === undefined) {
        this.newInvoice.structure_id = val
        return
      }
      this.newInvoice.structure_id = val.id

    },
    controllPreviusUrl() {
      // Recupera l'URL della pagina precedente dal sessionStorage
      const referrerUrl = sessionStorage.getItem('previousPage');

      if (referrerUrl && (referrerUrl.includes('/admin/invoices') || referrerUrl.includes('/admin/i-passive'))) {
        // Analizza i parametri della query
        const url = new URL(referrerUrl, window.location.origin);
        const params = url.search;

        // Verifica se ci sono parametri
        return params.length > 0;
      }
      return false;
    },

    goToPreviousPage() {
        this.$router.go(-1); // Torna alla pagina precedente
    },
  },
}
</script>

<style lang="scss">
.invoice-create-page {
  .invoice-foot {
    .invoice-total {
      min-width: 390px;
    }
  }

  @media (max-width: 480px) {
    .invoice-foot {
      .invoice-total {
        min-width: 384px;
      }
    }
  }
}
</style>
