<template>
  <div class="flex items-center justify-between mb-3">
    <div class="flex items-center" style="flex: 3">
      <!--
      <label class="pr-2 mb-0" align="right">
        {{ $t('general.tax') }}
      </label>
      -->
      <sw-select
        v-model="selectedTax"
        :options="filteredTypes"
        :allow-empty="false"
        :show-labels="false"
        :custom-label="customLabel"
        :placeholder="$t('general.select_a_tax')"
        track-by="name"
        label="name"
        @select="(val) => onSelectTax(val)"
      >
        <!--
        <div slot="afterList">
          <button
            type="button"
            class="flex items-center justify-center w-full px-2 py-2 bg-gray-200 border-none outline-none"
            @click="openTaxModal"
          >
            <check-circle-icon class="h-5 text-primary-400" />
            <label class="ml-2 text-sm leading-none text-primary-400">{{
              $t('invoices.add_new_tax')
            }}</label>
          </button>
        </div>
      -->
      </sw-select>

      <br />
    </div>
    <div class="flex items-center justify-center w-6 h-10 mx-2 cursor-pointer">
      <trash-icon class="h-5 text-gray-700" @click="removeTax" />
    </div>
    <div v-if="this.tax.percent != 0">
      <div
        class="text-sm text-right"
        style="flex: 2"
        v-html="$utils.formatMoney(taxAmount, currency)"
      />
    </div>
    <div v-if="this.tax.percent == 0" class="flex items-center" style="flex: 2">
      <sw-select
        v-model="tax_motivation"
        :options="filteredMotivation"
        :allow-empty="false"
        :show-labels="false"
        :placeholder="$t('general.tax_motivation')"
        :custom-label="motivationsLabel"
        track-by="name"
        label="name"
        @select="(val) => onSelectMotivation(val)"
      >
      </sw-select>
      <information-circle-icon
        v-tooltip.left="{
          content:
            'N1: operazioni escluse ex articolo 15<br>' +
            'N2.1: non soggette ad IVA (artt. da 7 a 7-septies del DPR 633/72)<br>' +
            'N2.2: non soggette (altri casi)<br>' +
            'N3.1: non imponibili per l’esportazioni<br>' +
            'N3.2: non imponibili per cessioni intracomunitarie<br>' +
            'N3.3: non imponibili per cessioni verso San Marino<br>' +
            'N3.4: non imponibili per operazioni assimilate alle cessioni all’esportazione<br>' +
            'N3.5: non imponibili a seguito di dichiarazioni d’intento<br>' +
            'N3.6: non imponibili per altre operazioni che non concorrono alla formazione del plafond<br>' +
            'N4: esenti<br>' +
            'N5: regime del margine IVA non esposta in fattura<br>' +
            'N6.1: inversione contabile per la cessione di rottami e altri materiali di recupero<br>' +
            'N6.2: inversione contabile per la cessione di oro e argento puro<br>' +
            'N6.3: inversione contabile per la subappalto nel settore edile<br>' +
            'N6.4: inversione contabile per la cessione di fabbricati<br>' +
            'N6.5: inversione contabile per la cessione di telefoni cellulari<br>' +
            'N6.6: inversione contabile per la cessione di prodotti elettronici<br>' +
            'N6.7: inversione contabile per la prestazioni comparto edile e settori connessi<br>' +
            'N6.8: inversione contabile per la operazioni settore energetico<br>' +
            'N6.9: inversione contabile per la altri casi<br>' +
            'N7: IVA assolta in altro stato UE',
        }"
        class="h-5 text-gray-700"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  CheckCircleIcon,
  TrashIcon,
  InformationCircleIcon,
} from '@vue-hero-icons/solid'
import VTooltip from 'v-tooltip'

export default {
  components: {
    CheckCircleIcon,
    TrashIcon,
    InformationCircleIcon,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    taxData: {
      type: Object,
      required: true,
    },
    taxes: {
      type: Array,
      default: [],
    },
    total: {
      type: Number,
      default: 0,
    },
    totalTax: {
      type: Number,
      default: 0,
    },
    currency: {
      type: [Object, String],
      required: true,
    },
    motivation: {
      type: String,
    },
  },
  data() {
    return {
      tax_motivations: [
        { name: 'N1', description: 'Escluse ex art. 15' },
        { name: 'N2.1', description: 'Non soggette ex artt. da 7 a 7-septies' },
        { name: 'N2.2', description: 'Non soggette altri casi' },
        { name: 'N3.1', description: 'Non imponibili esportazioni' },
        { name: 'N3.2', description: 'Non imponibili cessioni intraUE' },
        {
          name: 'N3.3',
          description: 'Non imponibili cessioni verso San Marino',
        },
        {
          name: 'N3.4',
          description:
            'Non imponibili operazioni assimilate alle cessioni all’esportazione',
        },
        {
          name: 'N3.5',
          description: 'Non imponibili a seguito di dichiarazione d’intento',
        },
        {
          name: 'N3.6',
          description:
            'Non imponibili altre operazioni che non concorrono alla formazione del plafond',
        },
        { name: 'N4', description: 'Esenti' },
        {
          name: 'N5',
          description: 'Regime del margine IVA non esposta in fattura',
        },
        {
          name: 'N6.1',
          description:
            'Inversione contabile cessione di rottami e altri materiali di recupero',
        },
        {
          name: 'N6.2',
          description: 'Inversione contabile cessione di oro e argento puro',
        },
        {
          name: 'N6.3',
          description: 'Inversione contabile subappalto nel settore edile',
        },
        {
          name: 'N6.4',
          description: 'Inversione contabile cessione di fabbricati',
        },
        {
          name: 'N6.5',
          description: 'Inversione contabile cessione di telefoni cellulari',
        },
        {
          name: 'N6.6',
          description: 'Inversione contabile cessione di prodotti elettronici',
        },
        {
          name: 'N6.7',
          description:
            'Inversione contabile prestazioni comparto edile e settori connessi',
        },
        {
          name: 'N6.8',
          description: 'Inversione contabile operazioni settore energetico',
        },
        { name: 'N6.9', description: 'Inversione contabile altri casi' },
        { name: 'N7', description: 'IVA assolta in altro Stato UE' },
      ],
      tax: { ...this.taxData },
      selectedTax: null,
      tax_motivation: null,
    }
  },
  computed: {
    ...mapGetters('taxType', ['taxTypes']),
    filteredTypes() {
      const clonedTypes = this.taxTypes.map((a) => ({ ...a }))
      return clonedTypes.map((taxType) => {
        let found = this.taxes.find((tax) => tax.tax_type_id === taxType.id)

        if (found) {
          taxType.$isDisabled = true
        } else {
          taxType.$isDisabled = false
        }

        return taxType
      })
    },
    filteredMotivation() {

      if((this.tax_motivation !== undefined || this.tax_motivation.name != null || this.tax_motivation != null) && window.location.href.indexOf("view") > -1){
        return this.tax_motivations.map((tax_motivation) => {
          let found = this.tax_motivation.name === tax_motivation.name

          if(found){
            tax_motivation.$isDisabled = false
          } else {
            tax_motivation.$isDisabled = true
          }

          return tax_motivation
        })        
      } else return this.tax_motivations
    },
    taxAmount() {
      if (this.tax.compound_tax && this.total) {
        let enpav = (this.total * 0.02) | 0 
        return Math.round(
          (((this.total + enpav) + this.totalTax) * this.tax.percent) / 100
        )
      }

      if (this.total && this.tax.percent) {
        let enpav = (this.total * 0.02) | 0 
        return Math.round(((this.total + enpav) * this.tax.percent) / 100)
      }

      return 0
    },
  },
  watch: {
    total: {
      handler: 'updateTax',
    },
    totalTax: {
      handler: 'updateTax',
    },
  },
  created() {
    if (this.taxData.tax_type_id > 0) {
      this.selectedTax = this.taxTypes.find(
        (_type) => _type.id === this.taxData.tax_type_id
      )
      this.tax_motivation = this.tax_motivations.find(
        (_name) => _name.name === this.motivation
      )
      
    }
    
    this.updateTax()
    window.hub.$on('newTax', (val) => {
      if (!this.selectedTax) {
        this.selectedTax = val
        this.onSelectTax(val)
      }
    })
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    customLabel({ name, percent }) {
      return `${name} - ${percent}%`
    },
    motivationsLabel({ name, description }) {
      return `${name}` //' ${description}`
    },
    onSelectTax(val) {
      this.tax.percent = val.percent
      this.tax.tax_type_id = val.id
      this.tax.compound_tax = val.compound_tax
      this.tax.name = val.name
      this.updateTax()
    },
    onSelectMotivation(val) {
      this.tax_motivation = val.name
      this.updateTax()
    },
    updateTax() {
      if (this.tax.tax_type_id === 0) {
        return
      }
      this.$emit('update', {
        index: this.index,
        item: {
          ...this.tax,
          amount: this.taxAmount,
          tax_motivation: this.tax_motivation,
        },
        tax_motivation: this.tax_motivation,
      })
    },
    removeTax() {
      this.$emit('remove', this.index, this.tax)
    },
    openTaxModal() {
      this.openModal({
        title: this.$t('settings.tax_types.add_tax'),
        componentName: 'TaxTypeModal',
      })
    },
  },
}
</script>
