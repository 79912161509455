import * as types from './mutation-types'

export default {
  [types.SET_STRUCTURES](state, structures) {
    state.structures = structures
  },

  [types.ADD_STRUCTURE](state, data) {
    state.structures.push(data.structure)
  },

  [types.UPDATE_STRUCTURE](state, data) {
    let pos = state.structures.findIndex(
      (structure) => structure.id === data.structure.id
    )
    Object.assign(state.structures[pos], { ...data.structure })
  },

  [types.DELETE_STRUCTURE](state, id) {
    // console.log('destroy_method' );
    // NON CI PASSA QUI DENTRO
    let pos = state.structures.findIndex((structure) => structure.id === id)
    state.structures.splice(pos, 1)
  },
}
