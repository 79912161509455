var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("navigation.invoices-active") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "dashboard", title: _vm.$t("general.home") },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("navigation.invoices-active", 2),
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalInvoices,
                      expression: "totalInvoices",
                    },
                  ],
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n          "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
              staticClass: "relative grid grid-flow-col grid-rows",
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$tc("customers.customer", 1) },
                },
                [
                  _c("base-customer-select", {
                    ref: "customerSelect",
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.structs_enabled
                ? _c(
                    "sw-input-group",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        label: _vm.$tc("settings.structure.invoice_code"),
                      },
                    },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.structs,
                          "group-select": false,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t(
                            "settings.structure.select_a_structure"
                          ),
                          "allow-empty": false,
                          "track-by": "id",
                          label: "formattedStructure",
                        },
                        on: {
                          select: function (val) {
                            return _vm.onSelectStructure(val)
                          },
                          remove: function ($event) {
                            return _vm.clearStructureSearch()
                          },
                        },
                        model: {
                          value: _vm.structure,
                          callback: function ($$v) {
                            _vm.structure = $$v
                          },
                          expression: "structure",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$tc("invoices.item.category", 1) },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.categories,
                      "group-select": false,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("invoices.item.select_a_category"),
                      "allow-empty": false,
                      "track-by": "id",
                      label: "formattedCategory",
                    },
                    on: {
                      select: function (val) {
                        return _vm.onSelectCategory(val)
                      },
                      remove: function ($event) {
                        return _vm.clearCategorySearch()
                      },
                    },
                    model: {
                      value: _vm.category,
                      callback: function ($$v) {
                        _vm.category = $$v
                      },
                      expression: "category",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$tc("invoices.item.subcategory", 1) },
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.subcats,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("invoices.item.select_a_subcategory"),
                      "allow-empty": false,
                      "track-by": "id",
                      label: "formattedSubcategory",
                    },
                    on: {
                      select: function (val) {
                        return _vm.onSelectSubcategory(val)
                      },
                      remove: function ($event) {
                        return _vm.clearSubcategorySearch()
                      },
                    },
                    model: {
                      value: _vm.subcategory,
                      callback: function ($$v) {
                        _vm.subcategory = $$v
                      },
                      expression: "subcategory",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$t("general.from") },
                },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.from_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "from_date", $$v)
                      },
                      expression: "filters.from_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { staticClass: "mt-2", attrs: { label: _vm.$t("general.to") } },
                [
                  _c("base-date-picker", {
                    attrs: {
                      "calendar-button": true,
                      "calendar-button-icon": "calendar",
                    },
                    model: {
                      value: _vm.filters.to_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "to_date", $$v)
                      },
                      expression: "filters.to_date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$t("invoices.ref_number") },
                },
                [
                  _c(
                    "sw-input",
                    {
                      model: {
                        value: _vm.filters.invoice_number,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "invoice_number", $$v)
                        },
                        expression: "filters.invoice_number",
                      },
                    },
                    [
                      _c("hashtag-icon", {
                        staticClass: "h-5 ml-1 text-gray-500",
                        attrs: { slot: "leftIcon" },
                        slot: "leftIcon",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-2",
                  attrs: { label: _vm.$t("invoices.description") },
                },
                [
                  _c("sw-input", {
                    model: {
                      value: _vm.filters.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "description", $$v)
                      },
                      expression: "filters.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("invoices.no_invoices"),
            description: _vm.$t("invoices.list_of_invoices"),
          },
        },
        [_c("moon-walker-icon", { staticClass: "mt-5 mb-4" })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative",
        },
        [
          _c(
            "div",
            { staticClass: "relative mt-5" },
            [
              _c(
                "p",
                {
                  staticClass: "absolute right-0 m-0 text-sm",
                  staticStyle: { top: "50px" },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("general.showing")) + ": "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.invoices.length))]),
                  _vm._v("\n\n          " + _vm._s(_vm.$t("general.of")) + " "),
                  _c("b", [_vm._v(_vm._s(_vm.totalInvoices))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-tabs",
                {
                  attrs: { "active-tab": _vm.activeTab },
                  on: { update: _vm.setStatusFilter },
                },
                [
                  _c("sw-tab-item", {
                    attrs: { title: _vm.$t("general.all"), filter: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.structs_enabled
            ? _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.registration_date"),
                      "sort-as": "registration_date",
                      show: "formattedRegistrationDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.date"),
                      "sort-as": "invoice_date",
                      show: "formattedInvoiceDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.ref_no"),
                      show: "invoice_number",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("invoices.number"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: {
                                      path: "i-active/" + row.id + "/view",
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row.invoice_number) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2077491842
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.number"),
                      show: "reference_number",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.customer"),
                      width: "20%",
                      show: "name",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      show: "structure_name",
                      sortable: true,
                      label: _vm.$t("settings.structure.invoice_code"),
                      width: "20%",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.description"),
                      width: "20%",
                      show: "description",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.item.net"),
                      "sort-as": "sub_total",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("invoices.item.net"))),
                              ]),
                              _vm._v(" "),
                              row.document_type == "TD04"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          -row.sub_total,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              row.document_type != "TD04"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.sub_total,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3819821326
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.ritenuta"),
                      "sort-as": "ritenuta",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("invoices.ritenuta"))),
                              ]),
                              _vm._v(" "),
                              row.document_type == "TD04"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          -row.ritenuta,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              row.document_type != "TD04"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.ritenuta,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1042369454
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.total"),
                      "sort-as": "total",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("invoices.total"))),
                              ]),
                              _vm._v(" "),
                              row.document_type == "TD04"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          -row.total,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              row.document_type != "TD04"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.total,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3267918376
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.amount_due"),
                      "sort-as": "due_amount",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("invoices.amount_due"))),
                              ]),
                              _vm._v(" "),
                              row.document_type == "TD04"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          -row.due_amount,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              row.document_type != "TD04"
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.due_amount,
                                          row.user.currency
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2564364653
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.paid_status"),
                      "sort-as": "paid_status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("invoices.paid_status"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-badge",
                                {
                                  attrs: {
                                    "bg-color": _vm.$utils.getBadgeStatusColor(
                                      row.status
                                    ).bgColor,
                                    color: _vm.$utils.getBadgeStatusColor(
                                      row.status
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.paid_status
                                          .replace("PAID", "PAGATO")
                                          .replace("UN", "NON_")
                                          .replace(
                                            "PARTIALLY_",
                                            "PARZIALMENTE_"
                                          )
                                          .replace("_", " ")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2856172950
                    ),
                  }),
                ],
                1
              )
            : _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.registration_date"),
                      "sort-as": "registration_date",
                      show: "formattedRegistrationDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.date"),
                      "sort-as": "invoice_date",
                      show: "formattedInvoiceDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.ref_no"),
                      show: "invoice_number",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("invoices.number"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "font-medium text-primary-500",
                                attrs: {
                                  to: { path: "i-active/" + row.id + "/view" },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row.invoice_number) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.number"),
                      show: "reference_number",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.customer"),
                      width: "20%",
                      show: "name",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.description"),
                      width: "20%",
                      show: "description",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.item.net"),
                      "sort-as": "sub_total",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("invoices.item.net"))),
                            ]),
                            _vm._v(" "),
                            row.document_type == "TD04"
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        -row.sub_total,
                                        row.user.currency
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            row.document_type != "TD04"
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.sub_total,
                                        row.user.currency
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.ritenuta"),
                      "sort-as": "ritenuta",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("invoices.ritenuta"))),
                            ]),
                            _vm._v(" "),
                            row.document_type == "TD04"
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        -row.ritenuta,
                                        row.user.currency
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            row.document_type != "TD04"
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.ritenuta,
                                        row.user.currency
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.total"),
                      "sort-as": "total",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("invoices.total"))),
                            ]),
                            _vm._v(" "),
                            row.document_type == "TD04"
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        -row.total,
                                        row.user.currency
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            row.document_type != "TD04"
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.total,
                                        row.user.currency
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.amount_due"),
                      "sort-as": "due_amount",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("invoices.amount_due"))),
                            ]),
                            _vm._v(" "),
                            row.document_type == "TD04"
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        -row.due_amount,
                                        row.user.currency
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            row.document_type != "TD04"
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.due_amount,
                                        row.user.currency
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("invoices.paid_status"),
                      "sort-as": "paid_status",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("invoices.paid_status"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "sw-badge",
                              {
                                attrs: {
                                  "bg-color": _vm.$utils.getBadgeStatusColor(
                                    row.paid_status
                                  ).bgColor,
                                  color: _vm.$utils.getBadgeStatusColor(
                                    row.paid_status
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.paid_status
                                        .replace("PAID", "PAGATO")
                                        .replace("UN", "NON_")
                                        .replace("PARTIALLY_", "PARZIALMENTE_")
                                        .replace("_", " ")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }