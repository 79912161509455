import * as types from './mutation-types'

export default {
  [types.SET_SUBCATEGORIES](state, subcategories) {
    state.subcategories = subcategories
  },

  [types.ADD_SUBCATEGORY](state, data) {
    state.subcategories.push(data.subcategory)
  },

  [types.UPDATE_SUBCATEGORY](state, data) {
    let pos = state.subcategories.findIndex(
      (subcategory) => subcategory.id === data.subcategory.id
    )
    Object.assign(state.subcategories[pos], { ...data.subcategory })
  },

  [types.DELETE_SUBCATEGORY](state, id) {
    let pos = state.subcategories.findIndex((subcategory) => subcategory.id === id)
    state.subcategories.splice(pos, 1)
  },
}
