import { render, staticRenderFns } from "./ItemsTab.vue?vue&type=template&id=1c406d13&"
import script from "./ItemsTab.vue?vue&type=script&lang=js&"
export * from "./ItemsTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\_Lavoro\\ALCYON\\Homestead\\code\\reporting\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c406d13')) {
      api.createRecord('1c406d13', component.options)
    } else {
      api.reload('1c406d13', component.options)
    }
    module.hot.accept("./ItemsTab.vue?vue&type=template&id=1c406d13&", function () {
      api.rerender('1c406d13', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/settings/customization-tabs/ItemsTab.vue"
export default component.exports