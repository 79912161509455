var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSuperAdmin
    ? _c(
        "base-page",
        { staticClass: "items" },
        [
          _c(
            "sw-page-header",
            { attrs: { title: _vm.$t("users.title") } },
            [
              _c(
                "sw-breadcrumb",
                { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
                [
                  _c("sw-breadcrumb-item", {
                    attrs: { to: "dashboard", title: _vm.$t("general.home") },
                  }),
                  _vm._v(" "),
                  _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$tc("users.title", 2),
                      active: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "sw-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalUsers,
                          expression: "totalUsers",
                        },
                      ],
                      attrs: { variant: "primary-outline", size: "lg" },
                      on: { click: _vm.toggleFilter },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.filter")) +
                          "\n        "
                      ),
                      _c(_vm.filterIcon, {
                        tag: "component",
                        staticClass: "w-4 h-4 ml-2 -mr-1",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        "tag-name": "router-link",
                        to: "users/create",
                        variant: "primary",
                        size: "lg",
                      },
                    },
                    [
                      _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("users.add_user")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "slide-y-up-transition",
            [
              _c(
                "sw-filter-wrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters",
                    },
                  ],
                  staticClass: "mt-3",
                },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("users.name") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "name",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2 mr-4",
                      attrs: { label: _vm.$tc("users.email") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "email",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "email", $$v)
                          },
                          expression: "filters.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "flex-1 mt-2",
                      attrs: { label: _vm.$tc("users.phone") },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          type: "text",
                          name: "phone",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "phone", $$v)
                          },
                          expression: "filters.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                      staticStyle: { top: "10px", right: "15px" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("general.clear_all")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-empty-table-placeholder",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              attrs: {
                title: _vm.$t("users.no_users"),
                description: _vm.$t("users.list_of_users"),
              },
            },
            [
              _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "/admin/users/create",
                    size: "lg",
                    variant: "primary-outline",
                  },
                  slot: "actions",
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("users.add_user")) + "\n    "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "relative table-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid",
                },
                [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.users.length))]),
                    _vm._v(
                      "\n\n        " +
                        _vm._s(_vm.$t("general.of")) +
                        "\n\n        "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.totalUsers))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "sw-transition",
                    { attrs: { type: "fade" } },
                    [
                      _vm.selectedUsers.length
                        ? _c(
                            "sw-dropdown",
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                                  attrs: { slot: "activator" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n            "
                                  ),
                                  _c("chevron-down-icon", {
                                    staticClass: "h-5",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown-item",
                                { on: { click: _vm.removeMultipleUsers } },
                                [
                                  _c("trash-icon", {
                                    staticClass: "h-5 mr-3 text-gray-600",
                                  }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.delete")) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
                },
                [
                  _c("sw-checkbox", {
                    staticClass: "hidden md:inline",
                    attrs: { variant: "primary", size: "sm" },
                    on: { change: _vm.selectAllUsers },
                    model: {
                      value: _vm.selectAllFieldStatus,
                      callback: function ($$v) {
                        _vm.selectAllFieldStatus = $$v
                      },
                      expression: "selectAllFieldStatus",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-checkbox", {
                    staticClass: "md:hidden",
                    attrs: {
                      label: _vm.$t("general.select_all"),
                      variant: "primary",
                      size: "sm",
                    },
                    on: { change: _vm.selectAllUsers },
                    model: {
                      value: _vm.selectAllFieldStatus,
                      callback: function ($$v) {
                        _vm.selectAllFieldStatus = $$v
                      },
                      expression: "selectAllFieldStatus",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.fetchData,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return _c(
                              "div",
                              { staticClass: "custom-control custom-checkbox" },
                              [
                                _c("sw-checkbox", {
                                  attrs: {
                                    id: row.id,
                                    value: row.id,
                                    variant: "primary",
                                    size: "sm",
                                  },
                                  model: {
                                    value: _vm.selectField,
                                    callback: function ($$v) {
                                      _vm.selectField = $$v
                                    },
                                    expression: "selectField",
                                  },
                                }),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3150036800
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("users.name"),
                      show: "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("users.name"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  attrs: {
                                    to: { path: "users/" + row.id + "/edit" },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(row.name) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2266017303
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("users.email"),
                      show: "email",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("users.phone"),
                      show: "phone",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("users.phone"))),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(row.phone ? row.phone : "No Contact") +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3015326942
                    ),
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("users.added_on"),
                      "sort-as": "created_at",
                      show: "formattedCreatedAt",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      label: _vm.$t("users.active"),
                      "sort-as": "active",
                      show: "formattedActive",
                    },
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("users.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "sw-dropdown",
                                [
                                  _c("dot-icon", {
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      attrs: {
                                        "tag-name": "router-link",
                                        to: "users/" + row.id + "/edit",
                                      },
                                    },
                                    [
                                      _c("pencil-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.edit")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  row.active
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.userDeactivate(row.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("x-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("users.deactivate")
                                              ) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !row.active
                                    ? _c(
                                        "sw-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.userActivate(row.id)
                                            },
                                          },
                                        },
                                        [
                                          _c("check-circle-icon", {
                                            staticClass:
                                              "h-5 mr-3 text-gray-600",
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("users.activate")) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "sw-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeUser(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("trash-icon", {
                                        staticClass: "h-5 mr-3 text-gray-600",
                                      }),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3312661470
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }