<template>
  <base-page>
    <sw-page-header :title="$t('expenses.title')">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="dashboard" :title="$t('general.home')" />
        <sw-breadcrumb-item to="#" :title="$tc('expenses.expense', 2)" active />
      </sw-breadcrumb>

      <template slot="actions">
        <sw-button v-show="totalInvoices" size="lg" variant="primary-outline" @click="toggleFilter">
          {{ $t('general.filter') }}
          <component :is="filterIcon" class="w-4 h-4 ml-2 -mr-1" />
        </sw-button>

        <sw-button tag-name="router-link" to="/admin/invoices/create" class="ml-4" size="lg" variant="primary">
          <plus-icon class="w-6 h-6 mr-1 -ml-2" />
          {{ $t('expenses.new_expense') }}
        </sw-button>
      </template>
    </sw-page-header>

    <slide-y-up-transition>
      <sw-filter-wrapper v-show="showFilters" class="relative grid grid-flow-col grid-rows">
        <sw-input-group :label="$tc('customers.customer', 1)" class="mt-2">
          <base-customer-select ref="customerSelect" @select="onSelectCustomer" @deselect="clearCustomerSearch" />
        </sw-input-group>

        <!-- AGGIUNTO 20/06/22 -->
        <!-- FILTRO -->
        <sw-input-group v-if="structs_enabled" :label="$tc('settings.structure.invoice_code')" class="mt-2">
          <sw-select v-model="structure" :options="structs" :group-select="false" :searchable="true"
            :show-labels="false" :placeholder="$t('settings.structure.select_a_structure')" :allow-empty="false"
            track-by="id" label="formattedStructure" @select="(val) => onSelectStructure(val)"
            @remove="clearStructureSearch()" />
        </sw-input-group>

        <sw-input-group :label="$tc('invoices.item.category', 1)" class="mt-2">
          <sw-select v-model="category" :options="categories" :group-select="false" :searchable="true"
            :show-labels="false" :placeholder="$t('invoices.item.select_a_category')" :allow-empty="false" track-by="id"
            label="formattedCategory" @select="(val) => onSelectCategory(val)" @remove="clearCategorySearch()" />
        </sw-input-group>

        <sw-input-group :label="$tc('invoices.item.subcategory', 1)" class="mt-2">
          <sw-select v-model="subcategory" :options="subcats" :searchable="true" :show-labels="false"
            :placeholder="$t('invoices.item.select_a_subcategory')" :allow-empty="false" track-by="id"
            label="formattedSubcategory" @select="(val) => onSelectSubcategory(val)"
            @remove="clearSubcategorySearch()" />
        </sw-input-group>

        <sw-input-group :label="$t('general.from')" class="mt-2">
          <base-date-picker v-model="filters.from_date" :calendar-button="true" calendar-button-icon="calendar" />
        </sw-input-group>

        <sw-input-group :label="$t('general.to')" class="mt-2">
          <base-date-picker v-model="filters.to_date" :calendar-button="true" calendar-button-icon="calendar" />
        </sw-input-group>

        <sw-input-group :label="$t('invoices.ref_number')" class="mt-2">
          <sw-input v-model="filters.invoice_number">
            <hashtag-icon slot="leftIcon" class="h-5 ml-1 text-gray-500" />
          </sw-input>
        </sw-input-group>

        <sw-input-group :label="$t('invoices.description')" class="mt-2">
          <sw-input v-model="filters.description" />
        </sw-input-group>

        <label class="absolute text-sm leading-snug text-black cursor-pointer" @click="clearFilter"
          style="top: 10px; right: 15px">{{ $t('general.clear_all') }}</label>
      </sw-filter-wrapper>
    </slide-y-up-transition>

    <sw-empty-table-placeholder v-show="showEmptyScreen" :title="$t('invoices.no_invoices')"
      :description="$t('invoices.list_of_invoices')">
      <moon-walker-icon class="mt-5 mb-4" />

      <sw-button slot="actions" tag-name="router-link" to="/admin/invoices/create" size="lg" variant="primary-outline">
        <plus-icon class="w-6 h-6 mr-1 -ml-2" />
        {{ $t('invoices.new_invoice') }}
      </sw-button>
    </sw-empty-table-placeholder>

    <div v-show="!showEmptyScreen" class="relative">
      <div class="relative mt-5">
        <p class="absolute right-0 m-0 text-sm" style="top: 50px">
          {{ $t('general.showing') }}: <b>{{ invoices.length }}</b>

          {{ $t('general.of') }} <b>{{ totalInvoices }}</b>
        </p>

        <sw-tabs :active-tab="activeTab" @update="setStatusFilter">
          <!--
          <sw-tab-item :title="$t('general.due')" filter="DUE" />
          <sw-tab-item :title="$t('general.draft')" filter="DRAFT" />
          -->
          <sw-tab-item :title="$t('general.all')" filter="" />
        </sw-tabs>

        <sw-transition type="fade">
          <sw-dropdown v-if="selectedInvoices.length" class="absolute float-right" style="margin-top: -35px">
            <span slot="activator" class="flex block text-sm font-medium cursor-pointer select-none text-primary-400">
              {{ $t('general.actions') }}
              <chevron-down-icon class="h-5" />
            </span>

            <sw-dropdown-item @click="removeMultipleInvoices">
              <trash-icon class="h-5 mr-3 text-gray-600" />
              {{ $t('general.delete') }}
            </sw-dropdown-item>
          </sw-dropdown>
        </sw-transition>
      </div>

      <div v-show="invoices && invoices.length" class="absolute z-10 items-center pl-4 mt-2 select-none md:mt-12">
        <sw-checkbox v-model="selectAllFieldStatus" variant="primary" size="sm" class="hidden md:inline"
          @change="selectAllInvoices" />

        <sw-checkbox v-model="selectAllFieldStatus" :label="$t('general.select_all')" variant="primary" size="sm"
          class="md:hidden" @change="selectAllInvoices" />
      </div>

      <sw-table-component v-if="structs_enabled" ref="table" :show-filter="false" :data="fetchData" table-class="table">
        <sw-table-column :sortable="false" :filterable="false" cell-class="no-click">
          <div slot-scope="row" class="relative block">
            <sw-checkbox v-if="row.paid_status != 'PAID'" :id="row.id" v-model="selectField" :value="row.id"
              variant="primary" size="sm" />
          </div>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.registration_date')" sort-as="registration_date"
          show="formattedRegistrationDate" />

        <sw-table-column :sortable="true" :label="$t('invoices.date')" sort-as="invoice_date"
          show="formattedInvoiceDate" />

        <sw-table-column :sortable="true" :label="$t('invoices.ref_no')" show="invoice_number">
          <template slot-scope="row">
            <span>{{ $t('invoices.number') }}</span>
            <router-link v-if="row.paid_status != 'PAID'" :to="{ path: `invoices/${row.id}/edit` }"
              class="font-medium text-primary-500">
              {{ row.invoice_number }}
            </router-link>
            <router-link v-if="row.paid_status == 'PAID'" :to="{ path: `invoices/${row.id}/view` }"
              class="font-medium text-primary-500">
              {{ row.invoice_number }}
            </router-link>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.number')" show="reference_number" />

        <sw-table-column :sortable="true" :label="$t('invoices.customer')" width="20%" show="name" />

        <!-- //AGGIUNTO 15/06 -->
        <sw-table-column v-if="structs_enabled" :sortable="true" :label="$t('settings.structure.invoice_code')"
          width="20%" show="structure_name" />

        <!--
        <sw-table-column
          :sortable="true"
          :label="$t('invoices.status')"
          sort-as="status"
        >
          <template slot-scope="row">
            <span> {{ $t('invoices.status') }}</span>

            <sw-badge
              :bg-color="$utils.getBadgeStatusColor(row.status).bgColor"
              :color="$utils.getBadgeStatusColor(row.status).color"
            >
              {{ row.status.replace('_', ' ') }}
            </sw-badge>
          </template>
        </sw-table-column>
-->

        <sw-table-column :sortable="true" :label="$t('invoices.description')" width="20%" show="description" />

        <sw-table-column :sortable="true" :label="$t('invoices.item.net')" sort-as="sub_total">
          <template slot-scope="row">
            <span>{{ $t('invoices.item.net') }}</span>

            <div v-if="row.document_type == 'TD04'" v-html="$utils.formatMoney(-row.sub_total, row.user.currency)" />
            <!-- vecchia gestione -->
            <!-- <div
              v-if="row.document_type == 'TD01' || row.document_type == 'TD02'"
              v-html="$utils.formatMoney(row.sub_total, row.user.currency)"
            /> -->
            <div v-if="row.document_type != 'TD04'" v-html="$utils.formatMoney(row.sub_total, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.ritenuta')" sort-as="ritenuta">
          <template slot-scope="row">
            <span>{{ $t('invoices.ritenuta') }}</span>

            <div v-if="row.document_type == 'TD04'" v-html="$utils.formatMoney(-(row.ritenuta), row.user.currency)
      " />

            <div v-if="row.document_type != 'TD04'" v-html="$utils.formatMoney(row.ritenuta, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.total')" sort-as="total">
          <template slot-scope="row">
            <span>{{ $t('invoices.total') }}</span>

            <div v-if="row.document_type == 'TD04'" v-html="$utils.formatMoney(-row.total, row.user.currency)" />

            <div v-if="row.document_type != 'TD04'" v-html="$utils.formatMoney(row.total, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.amount_due')" sort-as="due_amount">
          <template slot-scope="row">
            <span>{{ $t('invoices.amount_due') }}</span>

            <div v-if="row.document_type == 'TD04'" v-html="$utils.formatMoney(-row.due_amount, row.user.currency)" />

            <div v-if="row.document_type != 'TD04'" v-html="$utils.formatMoney(row.due_amount, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.paid_status')" sort-as="paid_status">
          <template slot-scope="row">
            <span>{{ $t('invoices.paid_status') }}</span>

            <sw-badge :bg-color="$utils.getBadgeStatusColor(row.paid_status).bgColor"
              :color="$utils.getBadgeStatusColor(row.paid_status).color">
              {{
      row.paid_status
        .replace('PAID', 'PAGATO')
        .replace('UN', 'NON_')
        .replace('PARTIALLY_', 'PARZIALMENTE_')
        .replace('_', ' ')
    }}
            </sw-badge>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="false" :filterable="false" cell-class="action-dropdown no-click">
          <template slot-scope="row">
            <span>{{ $t('invoices.action') }}</span>

            <sw-dropdown>
              <dot-icon slot="activator" />

              <sw-dropdown-item v-if="row.paid_status != 'PAID'" tag-name="router-link" :to="`invoices/${row.id}/edit`">
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item>

              <sw-dropdown-item v-if="row.paid_status == 'PAID'" tag-name="router-link" :to="`invoices/${row.id}/view`">
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.view') }}
              </sw-dropdown-item>
              <!--
              <sw-dropdown-item
                tag-name="router-link"
                :to="`invoices/${row.id}/view`"
              >
                <eye-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.view') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status == 'DRAFT'"
                @click="sendInvoice(row)"
              >
                <paper-airplane-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.send_invoice') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status === 'SENT' || row.status === 'VIEWED'"
                @click="sendInvoice(row)"
              >
                <paper-airplane-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.resend_invoice') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status == 'DRAFT'"
                @click="markInvoiceAsSent(row.id)"
              >
                <check-circle-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.mark_as_sent') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="
                  row.status === 'SENT' ||
                  row.status === 'VIEWED' ||
                  row.status === 'OVERDUE'
                "
                tag-name="router-link"
                :to="`/admin/payments/${row.id}/create`"
              >
                <credit-card-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('payments.record_payment') }}
              </sw-dropdown-item>

              <sw-dropdown-item @click="onCloneInvoice(row.id)">
                <document-duplicate-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.clone_invoice') }}
              </sw-dropdown-item>
-->
              <sw-dropdown-item v-if="row.paid_status != 'PAID'" @click="removeInvoice(row.id)">
                <trash-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.delete') }}
              </sw-dropdown-item>
            </sw-dropdown>
          </template>
        </sw-table-column>
      </sw-table-component>

      <!--STAMPO TABELLA SENZA CAMPO STRUTTURE-->
      <sw-table-component v-else ref="table" :show-filter="false" :data="fetchData" table-class="table">
        <sw-table-column :sortable="false" :filterable="false" cell-class="no-click">
          <div slot-scope="row" class="relative block">
            <sw-checkbox v-if="row.paid_status != 'PAID'" :id="row.id" v-model="selectField" :value="row.id"
              variant="primary" size="sm" />
          </div>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.registration_date')" sort-as="registration_date"
          show="formattedRegistrationDate" />

        <sw-table-column :sortable="true" :label="$t('invoices.date')" sort-as="invoice_date"
          show="formattedInvoiceDate" />

        <sw-table-column :sortable="true" :label="$t('invoices.ref_no')" show="invoice_number">
          <template slot-scope="row">
            <span>{{ $t('invoices.number') }}</span>
            <router-link v-if="row.paid_status != 'PAID'" :to="{ path: `invoices/${row.id}/edit` }"
              class="font-medium text-primary-500">
              {{ row.invoice_number }}
            </router-link>
            <router-link v-if="row.paid_status == 'PAID'" :to="{ path: `invoices/${row.id}/view` }"
              class="font-medium text-primary-500">
              {{ row.invoice_number }}
            </router-link>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.number')" show="reference_number" />

        <sw-table-column :sortable="true" :label="$t('invoices.customer')" width="20%" show="name" />

        <!--
        <sw-table-column
          :sortable="true"
          :label="$t('invoices.status')"
          sort-as="status"
        >
          <template slot-scope="row">
            <span> {{ $t('invoices.status') }}</span>

            <sw-badge
              :bg-color="$utils.getBadgeStatusColor(row.status).bgColor"
              :color="$utils.getBadgeStatusColor(row.status).color"
            >
              {{ row.status.replace('_', ' ') }}
            </sw-badge>
          </template>
        </sw-table-column>
-->

        <sw-table-column :sortable="true" :label="$t('invoices.description')" width="20%" show="description" />

        <sw-table-column :sortable="true" :label="$t('invoices.item.net')" sort-as="sub_total">
          <template slot-scope="row">
            <span>{{ $t('invoices.item.net') }}</span>

            <div v-if="row.document_type == 'TD04'" v-html="$utils.formatMoney(-row.sub_total, row.user.currency)" />

            <div v-if="row.document_type != 'TD04'" v-html="$utils.formatMoney(row.sub_total, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.ritenuta')" sort-as="ritenuta">
          <template slot-scope="row">
            <span>{{ $t('invoices.ritenuta') }}</span>

            <div v-if="row.document_type == 'TD04'" v-html="$utils.formatMoney(-(row.ritenuta), row.user.currency)
      " />

            <div v-if="row.document_type != 'TD04'" v-html="$utils.formatMoney(row.ritenuta, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.total')" sort-as="total">
          <template slot-scope="row">
            <span>{{ $t('invoices.total') }}</span>

            <div v-if="row.document_type == 'TD04'" v-html="$utils.formatMoney(-row.total, row.user.currency)" />

            <div v-if="row.document_type != 'TD04'" v-html="$utils.formatMoney(row.total, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.amount_due')" sort-as="due_amount">
          <template slot-scope="row">
            <span>{{ $t('invoices.amount_due') }}</span>

            <div v-if="row.document_type == 'TD04'" v-html="$utils.formatMoney(-row.due_amount, row.user.currency)" />

            <div v-if="row.document_type != 'TD04'" v-html="$utils.formatMoney(row.due_amount, row.user.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('invoices.paid_status')" sort-as="paid_status">
          <template slot-scope="row">
            <span>{{ $t('invoices.paid_status') }}</span>

            <sw-badge :bg-color="$utils.getBadgeStatusColor(row.paid_status).bgColor"
              :color="$utils.getBadgeStatusColor(row.paid_status).color">
              {{
      row.paid_status
        .replace('PAID', 'PAGATO')
        .replace('UN', 'NON_')
        .replace('PARTIALLY_', 'PARZIALMENTE_')
        .replace('_', ' ')
    }}
            </sw-badge>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="false" :filterable="false" cell-class="action-dropdown no-click">
          <template slot-scope="row">
            <span>{{ $t('invoices.action') }}</span>

            <sw-dropdown>
              <dot-icon slot="activator" />

              <sw-dropdown-item v-if="row.paid_status != 'PAID'" tag-name="router-link" :to="`invoices/${row.id}/edit`">
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item>

              <sw-dropdown-item v-if="row.paid_status == 'PAID'" tag-name="router-link" :to="`invoices/${row.id}/view`">
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.view') }}
              </sw-dropdown-item>
              <!--
              <sw-dropdown-item
                tag-name="router-link"
                :to="`invoices/${row.id}/view`"
              >
                <eye-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.view') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status == 'DRAFT'"
                @click="sendInvoice(row)"
              >
                <paper-airplane-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.send_invoice') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status === 'SENT' || row.status === 'VIEWED'"
                @click="sendInvoice(row)"
              >
                <paper-airplane-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.resend_invoice') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="row.status == 'DRAFT'"
                @click="markInvoiceAsSent(row.id)"
              >
                <check-circle-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.mark_as_sent') }}
              </sw-dropdown-item>

              <sw-dropdown-item
                v-if="
                  row.status === 'SENT' ||
                  row.status === 'VIEWED' ||
                  row.status === 'OVERDUE'
                "
                tag-name="router-link"
                :to="`/admin/payments/${row.id}/create`"
              >
                <credit-card-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('payments.record_payment') }}
              </sw-dropdown-item>

              <sw-dropdown-item @click="onCloneInvoice(row.id)">
                <document-duplicate-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('invoices.clone_invoice') }}
              </sw-dropdown-item>
-->
              <sw-dropdown-item v-if="row.paid_status != 'PAID'" @click="removeInvoice(row.id)">
                <trash-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.delete') }}
              </sw-dropdown-item>
            </sw-dropdown>
          </template>
        </sw-table-column>
      </sw-table-component>
    </div>
    <div class="flex justify-end">
      <div class="text-right mt-8 ml-4" v-show="invoices && invoices.length">
        <sw-button size="lg" variant="primary" @click="downloadActive()">
          <download-icon class="h-5 mr-1 -ml-2" />
          {{ $t('reports.download_excel_active') }}
        </sw-button>
      </div>
      <div class="text-right mt-8 ml-4" v-show="invoices && invoices.length">
        <sw-button size="lg" variant="primary" @click="downloadPassive()">
          <download-icon class="h-5 mr-1 -ml-2" />
          {{ $t('reports.download_excel_passive') }}
        </sw-button>
      </div>
      <div class="text-right mt-8 ml-4" v-show="invoices && invoices.length">
        <sw-button size="lg" variant="primary" @click="downloadAll()">
          <download-icon class="h-5 mr-1 -ml-2" />
          {{ $t('reports.download_excel_all') }}
        </sw-button>
      </div>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MoonWalkerIcon from '@/components/icon/MoonwalkerIcon'
import moment from 'moment'

import {
  PencilIcon,
  DocumentDuplicateIcon,
  CreditCardIcon,
  FilterIcon,
  XIcon,
  ChevronDownIcon,
  EyeIcon,
  PlusIcon,
  DocumentTextIcon,
  PaperAirplaneIcon,
  CheckCircleIcon,
  TrashIcon,
  XCircleIcon,
  HashtagIcon,
  DownloadIcon,
} from '@vue-hero-icons/solid'

import { DotsHorizontalIcon } from '@vue-hero-icons/outline'

export default {
  components: {
    MoonWalkerIcon,
    PlusIcon,
    FilterIcon,
    XIcon,
    ChevronDownIcon,
    DotsHorizontalIcon,
    PencilIcon,
    DocumentDuplicateIcon,
    TrashIcon,
    CheckCircleIcon,
    PaperAirplaneIcon,
    DocumentTextIcon,
    XCircleIcon,
    EyeIcon,
    CreditCardIcon,
    HashtagIcon,
    DownloadIcon,
  },

  data() {
    return {
      showFilters: false,
      currency: null,
      structure: null,
      category: null,
      subcategory: null,
      subcats: [],
      structs: [], //AGGIUNTO 20/06/22
      structs_enabled: null, // AGGIUNTO 24/06/22

      status: [
        {
          label: 'Status',
          isDisable: true,
          options: [
            { name: 'DRAFT', value: 'DRAFT' },
            { name: 'DUE', value: 'DUE' },
            { name: 'SENT', value: 'SENT' },
            { name: 'VIEWED', value: 'VIEWED' },
            { name: 'OVERDUE', value: 'OVERDUE' },
            { name: 'COMPLETED', value: 'COMPLETED' },
          ],
        },
        {
          label: 'Paid Status',
          options: [
            { name: 'UNPAID', value: 'UNPAID' },
            { name: 'PAID', value: 'PAGATO' },
            { name: 'PARTIALLY PAID', value: 'PARTIALLY_PAID' },
          ],
        },
      ],

      isRequestOngoing: true,
      activeTab: this.$t('general.all'),
      filters: {
        customer: '',
        structure: null,
        //status: { name: 'DUE', value: 'DUE' },
        status: { name: '', value: '' },
        category: null,
        subcategory: null,
        from_date: '',
        to_date: '',
        invoice_number: '',
        description: '',
      },
    }
  },

  computed: {
    showEmptyScreen() {
      return !this.totalInvoices && !this.isRequestOngoing
    },

    filterIcon() {
      return this.showFilters ? 'x-icon' : 'filter-icon'
    },

    ...mapGetters('customer', ['customers']),
    ...mapGetters('structure', ['structures']), //AGGIUNTO 20/06/22
    ...mapGetters('category', ['categories']),
    ...mapGetters('subcategory', ['subcategories']),
    ...mapGetters('company', ['getSelectedCompany']),

    ...mapGetters('invoice', [
      'selectedInvoices',
      'totalInvoices',
      'invoices',
      'selectAllField',
    ]),

    selectField: {
      get: function () {
        return this.selectedInvoices
      },
      set: function (val) {
        this.selectInvoice(val)
      },
    },

    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },

  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  mounted() {
    this.loadFiltersFromUrl()
    this.setInitialData()
  },

  destroyed() {
    if (this.selectAllField) {
      this.selectAllInvoices()
    }
  },

  methods: {
    ...mapActions('invoice', [
      'fetchInvoices',
      'getRecord',
      'selectInvoice',
      'resetSelectedInvoices',
      'selectAllInvoices',
      'deleteInvoice',
      'deleteMultipleInvoices',
      'sendEmail',
      'markAsSent',
      'setSelectAllState',
      'cloneInvoice',
    ]),
    ...mapActions('customer', ['fetchCustomers']),
    ...mapActions('customer', ['fetchCustomer']),
    ...mapActions('structure', ['fetchStructures']), //AGGIUNTO 20/06/22

    ...mapActions('modal', ['openModal']),
    ...mapActions('user', ['fetchCurrentUser']),
    ...mapActions('category', ['fetchCategories']),
    ...mapActions('category', ['fetchCategory']),
    ...mapActions('subcategory', ['fetchSubcategories']),
    ...mapActions('subcategory', ['fetchSubcategory']),

    async sendInvoice(invoice) {
      this.openModal({
        title: this.$t('invoices.send_invoice'),
        componentName: 'SendInvoiceModal',
        id: invoice.id,
        data: invoice,
        variant: 'lg',
      })
    },

    async markInvoiceAsSent(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('invoices.invoice_mark_as_sent'),
        icon: '/assets/icon/check-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          const data = {
            id: id,
            status: 'SENT',
          }
          let response = await this.markAsSent(data)
          this.refreshTable()
          if (response.data) {
            window.toastr['success'](
              this.$tc('invoices.mark_as_sent_successfully')
            )
          }
        }
      })
    },

    async onCloneInvoice(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('invoices.confirm_clone'),
        icon: '/assets/icon/check-circle-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          let response = await this.cloneInvoice({ id })

          this.refreshTable()

          if (response.data) {
            window.toastr['success'](this.$tc('invoices.cloned_successfully'))
            this.$router.push(
              `/admin/invoices/${response.data.invoice.id}/edit`
            )
          }
        }
      })
    },

    setStatusFilter(val) {
      if (this.activeTab == val.title) {
        return true
      }
      this.activeTab = val.title
      switch (val.title) {
        case this.$t('general.due'):
          this.filters.status = {
            name: 'DUE',
            value: 'DUE',
          }
          break

        case this.$t('general.draft'):
          this.filters.status = {
            name: 'DRAFT',
            value: 'DRAFT',
          }
          break

        default:
          this.filters.status = {
            name: '',
            value: '',
          }
          break
      }
      // this.refreshTable()
    },

    refreshTable() {
      this.$refs.table.refresh()
    },

    async fetchData({ page, filter, sort }) {
      let data = {
        customer_id: this.filters.customer ? this.filters.customer.id : '',
        status: this.filters.status.value,
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        invoice_number: this.filters.invoice_number,
        structure: this.filters.structure,
        category: this.filters.category,
        subcategory: this.filters.subcategory,
        description: this.filters.description,
        orderByField: sort.fieldName,
        orderBy: sort.order,
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchInvoices(data)
      await this.fetchCategories({ limit: 'all' })
      let structures = await this.fetchStructures({ limit: 'all' }) //AGGIUNTO 17/06/22
      this.structs = structures.data.structures.data
      let subcategories = await this.fetchSubcategories({ limit: 'all' })
      this.subcats = subcategories.data.subcategories.data
      this.isRequestOngoing = false
      this.currency = response.data.currency

      return {
        data: response.data.invoices.data,
        pagination: {
          totalPages: response.data.invoices.last_page,
          currentPage: page,
          count: response.data.invoices.count,
        },
      }
    },
    // AGGIUNTO 24/06/22
    async setInitialData() {
      let res = await this.fetchCurrentUser()
      if (res.data.user) {
        this.structs_enabled = res.data.user.company.structs_enabled
      }
    },

    setFilters() {
      this.resetSelectedInvoices()
      this.refreshTable()
      this.updateUrlWithFilters()
    },

    updateUrlWithFilters() {
      const query = {};

      for (var prop in this.filters) {
        if (this.filters.hasOwnProperty(prop)) {
          const value = this.filters[prop];
          if ((prop === 'customer' || prop === 'subcategory' || prop === 'category') && typeof value === 'object') {
            // per i campi select
            console.log('OBJECT', value);
            if (value !== null && value.id !== null && value.id !== undefined && value.id !== '') {
              query[prop] = value.id;  // Usa `prop` invece di `key`
            }
          } else if (value !== null && value !== '' && typeof value !== 'object') {
            console.log('STANDARD');
            // per i campi di input standard
            query[prop] = value;  // Usa `prop` invece di `key`
          }
        }
      }

      if (this.$router) {
        console.log('Replacing router with query:', query);
        this.$router.replace({ query }, () => {
          console.log('Router replace succeeded');
        }, (err) => {
          if (err !== undefined) {
            console.error('Error details:', err);
          }
        });
      } else {
        console.error('Router is not defined');
      }
    },

    async loadFiltersFromUrl() {
      const query = this.$route.query;
      console.log('Loading filters from URL:', query);

      for (var key of Object.keys(query)) {
        if (this.filters.hasOwnProperty(key)) {
          switch (key) {
            case 'customer':
              {
                let params = {
                  id: query[key],
                };
                let response = await this.fetchCustomer(params); // chiamo l'action che mi prende il customer singolo
                this.filters[key] = response.data.customer;
                this.$refs.customerSelect.selectCustomer(response.data.customer);
              }
              break;
            case 'category': 
              {
                let response = await this.fetchCategory(query[key]);
                
                this.filters[key] = response.data.category.id;
                this.category = response.data.category;
              }
              break;
              case 'subcategory':
                {
                  let response = await this.fetchSubcategory(query[key]);
                  console.log('SUBCATEGORY', response);
                  this.filters[key] = response.data.subcategory;
                  this.subcategory = response.data.subcategory;
                break;
              }
            default:
              this.filters[key] = query[key];
              break;
          }
        }
      }

      console.log('Filters loaded from URL', this.filters);
      this.refreshTable(); // Aggiorna la tabella dopo aver caricato i filtri
    },

    clearFilter() {
      if (this.filters.customer) {
        this.$refs.customerSelect.$refs.baseSelect.removeElement(
          this.filters.customer
        )
      }

      this.filters = {
        customer: '',
        structure: null,
        category: null,
        subcategory: null,
        status: '',
        from_date: '',
        to_date: '',
        invoice_number: '',
        description: '',
      }
      this.category = null
      this.subcategory = null
      this.structure = null

      this.activeTab = this.$t('general.all')

      // pulisco url
      if (this.$router) {
        this.$router.replace({ query: {} }, () => {
          console.log('Router replace succeeded');
        }, (err) => {
          if (err !== undefined) {
            console.error('Error details:', err);
          }
        });
      } else {
        console.error('Router is not defined');
      }
    },

    toggleFilter() {
      if (this.showFilters) {
        this.clearFilter()
      }

      this.showFilters = !this.showFilters
    },

    onSelectCustomer(customer) {
      console.log(customer);
      this.filters.customer = customer
    },

    //AGGIUNTO 15/06
    //CI ENTRA QUANDO SELEZIONO UNA STRUTTURA DALLA SELECT IN FILTRI
    onSelectStructure(structure) {
      this.filters.structure = structure.id
    },

    onSelectCategory(category) {
      this.filters.category = category.id
    },

    onSelectSubcategory(subcategory) {
      this.filters.subcategory = subcategory.id
    },

    async removeInvoice(id) {
      this.id = id
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('invoices.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          let res = await this.deleteInvoice({ ids: [id] })

          if (res.data.success) {
            window.toastr['success'](this.$tc('invoices.deleted_message'))
            this.$refs.table.refresh()
            return true
          }

          if (res.data.error === 'payment_attached') {
            window.toastr['error'](
              this.$t('invoices.payment_attached_message'),
              this.$t('general.action_failed')
            )
            return true
          }

          window.toastr['error'](res.data.error)
          return true
        }
        this.resetSelectedInvoices()
      })
    },

    async removeMultipleInvoices() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('invoices.confirm_delete', 2),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          let res = await this.deleteMultipleInvoices()

          if (res.data.error === 'payment_attached') {
            window.toastr['error'](
              this.$t('invoices.payment_attached_message'),
              this.$t('general.action_failed')
            )
            return true
          }

          if (res.data) {
            this.$refs.table.refresh()
            this.resetSelectedInvoices()
            window.toastr['success'](this.$tc('invoices.deleted_message', 2))
          } else if (res.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },

    async clearCustomerSearch(removedOption, id) {
      this.filters.customer = ''
      this.refreshTable()
    },

    async clearStatusSearch(removedOption, id) {
      this.filters.status = ''
      this.refreshTable()
    },

    //AGGIUNTO 15/06
    async clearStructureSearch(removedOption, id) {
      this.filters.structure = ''
      this.refreshTable()
    },

    async clearCategorySearch(removedOption, id) {
      this.filters.category = null
      this.refreshTable()
    },

    async clearSubcategorySearch(removedOption, id) {
      this.filters.subcategory = null
      this.refreshTable()
    },
    setActiveTab(val) {
      switch (val.value) {
        case 'DRAFT':
          this.activeTab = this.$t('general.draft')
          break
        case 'DUE':
          this.activeTab = this.$t('general.due')
          break
        default:
          this.activeTab = this.$t('general.all')
          break
      }
    },
    downloadAll() {
      let params = {
        customer_id: this.filters.customer ? this.filters.customer.id : '',
        status: this.filters.status.value,
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        invoice_number: this.filters.invoice_number,
        category: this.filters.category,
        structure: this.filters.structure, //AGGIUNTO 15/06
        subcategory: this.filters.subcategory,
        description: this.filters.description,
        invoice_type: ['Normal', 'Active'],
        orderByField: 'invoice_number',
        orderBy: 'asc',
      }

      axios
        .get(`/invoices/report`, { params })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))

          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'spese.csv')
          document.body.appendChild(fileLink)
          fileLink.click()

          window.toastr['success'](this.$t('invoices.exported_list'))
        })
        .catch((err) => {
          reject(err)
        })
    },
    downloadActive() {
      let params = {
        customer_id: this.filters.customer ? this.filters.customer.id : '',
        status: this.filters.status.value,
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        invoice_number: this.filters.invoice_number,
        category: this.filters.category,
        structure: this.filters.structure, //AGGIUNTO 15/06
        subcategory: this.filters.subcategory,
        description: this.filters.description,
        invoice_type: 'Active',
        orderByField: 'invoice_number',
        orderBy: 'asc',
      }

      axios
        .get(`/invoices/report`, { params })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))

          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'spese.csv')
          document.body.appendChild(fileLink)
          fileLink.click()

          window.toastr['success'](this.$t('invoices.exported_list'))
        })
        .catch((err) => {
          reject(err)
        })
    },
    downloadPassive() {
      let params = {
        customer_id: this.filters.customer ? this.filters.customer.id : '',
        status: this.filters.status.value,
        from_date: this.filters.from_date,
        to_date: this.filters.to_date,
        invoice_number: this.filters.invoice_number,
        category: this.filters.category,
        structure: this.filters.structure, //AGGIUNTO 15/06
        subcategory: this.filters.subcategory,
        description: this.filters.description,
        invoice_type: 'Normal',
        orderByField: 'invoice_number',
        orderBy: 'asc',
      }

      axios
        .get(`/invoices/report`, { params })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))

          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'spese.csv')
          document.body.appendChild(fileLink)
          fileLink.click()

          window.toastr['success'](this.$t('invoices.exported_list'))
        })
        .catch((err) => {
          reject(err)
        })
    },
  },
}
</script>
