var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      staticClass:
        "box-border bg-white border border-gray-200 border-solid rounded-b",
    },
    [
      _c(
        "td",
        { staticClass: "p-0 text-left align-top", attrs: { colspan: "12" } },
        [
          _c("table", { staticClass: "w-full" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "16%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "15%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "10%" } }),
              _vm._v(" "),
              _vm.enpav != null
                ? _c("col", { staticStyle: { width: "10%" } })
                : _vm._e(),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "26%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "9%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "7%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "7%" } }),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "px-2 py-4 text-right align-top" },
                  [
                    _c("sw-input", {
                      attrs: { disabled: true, type: "text", small: "" },
                      on: {
                        input: function ($event) {
                          return _vm.$v.item.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.item.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "name", $$v)
                        },
                        expression: "item.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-left align-top" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full" },
                          [
                            _c("sw-money", {
                              attrs: {
                                disabled: true,
                                currency: _vm.customerCurrency,
                                invalid: _vm.$v.item.price.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.item.price.$touch()
                                },
                              },
                              model: {
                                value: _vm.price,
                                callback: function ($$v) {
                                  _vm.price = $$v
                                },
                                expression: "price",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$v.item.price.$error
                          ? _c("div", [
                              !_vm.$v.item.price.maxLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("validation.price_maxlength")
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "px-2 py-4 text-right align-top" },
                  [
                    _c("sw-input", {
                      attrs: { type: "text", disabled: true, small: "" },
                      on: {
                        keyup: _vm.updateItem,
                        input: function ($event) {
                          return _vm.$v.item.quantity.$touch()
                        },
                      },
                      model: {
                        value: _vm.item.quantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "quantity", $$v)
                        },
                        expression: "item.quantity",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-left align-top" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full" },
                          [
                            _vm.enpav != null
                              ? _c("sw-money", {
                                  attrs: {
                                    value: _vm.divided_enpav,
                                    disabled: true,
                                    currency: _vm.customerCurrency,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "px-2 py-4 text-right align-top" },
                  _vm._l(_vm.item.taxes, function (tax, index) {
                    return _c("tax", {
                      key: tax.id,
                      attrs: {
                        disabled: true,
                        motivation: _vm.item.tax_motivation,
                        index: index,
                        "tax-data": tax,
                        taxes: _vm.item.taxes,
                        "discounted-total": _vm.total,
                        "total-tax": _vm.totalSimpleTax,
                        total: _vm.total,
                        currency: _vm.currency,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center h-10 cursor-pointer text-sm",
                    },
                    [
                      _c("span", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(_vm.totalTTC, _vm.currency)
                            ),
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center w-6 h-10 mx-2 text-sm cursor-pointer",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-25px" },
                            attrs: { disabled: true },
                            on: { change: _vm.updateItem },
                            model: {
                              value: _vm.item.fctax,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "fctax", $$v)
                              },
                              expression: "item.fctax",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "px-2 py-4 text-right align-top" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-end w-6 h-10 mx-2 text-sm cursor-pointer",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "relative w-12" },
                        [
                          _c("sw-switch", {
                            staticClass: "absolute",
                            staticStyle: { top: "-25px" },
                            attrs: { disabled: true },
                            on: { change: _vm.updateItem },
                            model: {
                              value: _vm.item.indtax,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "indtax", $$v)
                              },
                              expression: "item.indtax",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }