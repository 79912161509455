<template>
  <base-page class="customer-create">
    <sw-page-header :title="$t('customers.title')">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item :title="$t('general.home')" to="dashboard" />
        <sw-breadcrumb-item :title="$tc('customers.customer', 2)" to="#" active />
      </sw-breadcrumb>

      <template slot="actions">
        <sw-button v-show="totalCustomers" size="lg" variant="primary-outline" @click="toggleFilter">
          {{ $t('general.filter') }}
          <component :is="filterIcon" class="h-4 ml-1 -mr-1 font-bold" />
        </sw-button>

        <sw-button tag-name="router-link" to="customers/create" size="lg" variant="primary" class="ml-4">
          <plus-sm-icon class="h-6 mr-1 -ml-2 font-bold" />
          {{ $t('customers.new_customer') }}
        </sw-button>
      </template>
    </sw-page-header>

    <slide-y-up-transition>
      <sw-filter-wrapper v-show="showFilters">
        <sw-input-group :label="$t('customers.display_name')" class="flex-1 mt-2 ml-0 lg:ml-6">
          <sw-input v-model="filters.display_name" type="text" name="name" class="mt-2" autocomplete="off" />
        </sw-input-group>

        <sw-input-group :label="$t('customers.contact_name')" class="flex-1 mt-2 ml-0 lg:ml-6">
          <sw-input v-model="filters.contact_name" type="text" name="address_name" class="mt-2" autocomplete="off" />
        </sw-input-group>

        <sw-input-group :label="$t('customers.phone')" class="flex-1 mt-2 ml-0 lg:ml-6">
          <sw-input v-model="filters.phone" type="text" name="phone" class="mt-2" autocomplete="off" />
        </sw-input-group>

        <label class="absolute text-sm leading-snug text-black cursor-pointer" style="top: 10px; right: 15px"
          @click="clearFilter">{{ $t('general.clear_all') }}</label>
      </sw-filter-wrapper>
    </slide-y-up-transition>

    <sw-empty-table-placeholder v-show="showEmptyScreen" :title="$t('customers.no_customers')"
      :description="$t('customers.list_of_customers')">
      <astronaut-icon class="mt-5 mb-4" />

      <sw-button slot="actions" tag-name="router-link" to="/admin/customers/create" size="lg" variant="primary-outline">
        {{ $t('customers.add_new_customer') }}
      </sw-button>
    </sw-empty-table-placeholder>

    <div v-show="!showEmptyScreen" class="relative table-container">
      <div class="relative flex items-center justify-between h-10 mt-5 border-b-2 border-gray-200 border-solid">
        <p class="text-sm">
          {{ $t('general.showing') }}: <b>{{ customers.length }}</b>
          {{ $t('general.of') }} <b>{{ totalCustomers }}</b>
        </p>

        <sw-transition type="fade">
          <sw-dropdown v-if="selectedCustomers.length">
            <span slot="activator" class="flex block text-sm font-medium cursor-pointer select-none text-primary-400">
              {{ $t('general.actions') }}
              <chevron-down-icon class="h-5" />
            </span>

            <sw-dropdown-item @click="removeMultipleCustomers">
              <trash-icon class="h-5 mr-3 text-gray-600" />
              {{ $t('general.delete') }}
            </sw-dropdown-item>
          </sw-dropdown>
        </sw-transition>
      </div>

      <div class="absolute z-10 items-center pl-4 mt-2 select-none md:mt-12">
        <sw-checkbox v-model="selectAllFieldStatus" variant="primary" size="sm" class="hidden md:inline"
          @change="selectAllCustomers" />

        <sw-checkbox v-model="selectAllFieldStatus" :label="$t('general.select_all')" variant="primary" size="sm"
          class="md:hidden" @change="selectAllCustomers" />
      </div>

      <sw-table-component ref="table" :show-filter="false" :data="fetchData" table-class="table">
        <sw-table-column :sortable="false" :filterable="false" cell-class="no-click">
          <div slot-scope="row" class="relative block">
            <sw-checkbox :id="row.id" v-model="selectField" :value="row.id" variant="primary" size="sm" />
          </div>
        </sw-table-column>

        <sw-table-column :sortable="true" :filterable="true" :label="$t('customers.display_name')" show="name">
          <template slot-scope="row">
            <span>{{ $t('customers.display_name') }}</span>
            <router-link :to="{ path: `customers/${row.id}/edit` }" class="font-medium text-primary-500">
              {{ row.name }}
            </router-link>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('customers.contact_name')" show="contact_name">
          <template slot-scope="row">
            <span>{{ $t('customers.contact_name') }}</span>
            <span>
              {{ row.contact_name ? row.contact_name : $t('customers.no_contact_name') }}
            </span>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('customers.phone')" show="phone">
          <template slot-scope="row">
            <span>{{ $t('customers.phone') }}</span>
            <span>
              {{ row.phone ? row.phone : 'No Contact' }}
            </span>
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('customers.amount_due')" show="due_amount">
          <template slot-scope="row">
            <span> {{ $t('customers.amount_due') }} </span>
            <div v-html="$utils.formatMoney(row.due_amount, row.currency)" />
          </template>
        </sw-table-column>

        <sw-table-column :sortable="true" :label="$t('customers.added_on')" sort-as="created_at"
          show="formattedCreatedAt" />

        <sw-table-column :sortable="false" :filterable="false" cell-class="action-dropdown">
          <template slot-scope="row">
            <span> {{ $t('customers.action') }} </span>

            <sw-dropdown>
              <dot-icon slot="activator" />

              <sw-dropdown-item :to="`customers/${row.id}/edit`" tag-name="router-link">
                <pencil-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.edit') }}
              </sw-dropdown-item>

              <!-- <sw-dropdown-item
                :to="`customers/${row.id}/edit`"
                tag-name="router-link"
              >
                <eye-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.view') }}
              </sw-dropdown-item> -->

              <sw-dropdown-item @click="removeCustomer(row.id)">
                <trash-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('general.delete') }}
              </sw-dropdown-item>
            </sw-dropdown>
          </template>
        </sw-table-column>
      </sw-table-component>
    </div>
  </base-page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { PlusSmIcon } from '@vue-hero-icons/solid'
import {
  FilterIcon,
  XIcon,
  ChevronDownIcon,
  TrashIcon,
  PencilIcon,
  EyeIcon,
} from '@vue-hero-icons/solid'
import AstronautIcon from '../../components/icon/AstronautIcon'

export default {
  components: {
    AstronautIcon,
    ChevronDownIcon,
    PlusSmIcon,
    FilterIcon,
    XIcon,
    TrashIcon,
    PencilIcon,
    EyeIcon,
  },
  data() {
    return {
      showFilters: false,
      isRequestOngoing: true,
      filters: {
        display_name: '',
        contact_name: '',
        phone: '',
      },
    }
  },
  computed: {
    showEmptyScreen() {
      return !this.totalCustomers && !this.isRequestOngoing
    },
    filterIcon() {
      return this.showFilters ? 'x-icon' : 'filter-icon'
    },
    ...mapGetters('customer', [
      'customers',
      'selectedCustomers',
      'totalCustomers',
      'selectAllField',
    ]),
    selectField: {
      get: function () {
        return this.selectedCustomers
      },
      set: function (val) {
        this.selectCustomer(val)
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  mounted() {
    this.loadFiltersFromUrl()
  },
  
  destroyed() {
    if (this.selectAllField) {
      this.selectAllCustomers()
    }
  },
  methods: {
    ...mapActions('customer', [
      'fetchCustomers',
      'selectAllCustomers',
      'selectCustomer',
      'deleteCustomer',
      'deleteMultipleCustomers',
      'setSelectAllState',
    ]),
    refreshTable() {
      this.$refs.table.refresh()
    },
    async fetchData({ page, filter, sort }) {
      let data = {
        display_name: this.filters.display_name,
        contact_name: this.filters.contact_name,
        phone: this.filters.phone,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchCustomers(data)
      this.isRequestOngoing = false

      return {
        data: response.data.customers.data,
        pagination: {
          totalPages: response.data.customers.last_page,
          currentPage: page,
        },
      }
    },
    setFilters() {
      this.refreshTable()
      this.updateUrlWithFilters()
    },

    updateUrlWithFilters() {
      const query = {};

      for (var prop in this.filters) {
        if (this.filters.hasOwnProperty(prop)) {
          const value = this.filters[prop];

          if (value !== null && value !== '' && !(typeof value === 'object')) {
            query[prop] = value;  // Usa `prop` invece di `key`
          }
        }
      }

      if (this.$router) {
        console.log('Replacing router with query:', query);
        this.$router.replace({ query }, () => {
          console.log('Router replace succeeded');
        }, (err) => {
          if (err !== undefined) {
            console.error('Error details:', err);
          }
        });
      } else {
        console.error('Router is not defined');
      }
    },

    loadFiltersFromUrl() {
      const query = this.$route.query;
      console.log('Loading filters from URL:', query);
      Object.keys(query).forEach(key => {
        if (this.filters.hasOwnProperty(key)) {
          this.filters[key] = query[key];
        }
      });
      console.log('Filters loaded from URL', this.filters);
      this.refreshTable(); // Aggiorna la tabella dopo aver caricato i filtri
    },

    clearFilter() {
      this.filters = {
        display_name: '',
        contact_name: '',
        phone: '',
      }

      // pulisco url
      if (this.$router) {
        this.$router.replace({ query: {} }, () => {
          console.log('Router replace succeeded');
        }, (err) => {
          if (err !== undefined) {
            console.error('Error details:', err);
          }
        });
      } else {
        console.error('Router is not defined');
      }
    },

    toggleFilter() {
      if (this.showFilters) {
        this.clearFilter()
      }

      this.showFilters = !this.showFilters
    },

    async removeCustomer(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('customers.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteCustomer({ ids: [id] })

          if (res.data.success) {
            window.toastr['success'](this.$tc('customers.deleted_message', 1))
            this.$refs.table.refresh()
            return true
          }

          window.toastr['error'](res.data.message)
          return true
        }
      })
    },

    async removeMultipleCustomers() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('customers.confirm_delete', 2),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let request = await this.deleteMultipleCustomers()
          if (request.data.success) {
            window.toastr['success'](this.$tc('customers.deleted_message', 2))
            this.refreshTable()
          } else if (request.data.error) {
            window.toastr['error'](request.data.message)
          }
        }
      })
    },
  },
}
</script>
