export default {
  amount: 0,
  user_id: null,
  invoice_id: null,
  company_id: null,
  deadline_method_id: null,
  ritenuta: 0,
  sold: 0,
  new: 1,
}
